import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  icUkNo:"",
  ic_DO_NO:"",
  ic_DO_TY:"",
  ic_DO_CO:"",
  ic_OR_NO:"",
  ic_OR_TY:"",
  ic_OR_CO:"",
  ic_IT_NM:"",
  itemDES:"",
  ic_CO_CT:"",
  ic_LO_CN:"",
  ic_LT_NO:"",
  ic_SU_RF:"",
  ic_TR_QT:"",
  ic_TR_UM:"",
  ic_UN_CT:"",
  ic_CO_CT:"",
  ic_EX_CT:"",
  ic_FR_CT:"",
  ic_FR_AM:"",
  ic_CV_RT:"",
  ic_TR_DT:"",
  ic_SC_QT:"",
  ic_SC_UM:"",
};

const initialFilters = {
  ic_DO_NO: { value: "", operation: "" },
  ic_DO_TY: { value: "", operation: "" },
  ic_DO_CO: { value: "", operation: "" },
  ic_OR_NO: { value: "", operation: "" },
  ic_OR_TY: { value: "", operation: "" },
  ic_OR_CO: { value: "", operation: "" },
  ic_IT_NM: { value: "", operation: "" },
  itemDES: { value: "", operation: "" },
  ic_CO_CT: { value: "", operation: "" },
  ic_LO_CN: { value: "", operation: "" },
  ic_LT_NO: { value: "", operation: "" },
  ic_SU_RF: { value: "", operation: "" },
  ic_TR_QT: { value: "", operation: "" },
  ic_TR_UM: { value: "", operation: "" },
  ic_UN_CT: { value: "", operation: "" },
  ic_EX_CT: { value: "", operation: "" },
  ic_FR_CT: { value: "", operation: "" },
  ic_FR_AM: { value: "", operation: "" },
  ic_CV_RT: { value: "", operation: "" },
  ic_TR_DT: { value: "", operation: "" },
  ic_SC_QT: { value: "", operation: "" },
  ic_SC_UM: { value: "", operation: "" },
};

const ItemLedgerMain = () => {
    // buttonstyles
    const {formatDate,checkboxStyle,tableHeaderStyle, inputStyle,tableCellStyle, } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // AdvanceSearch
  const [SelectedItem, setSelectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [item, setitem] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const openitemPopup = (e) => {
    setItemPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    setSelectedItem(data.im_IT_DS);
    setitem(data.im_IT_NM);
    setItemPopupVisible(false);
    updateFilters (data.im_IT_NM);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      ic_IT_NM: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);


  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClearButton=()=>{
    setitem("");
    setselectedDesc("");
    setSelectedItem(null);
    setFilters(initialFilters);
  }
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [eg_CO_NO , seteg_CO_NO] = useState("");
  const handleDefault = async () => {
      seteg_CO_NO(`${company}`)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
           
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/InventoryTransfer/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid">
      <div className="field col" >
            <label htmlFor="item" className="font-bold">
             Item <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{width:"125px"}}
                id="item"
                name="item"
                onFocus={() => setShowSearchIcon(true)}
                value={item}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openitemPopup}
              ></i>
               )}
              <div>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                readOnly
                value={SelectedItem}
                style={{width:"250px",backgroundColor:"#f5f4f4"}}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            </div>
          </div>
          
</div>
  );
  const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
  // style={{ height: "35px" }}
  icon="pi pi-times"
  className="mr-2 clear-button"
  link
  onClick={handleClearButton}
  />
  </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_DO_NO"] ? filters["ic_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_DO_NO",
                        e.target.value,
                        filters["ic_DO_NO"]
                          ? filters["ic_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_DO_TY"] ? filters["ic_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_DO_TY",
                        e.target.value,
                        filters["ic_DO_TY"] ? filters["ic_DO_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_DO_CO"] ? filters["ic_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_DO_CO",
                        e.target.value,
                        filters["ic_DO_CO"]
                          ? filters["ic_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_OR_NO"] ? filters["ic_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_OR_NO",
                        e.target.value,
                        filters["ic_OR_NO"]
                          ? filters["ic_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_OR_TY"] ? filters["ic_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_OR_TY",
                        e.target.value,
                        filters["ic_OR_TY"]
                          ? filters["ic_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_OR_CO"] ? filters["ic_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_OR_CO",
                        e.target.value,
                        filters["ic_OR_CO"]
                          ? filters["ic_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_IT_NM"] ? filters["ic_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_IT_NM",
                        e.target.value,
                        filters["ic_IT_NM"] ? filters["ic_IT_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"] ? filters["itemDES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_CO_CT"] ? filters["ic_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_CO_CT",
                        e.target.value,
                        filters["ic_CO_CT"] ? filters["ic_CO_CT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_LO_CN"] ? filters["ic_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_LO_CN",
                        e.target.value,
                        filters["ic_LO_CN"] ? filters["ic_LO_CN"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_LT_NO"] ? filters["ic_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_LT_NO",
                        e.target.value,
                        filters["ic_LT_NO"] ? filters["ic_LT_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_SU_RF"] ? filters["ic_SU_RF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_SU_RF",
                        e.target.value,
                        filters["ic_SU_RF"] ? filters["ic_SU_RF"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_TR_QT"] ? filters["ic_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_TR_QT",
                        e.target.value,
                        filters["ic_TR_QT"] ? filters["ic_TR_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_TR_UM"] ? filters["ic_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_TR_UM",
                        e.target.value,
                        filters["ic_TR_UM"] ? filters["ic_TR_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_UN_CT"] ? filters["ic_UN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_UN_CT",
                        e.target.value,
                        filters["ic_UN_CT"] ? filters["ic_UN_CT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_EX_CT"] ? filters["ic_EX_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_EX_CT",
                        e.target.value,
                        filters["ic_EX_CT"] ? filters["ic_EX_CT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_FR_CT"] ? filters["ic_FR_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_FR_CT",
                        e.target.value,
                        filters["ic_FR_CT"] ? filters["ic_FR_CT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_FR_AM"] ? filters["ic_FR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_FR_AM",
                        e.target.value,
                        filters["ic_FR_AM"] ? filters["ic_FR_AM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_CV_RT"] ? filters["ic_CV_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_CV_RT",
                        e.target.value,
                        filters["ic_CV_RT"] ? filters["ic_CV_RT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_TR_DT"] ? filters["ic_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_TR_DT",
                        e.target.value,
                        filters["ic_TR_DT"] ? filters["ic_TR_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_SC_QT"] ? filters["ic_SC_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_SC_QT",
                        e.target.value,
                        filters["ic_SC_QT"] ? filters["ic_SC_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ic_SC_UM"] ? filters["ic_SC_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ic_SC_UM",
                        e.target.value,
                        filters["ic_SC_UM"] ? filters["ic_SC_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Document No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Order No </th>
                <th style={tableHeaderStyle}>Order Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot No</th>
                <th style={tableHeaderStyle}>Reference</th>
                <th style={tableHeaderStyle}>Transit Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Foreign Unit Cost</th>
                <th style={tableHeaderStyle}>Foreign Amount</th>
                <th style={tableHeaderStyle}>Conversion Rate</th>
                <th style={tableHeaderStyle}>Trans Date</th>
                <th style={tableHeaderStyle}>Sec Quantity</th>
                <th style={tableHeaderStyle}>Sec UM</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.icUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.icUkNo}
                        checked={selectedRows.includes(rowData.icUkNo)}
                        onChange={() => handleRowSelect(rowData.icUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ic_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.ic_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.ic_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.ic_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.ic_OR_TY}</td> 
                    <td style={tableCellStyle}>{rowData.ic_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.ic_IT_NM}</td>  
                    <td style={tableCellStyle}>{rowData.itemDES}</td>  
                    <td style={tableCellStyle}>{rowData.ic_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.ic_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.ic_LT_NO}</td>
                    <td style={tableCellStyle}>{rowData.ic_SU_RF}</td> 
                    <td style={tableCellStyle}>{rowData.ic_TR_QT}</td>
                    <td style={tableCellStyle}>{rowData.ic_TR_UM}</td>      
                    <td style={tableCellStyle}>{rowData.ic_UN_CT}</td>   
                    <td style={tableCellStyle}>{rowData.ic_EX_CT}</td>
                    <td style={tableCellStyle}>{rowData.ic_FR_CT}</td> 
                    <td style={tableCellStyle}>{rowData.ic_FR_AM}</td>
                    <td style={tableCellStyle}>{rowData.ic_CV_RT}</td>      
                    <td style={tableCellStyle}>{formatDate(rowData.ic_TR_DT)}</td>  
                    <td style={tableCellStyle}>{rowData.ic_SC_QT}</td>
                    <td style={tableCellStyle}>{rowData.ic_SC_UM}</td>              
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect}
      />
    </div>
    </div>
  );
};
export default ItemLedgerMain;