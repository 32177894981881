import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { TabView, TabPanel } from "primereact/tabview";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ServicePackagePopup from "../Popups/ServicePackagePopup";
import ShipmentStatusPopup from "../Popups/ServiceContractPopup";
import { InputMask } from "primereact/inputmask";

// import { Tabs } from "react-bootstrap";
// import { Tab, Tabs, TabList} from 'react-tabs';
export default function ShipmentStatusDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  onChangeShipmentStatusData,
  handleFormSubmit,
  ShipmentStatusData,
  setShipmentStatusData,
  SelectedSold,
  setSelectedSold,
  SelectedShip,
  setSelectedShip,
  ss_SH_DE,
  setss_SH_DE,
  ss_OG_NO,
  setss_OG_NO,
  ss_SH_TY,
  setss_SH_TY,
  ss_ST_CD,
  setss_ST_CD,
  ss_CI_TY,
  setss_CI_TY,
  ss_ST_AT,
  setss_ST_AT,
  ss_RT_CD,
  setss_RT_CD,
  ss_ZO_NO,
  setss_ZO_NO,
  ss_CT_RY,
  setss_CT_RY,
  ss_MO_TR,
  setss_MO_TR,
  ss_FR_HN,
  setss_FR_HN,
  ss_WT_UM,
  setss_WT_UM,
  ss_VL_UM,
  setss_VL_UM,
}) {
  const {
    ssUkNo,
    ss_SH_CO,
    ss_SL_TO,
    ss_SH_TO,
    SS_PO_CD,
    ss_CA_NO,
    ss_NO_PS,
    ss_SC_WT,
    ss_NO_CN,
    ss_SC_VL,
    ss_SH_NO,
    ss_PR_SD,
    ss_PR_ST,
    ss_PR_DD,
    ss_PR_DT,
    ss_AC_SD,
    ss_AC_ST,
    ss_AC_DD,
    ss_AC_DT,
    ss_PK_DT,
    SS_PK_TM,
  } = data;
  const addRow = () => {
    setShipmentStatusData([...ShipmentStatusData, {}]);
  };
  const deleteRow = () => {
    if (ShipmentStatusData.length > 0) {
      const updatedData = [...ShipmentStatusData];
      updatedData.pop();
      setShipmentStatusData(updatedData);
    }
  };
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, savestyle, TabBoxStyle, cancelstyle, Dialogstyle } = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
  // DEFAULT DATE
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "ch_ST_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [ss_SH_DEOptions, setss_SH_DEOptions] = useState([]);
  const [ss_OG_NOOptions, setss_OG_NOOptions] = useState([]);
  const [ss_SH_TYOptions, setss_SH_TYOptions] = useState([]);
  const [ss_ST_CDOptions, setss_ST_CDOptions] = useState([]);
  const [ss_CI_TYOptions, setss_CI_TYOptions] = useState([]);
  const [ss_ST_ATOptions, setss_ST_ATOptions] = useState([]);
  const [ss_RT_CDOptions, setss_RT_CDOptions] = useState([]);
  const [ss_ZO_NOOptions, setss_ZO_NOOptions] = useState([]);
  const [ss_CT_RYOptions, setss_CT_RYOptions] = useState([]);
  const [ss_MO_TROptions, setss_MO_TROptions] = useState([]);
  const [ss_FR_HNOptions, setss_FR_HNOptions] = useState([]);
  const [ss_WT_UMOptions, setss_WT_UMOptions] = useState([]);
  const [ss_VL_UMOptions, setss_VL_UMOptions] = useState([]);


   // Tax AP
   const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_SH_DE(value);
  };
   // Tax AP
   const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_OG_NO(value);
  };
  // Tax AP
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_SH_TY(value);
  };
  // UM
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_ST_CD(value);
  };
  // Frequency
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_CI_TY(value);
  };
  // Tax area
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_ST_AT(value);
  };
   // contract type
   const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_RT_CD(value);
  };
  // contract type
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_ZO_NO(value);
  };
  // contract type
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setss_CT_RY(value);
  };
    // contract type
    const onchangeDropDownFormData10 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setss_MO_TR(value);
    };
    // contract type
    const onchangeDropDownFormData11 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setss_FR_HN(value);
    };
       // WTUM
       const onchangeDropDownFormData12 = (name, value) => {
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        setss_WT_UM(value);
      };
      // VLUM
      const onchangeDropDownFormData13 = (name, value) => {
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
        setss_VL_UM(value);
      };

  useEffect(() => {
    fetchDropdown1();
    fetchDropdown2();
    fetchDropdown3();
    fetchDropdown4();
    fetchDropdown5();
    fetchDropdown6();
    fetchDropdown7();
    fetchDropdown8();
    fetchDropdown9();
    fetchDropdown10();
    fetchDropdown11();
    fetchDropdown12();
    fetchDropdown13();
  }, []);


  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeCD("SH/DP");
    setss_SH_DEOptions(Options);
  };
  const fetchDropdown2 = async () => {
    const Options = await fetchCostCenter();
    setss_OG_NOOptions(Options);
  };
  const fetchDropdown3 = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setss_SH_TYOptions(Options);
  };
  const fetchDropdown4 = async () => {
    const Options = await fetchClassCodeCD("SH/ST");
    setss_ST_CDOptions(Options);
  };
  const fetchDropdown5 = async () => {
    const Options = await fetchClassCodeCD("FO/ST");
    setss_CI_TYOptions(Options);
  };
  const fetchDropdown6 = async () => {
    const Options = await fetchClassCodeCD("FO/CI");
    setss_ST_ATOptions(Options);
  };
  const fetchDropdown7 = async () => {
    const Options = await fetchClassCodeCD("RT/CD");
    setss_RT_CDOptions(Options);
  };
  const fetchDropdown8 = async () => {
    const Options = await fetchClassCodeCD("ZO/NO");
    setss_ZO_NOOptions(Options);
  };
  const fetchDropdown9 = async () => {
    const Options = await fetchClassCodeCD("FO/CY");
    setss_CT_RYOptions(Options);
  };
  const fetchDropdown10 = async () => {
    const Options = await fetchClassCodeCD("TR/MO");
    setss_MO_TROptions(Options);
  };
  const fetchDropdown11 = async () => {
    const Options = await fetchClassCodeCD("FO/FH");
    setss_FR_HNOptions(Options);
  };
  const fetchDropdown12 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setss_WT_UMOptions(Options);
  };
  const fetchDropdown13 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setss_VL_UMOptions(Options);
  };
 
  

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // advance search  
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  

  const [SoldToPopupVisible, setSoldToPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);

  const [SoldTotype, setSoldTotype] = useState("C");
  const [ShipToType, setShipToType] = useState("E");

  

  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("ch_EX_RT").value = dataExRt.cr_CO_MR;
        //  setch_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };


  const openSoldToPopup = () => {
    setSoldToPopupVisible(true);
  };

  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedSold(data.ab_AD_NM);
    onchangeFormData("ss_SL_TO", data.abAdNo);
    setSoldToPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleDataSelectShip = (data) => {
    setSelectedShip(data.ab_AD_NM);
    onchangeFormData("ss_SH_TO", data.abAdNo);
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };



  const isUpdateMode = Boolean(ssUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ssUkNo ? "Revise Service Contract" : "Add Service Contract"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        {activeTab === 0 && (
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
        )}
        {activeTab === 0 && (
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Shipment Status" id="tab1">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div style={{ display: "none" }}>
                  <label htmlFor="ssUkNo" className="font-bold">
                    UK No
                  </label>
                  <InputText disabled
                    id="ssUkNo"
                    name="ssUkNo"
                    value={ssUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_SH_DE" className="font-bold">
                    Shipment Depot
                  </label>
                  <Dropdown
                    id="ss_SH_DE"
                    name="ss_SH_DE"
                    value={ss_SH_DE}
                    options={ss_SH_DEOptions}
                    onChange={(e) => onchangeDropDownFormData1("ss_SH_DE", e.value)}
                    placeholder="Shipment Depot"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_OG_NO" className="font-bold">
                    Origin
                  </label>
                  <Dropdown
                    id="ss_OG_NO"
                    name="ss_OG_NO"
                    value={ss_OG_NO}
                    options={ss_OG_NOOptions}
                    onChange={(e) => onchangeDropDownFormData2("ss_OG_NO", e.value)}
                    placeholder="	Origin"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_SH_NO" className="font-bold">
                    Shipment No
                  </label>
                  <InputText disabled
                    id="ss_SH_NO"
                    name="ss_SH_NO"
                    value={ss_SH_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_SH_TY" className="font-bold">
                    Type
                  </label>
                  <Dropdown
                    id="ss_SH_TY"
                    name="ss_SH_TY"
                    value={ss_SH_TY}
                    options={ss_SH_TYOptions}
                    onChange={(e) => onchangeDropDownFormData3("ss_SH_TY", e.value)}
                    placeholder="	Type"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_SH_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="ss_SH_CO"
                    name="ss_SH_CO"
                    value={ss_SH_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_ST_CD" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    id="ss_ST_CD"
                    name="ss_ST_CD"
                    value={ss_ST_CD}
                    options={ss_ST_CDOptions}
                    onChange={(e) => onchangeDropDownFormData4("ss_ST_CD", e.value)}
                    placeholder="Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_SH_TO" className="font-bold">
                    Ship To <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM1"
                      name="ab_AD_NM1"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedShip}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openShipToPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="ss_SH_TO"
                      name="ss_SH_TO"
                      value={ss_SH_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_CI_TY" className="font-bold">
                    City
                  </label>
                  <Dropdown
                    id="ss_CI_TY"
                    name="ss_CI_TY"
                    value={ss_CI_TY}
                    options={ss_CI_TYOptions}
                    onChange={(e) => onchangeDropDownFormData5("ss_CI_TY", e.value)}
                    placeholder="City"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_SL_TO" className="font-bold">
                    Sold To <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedSold}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openSoldToPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="ss_SL_TO"
                      name="ss_SL_TO"
                      value={ss_SL_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_ST_AT" className="font-bold">
                    State
                  </label>
                  <Dropdown
                    id="ss_ST_AT"
                    name="ss_ST_AT"
                    value={ss_ST_AT}
                    options={ss_ST_ATOptions}
                    onChange={(e) => onchangeDropDownFormData6("ss_ST_AT", e.value)}
                    placeholder="State"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_RT_CD" className="font-bold">
                    Route Code
                  </label>
                  <Dropdown
                    id="ss_RT_CD"
                    name="ss_RT_CD"
                    value={ss_RT_CD}
                    options={ss_RT_CDOptions}
                    onChange={(e) => onchangeDropDownFormData7("ss_RT_CD", e.value)}
                    placeholder="RouteCode"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="SS_PO_CD" className="font-bold">
                    Postal Code
                  </label>
                  <InputText
                    id="SS_PO_CD"
                    name="SS_PO_CD"
                    value={SS_PO_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_ZO_NO" className="font-bold">
                    Zone Number
                  </label>
                  <Dropdown
                    id="ss_ZO_NO"
                    name="ss_ZO_NO"
                    value={ss_ZO_NO}
                    options={ss_ZO_NOOptions}
                    onChange={(e) => onchangeDropDownFormData8("ss_ZO_NO", e.value)}
                    placeholder="RouteCode"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_CT_RY" className="font-bold">
                    Country
                  </label>
                  <Dropdown
                    id="ss_CT_RY"
                    name="ss_CT_RY"
                    value={ss_CT_RY}
                    options={ss_CT_RYOptions}
                    onChange={(e) => onchangeDropDownFormData9("ss_CT_RY", e.value)}
                    placeholder="RouteCode"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_MO_TR" className="font-bold">
                    Mode of Transport
                  </label>
                  <Dropdown
                    id="ss_MO_TR"
                    name="ss_MO_TR"
                    value={ss_MO_TR}
                    options={ss_MO_TROptions}
                    onChange={(e) => onchangeDropDownFormData10("ss_MO_TR", e.value)}
                    placeholder="Mode of Transport"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_CA_NO" className="font-bold">
                    Carrier Number
                  </label>
                  <InputText
                    id="ss_CA_NO"
                    name="ss_CA_NO"
                    value={ss_CA_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_FR_HN" className="font-bold">
                    Freight Handling
                  </label>
                  <Dropdown
                    id="ss_FR_HN"
                    name="ss_FR_HN"
                    value={ss_FR_HN}
                    options={ss_FR_HNOptions}
                    onChange={(e) => onchangeDropDownFormData11("ss_FR_HN", e.value)}
                    placeholder=" Freight Handling"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_NO_PS" className="font-bold">
                    Number of Pieces
                  </label>
                  <InputText
                    id="ss_NO_PS"
                    name="ss_NO_PS"
                    value={ss_NO_PS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ss_SC_WT" className="font-bold">
                    Weight
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="ss_SC_WT"
                      name="ss_SC_WT"
                      value={ss_SC_WT}
                      onChange={(e) => onchangedata(e)}
                    />
                      <Dropdown
                        id="ss_WT_UM"
                        name="ss_WT_UM"
                        value={ss_WT_UM}
                        options={ss_WT_UMOptions}
                        onChange={(e) => onchangeDropDownFormData12("ss_WT_UM", e.value)}
                        placeholder="Weight UM"
                      />
                    </div>
                  </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_NO_CN" className="font-bold">
                  Number of Containers
                  </label>
                  <InputText
                    id="ss_NO_CN"
                    name="ss_NO_CN"
                    value={ss_NO_CN}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ss_SC_VL" className="font-bold">
                    Volume
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="ss_SC_VL"
                      name="ss_SC_VL"
                      value={ss_SC_VL}
                      onChange={(e) => onchangedata(e)}
                    />
                      <Dropdown
                        id="ss_VL_UM"
                        name="ss_VL_UM"
                        value={ss_VL_UM}
                        options={ss_VL_UMOptions}
                        onChange={(e) => onchangeDropDownFormData13("ss_VL_UM", e.value)}
                        placeholder=" Volume UM"
                      />
                    </div>
                  </div>
             
              </div>
            </div>

          </TabPanel>
          <TabPanel header="Date/Time" id="tab2">
            <div style={inboxstyle} >
              <h1 style={{ fontWeight: "bold", fontSize: '1.5rem' }}>Promised</h1>
              <div className="formgrid grid">

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_PR_SD" className="font-bold">
                    Ship Date
                  </label>
                  <Calendar
                    id="ss_PR_SD"
                    name="ss_PR_SD"
                    value={moment(data.ss_PR_SD, "YYYYMMDD").isValid() ? moment(data.ss_PR_SD, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ss_PR_SD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_PR_ST" className="font-bold">
                    Ship Time
                  </label>
                  <InputMask
                    id={ss_PR_ST}
                    name={ss_PR_ST}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("ss_PR_ST", e.target.value,); }}
                    value={moment(ss_PR_ST, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_PR_DD" className="font-bold">
                    Delivery Date
                  </label>
                  <Calendar
                    id="ss_PR_DD"
                    name="ss_PR_DD"
                    value={moment(data.ss_PR_DD, "YYYYMMDD").isValid() ? moment(data.ss_PR_DD, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ss_PR_DD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_PR_DT" className="font-bold">
                    Delivery Time
                  </label>
                  <InputMask
                    id={ss_PR_DT}
                    name={ss_PR_DT}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("ss_PR_DT", e.target.value,); }}
                    value={moment(ss_PR_DT, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
              </div>
              <h1 style={{ fontWeight: "bold", fontSize: '1.5rem' }}>Actual</h1>
              <div className="formgrid grid">

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_AC_SD" className="font-bold">
                    Ship Date
                  </label>
                  <Calendar
                    id="ss_AC_SD"
                    name="ss_AC_SD"
                    value={moment(data.ss_AC_SD, "YYYYMMDD").isValid() ? moment(data.ss_AC_SD, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ss_AC_SD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_AC_ST" className="font-bold">
                    Ship Time
                  </label>
                  <InputMask
                    id={ss_AC_ST}
                    name={ss_AC_ST}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("ss_AC_ST", e.target.value,); }}
                    value={moment(ss_AC_ST, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_AC_DD" className="font-bold">
                    Delivery Date
                  </label>
                  <Calendar
                    id="ss_AC_DD"
                    name="ss_AC_DD"
                    value={moment(data.ss_AC_DD, "YYYYMMDD").isValid() ? moment(data.ss_AC_DD, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ss_AC_DD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_AC_DT" className="font-bold">
                    Delivery Time
                  </label>
                  <InputMask
                    id={ss_AC_DT}
                    name={ss_AC_DT}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("ss_AC_DT", e.target.value,); }}
                    value={moment(ss_AC_DT, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
              </div>
              <h1 style={{ fontWeight: "bold", fontSize: '1.5rem' }}>Pickup</h1>
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ss_PK_DT" className="font-bold">
                    Pickup  Date
                  </label>
                  <Calendar
                    id="ss_PK_DT"
                    name="ss_PK_DT"
                    value={moment(data.ss_PK_DT, "YYYYMMDD").isValid() ? moment(data.ss_PK_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "ss_PK_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="SS_PK_TM" className="font-bold">
                    Pickup  Time
                  </label>
                  <InputMask
                    id={SS_PK_TM}
                    name={SS_PK_TM}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("SS_PK_TM", e.target.value,); }}
                    value={moment(SS_PK_TM, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
      
      {/* SOLD TO POPUP */}
      <AddressBookPopup
        visible={SoldToPopupVisible}
        onClose={() => setSoldToPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={SoldTotype}
        setFormData={setFormData}
      />
      {/* SHIP TO POPUP */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelectShip}
        formData={data}
        addressType={ShipToType}
        setFormData={setFormData}
      />
    </Dialog >
  );
}

