import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function MovementInstructionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MovementInstructionTableData,
  setMovementInstructionTableData,
  mi_PA_IN,
  mi_PK_IN,
  mi_RE_IN,
  mi_PA_MO,
  mi_PK_MO,
  mi_RE_MO,
  mi_CO_CT,
  mi_IT_UM,
  mi_RA_ME,
  mi_RA_RU,
  mi_PA_ZO,
  mi_PK_ZO,
  mi_RP_ZO,
  setmi_PA_IN,
  setmi_PK_IN,
  setmi_RE_IN,
  setmi_PA_MO,
  setmi_PK_MO,
  setmi_RE_MO,
  setmi_CO_CT,
  setmi_IT_UM,
  setmi_RA_ME,
  setmi_RA_RU,
  setmi_PA_ZO,
  setmi_PK_ZO,
  setmi_RP_ZO,
  setselectedSupplier,
}) {
  const {
    miUkNo,
    mi_PA_MI,
    mi_PA_MA,
    mi_PK_MI,
    mi_PK_MA,
    mi_RP_MI,
    mi_PR_MA,
    mi_CO_NO,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader,Dialogstyle, cancelstyle, savestyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [mi_PA_INOptions, setmi_PA_INOptions] = useState([]);
  const [mi_PK_INOptions, setmi_PK_INOptions] = useState([]);
  const [mi_RE_INOptions, setmi_RE_INOptions] = useState([]);
  const [mi_PA_MOOptions, setmi_PA_MOOptions] = useState([]);
  const [mi_PK_MOOptions, setmi_PK_MOOptions] = useState([]);
  const [mi_RE_MOOptions, setmi_RE_MOOptions] = useState([]);
  const [mi_CO_CTOptions, setmi_CO_CTOptions] = useState([]);
  const [mi_RA_MEOptions, setmi_RA_MEOptions] = useState([]);

  const [mi_IT_UMOptions, setmi_IT_UMOptions] = useState([]);
  const [mi_RA_RUOptions, setmi_RA_RUOptions] = useState([]);
  const [mi_PA_ZOOptions, setmi_PA_ZOOptions] = useState([]);
  const [mi_PK_ZOOptions, setmi_PK_ZOOptions] = useState([]);
  const [mi_RP_ZOOptions, setmi_RP_ZOOptions] = useState([]);



  // Putaway Instruction
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_PA_IN(value);
  };

  // Picking Instruction 
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_PK_IN(value);
  };
  //Replenishment Instruction
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_RE_IN(value);
  };

  // Putaway Mode
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_PA_MO(value);
  };

  // Picking Mode 
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_PK_MO(value);
  };
  //Replenishment Mode
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_RE_MO(value);
  };
  //Cost Center
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_CO_CT(value);
  };
  //UM
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_IT_UM(value);
  };
  //Method
  const MethodDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_RA_ME(value);
  };
  //Random Rule
  const RandomRuleDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_RA_RU(value);
  };

  //Putaway Zone
  const PutawayZoneDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_PA_ZO(value);
  };
  //Picking Zone
  const PickingZoneDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_PK_ZO(value);
  };

  //Replenishment Zone
  const ReplenishmentZoneDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmi_RP_ZO(value);
  };



  useEffect(() => {
    PutawayInstruction();
    PickingInstruction();
    ReplenishmentInstruction();
    PutawayMode();
    PickingMode();
    ReplenishmentMode();
    CostCentre();
    UM();
    Method();
    RandomRule();
    PutawayZone();
    PickingZone();
    ReplenishmentZone();
    PolicyName();
    

  }, []);

  const PutawayInstruction = async () => {
    const Options = await fetchClassCodeDS("PU/IN");
    setmi_PA_INOptions(Options);
  };
  const PickingInstruction = async () => {
    const Options = await fetchClassCodeDS("PK/IN");
    setmi_PK_INOptions(Options);
  };
  const ReplenishmentInstruction = async () => {
    const Options = await fetchClassCodeDS("RP/IN");
    setmi_RE_INOptions(Options);
  };

  const PutawayMode = async () => {
    const Options = await fetchClassCodeDS("PU/MO");
    setmi_PA_MOOptions(Options);
  };
  const PickingMode = async () => {
    const Options = await fetchClassCodeDS("PK/MO");
    setmi_PK_MOOptions(Options);
  };
  const ReplenishmentMode = async () => {
    const Options = await fetchClassCodeDS("RP/MO");
    setmi_RE_MOOptions(Options);
  };
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setmi_CO_CTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setmi_IT_UMOptions(Options);
  };
  const Method = async () => {
    const Options = await fetchClassCodeDS("MI/ME");
    setmi_RA_MEOptions(Options);
  };
  const RandomRule = async () => {
    const Options = await fetchClassCodeDS("RA/RL");
    setmi_RA_RUOptions(Options);
  };

  const PutawayZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setmi_PA_ZOOptions(Options);
  };
  const PickingZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setmi_PK_ZOOptions(Options);
  };

  const ReplenishmentZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setmi_RP_ZOOptions(Options);
  };


  const PolicyName = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setmi_PK_INOptions(Options);
  };


  const addRow = () => {
    setMovementInstructionTableData([...MovementInstructionTableData, {}]);
  };

  const deleteRow = () => {
    if (MovementInstructionTableData.length > 0) {
      const updatedData = [...MovementInstructionTableData];
      updatedData.pop();
      setMovementInstructionTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(miUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
    </React.Fragment>
  );
  const [addressTypeSUP, setaddressTypeSUP] = useState("S");
  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleDataSelect = async (data) => {
    setselectedSupplier(data.ab_AD_NM);
    document.getElementById(`ep_PR_SU_${SelectedRowIndex}`).value = data.abAdNo;
    document.getElementById(`sup_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{miUkNo ? "Revise Movement Instruction" : "Add Movement Instruction"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >

      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="mi_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
                id="mi_CO_CT"
                name="mi_CO_CT"
                value={mi_CO_CT}
                options={mi_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData7("mi_CO_CT", e.value)}
                placeholder="Select Cost Centre"
              />
            </div>
       
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ep_PO_NM" className="font-bold">
                Putaway Instruction
              </label>
              <Dropdown
                id="mi_PA_IN"
                name="mi_PA_IN"
                value={mi_PA_IN}
                options={mi_PA_INOptions}
                onChange={(e) => onchangeDropDownFormData1("mi_PA_IN", e.value)}
                placeholder="Select Putaway Instruction"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="mi_PK_IN" className="font-bold">
                Picking Instruction
              </label>
              <Dropdown
                name="mi_PK_IN"
                value={mi_PK_IN}
                options={mi_PK_INOptions}
                onChange={(e) => onchangeDropDownFormData2("mi_PK_IN", e.value)}
                placeholder="Select Picking Instruction"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="mi_RE_IN" className="font-bold">
                Replenishment Instruction
              </label>
              <Dropdown
                name="mi_RE_IN"
                value={mi_RE_IN}
                options={mi_RE_INOptions}
                onChange={(e) => onchangeDropDownFormData3("mi_RE_IN", e.value)}
                placeholder="Select Replenishment Instruction"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="mi_PA_MO" className="font-bold">
                Putaway Mode
              </label>
              <Dropdown
                id="mi_PA_MO"
                name="mi_PA_MO"
                value={mi_PA_MO}
                options={mi_PA_MOOptions}
                onChange={(e) => onchangeDropDownFormData4("mi_PA_MO", e.value)}
                placeholder="Select  Putaway Mode"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="mi_PK_MO" className="font-bold">
                Picking Mode
              </label>
              <Dropdown
                name="mi_PK_MO"
                value={mi_PK_MO}
                options={mi_PK_MOOptions}
                onChange={(e) => onchangeDropDownFormData5("mi_PK_MO", e.value)}
                placeholder="Select Picking Mode"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="mi_RE_MO" className="font-bold">
                Replenishment Mode
              </label>
              <Dropdown
                name="mi_RE_MO"
                value={mi_RE_MO}
                options={mi_RE_MOOptions}
                onChange={(e) => onchangeDropDownFormData6("mi_RE_MO", e.value)}
                placeholder="Select Replenishment Mode"
              />
            </div>

          </div>
        </div>

        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="MovementInstructiontable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Seq No</th>
                  <th>UM</th>
                  <th>Method</th>
                  <th>Random Rule</th>
                  <th>Putaway Zone</th>
                  <th>Min Putaway</th>
                  <th>Max Putaway</th>
                  <th>Picking Zone</th>

                  <th>Min Picking</th>
                  <th>Max Picking</th>
                  <th>Replenishment Zone</th>
                  <th>Min Replenishment</th>
                  <th>Max Replenishment</th>
                  <th>Company</th>
                </tr>
              </thead>
              <tbody>
                {MovementInstructionTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`miUkNo_${index}`}
                        name={`miUkNo_${index}`}
                        value={row.miUkNo}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "90px" }}
                        id={`mi_SQ_NO_${index}`}
                        name={`mi_SQ_NO_${index}`}
                        value={index + 1}
                        readOnly
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`mi_IT_UM_${index}`}
                        name={`mi_IT_UM_${index}`}
                        value={data[`mi_IT_UM_${index}`] ? data[`mi_IT_UM_${index}`] : row.mi_IT_UM}
                        options={mi_IT_UMOptions}
                        onChange={(e) => UMDropdown(`mi_IT_UM_${index}`, e.value)}
                        placeholder="Select UM"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`mi_RA_ME_${index}`}
                        name={`mi_RA_ME_${index}`}
                        value={data[`mi_RA_ME_${index}`] ? data[`mi_RA_ME_${index}`] : row.mi_RA_ME}
                        options={mi_RA_MEOptions}
                        onChange={(e) => MethodDropdown(`mi_RA_ME_${index}`, e.value)}
                        placeholder="Select Method"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`mi_RA_RU_${index}`}
                        name={`mi_RA_RU_${index}`}
                        value={data[`mi_RA_RU_${index}`] ? data[`mi_RA_RU_${index}`] : row.mi_RA_RU}
                        options={mi_RA_RUOptions}
                        onChange={(e) => RandomRuleDropdown(`mi_RA_RU_${index}`, e.value)}
                        placeholder="Select Random Rule"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`mi_PA_ZO_${index}`}
                        name={`mi_PA_ZO_${index}`}
                        value={data[`mi_PA_ZO_${index}`] ? data[`mi_PA_ZO_${index}`] : row.mi_PA_ZO}
                        options={mi_PA_ZOOptions}
                        onChange={(e) => PutawayZoneDropdown(`mi_PA_ZO_${index}`, e.value)}
                        placeholder="Select Putaway Zone"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "90px" }}
                        id={`mi_PA_MI_${index}`}
                        name={`mi_PA_MI_${index}`}
                        defaultValue={row.mi_PA_MI}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "90px" }}
                        id={`mi_PA_MA_${index}`}
                        name={`mi_PA_MA_${index}`}
                        defaultValue={row.mi_PA_MA}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`mi_PK_ZO_${index}`}
                        name={`mi_PK_ZO_${index}`}
                        value={data[`mi_PK_ZO_${index}`] ? data[`mi_PK_ZO_${index}`] : row.mi_PK_ZO}
                        options={mi_PK_ZOOptions}
                        onChange={(e) => PickingZoneDropdown(`mi_PK_ZO_${index}`, e.value)}
                        placeholder="Select Picking Zone"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "90px" }}
                        id={`mi_PK_MI_${index}`}
                        name={`mi_PK_MI_${index}`}
                        defaultValue={row.mi_PK_MI}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "90px" }}
                        id={`mi_PK_MA_${index}`}
                        name={`mi_PK_MA_${index}`}
                        defaultValue={row.mi_PK_MA}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`mi_RP_ZO_${index}`}
                        name={`mi_RP_ZO_${index}`}
                        value={data[`mi_RP_ZO_${index}`] ? data[`mi_RP_ZO_${index}`] : row.mi_RP_ZO}
                        options={mi_RP_ZOOptions}
                        onChange={(e) => ReplenishmentZoneDropdown(`mi_RP_ZO_${index}`, e.value)}
                        placeholder="Select Replenishment Zone"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "90px" }}
                        id={`mi_RP_MI_${index}`}
                        name={`mi_RP_MI_${index}`}
                        defaultValue={row.mi_RP_MI}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "90px" }}
                        id={`mi_PR_MA_${index}`}
                        name={`mi_PR_MA_${index}`}
                        defaultValue={row.mi_PR_MA}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "90px" }}
                        id={`mi_CO_NO_${index}`}
                        name={`mi_CO_NO_${index}`}
                        defaultValue={mi_CO_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
       {/* SUPPLIER */}
      <AddressBookPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeSUP}
        setFormData={setFormData}
      />

    </Dialog>
  );
}
