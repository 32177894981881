import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ClassCodePopup from "views/admin/Popups/ClassCodePopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function BatchNumberDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  bn_DO_CO,
  bn_AU_RE,
  setbn_AU_RE,
  SelectedType,
  setselectedType,
  SelectedDesc,
  setselectedDesc,
}) {
  const {
    bnUkNo,
    bn_DS_PN,
    bn_BA_NO,
    bn_DO_TY
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [bn_AU_REOptions, setbn_AU_REOptions] = useState([]);
  // loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbn_AU_RE(value);
  };
  useEffect(() => {
    fetchDropdownLN();
  }, []);
  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setbn_AU_REOptions(Options);
  };
  const [TypeVisible, setTypeVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleType =async (data) => {
    setselectedType(data.cl_CL_CD)
    setselectedDesc(data.cl_CL_DS)
    onchangeFormData("bn_DO_TY", data.cl_CL_CD);
    onchangeFormData("bn_DS_PN", data.cl_CL_DS);
    setTypeVisible(false);
    setShowSearchIcon(false);
  };
  const openTypePopup = (e) => {
    setTypeVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(bnUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{bnUkNo ? "Revise Batch Number" : "Add Batch Number"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">              
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bn_DO_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="bn_DO_CO"
                name="bn_DO_CO"
                value={bn_DO_CO}
                readOnly
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bn_DO_TY" className="font-bold">
              Batch Type <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText 
                  id="bn_DO_TY"
                  name="bn_DO_TY"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedType}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openTypePopup}
                ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bn_DS_PN" className="font-bold">
              Description
              </label>
              <InputText disabled
                id="bn_DS_PN"
                name="bn_DS_PN"
                value={SelectedDesc}
                readOnly
              />
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bn_BA_NO" className="font-bold">
                Next Number
              </label>
              <InputText
                id="bn_BA_NO"
                name="bn_BA_NO"
                value={bn_BA_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bn_AU_RE" className="font-bold">
              Auto Reset
              </label>
              <Dropdown
                name="bn_AU_RE"
                value={bn_AU_RE}
                options={[
                  { label: "Yes", value: "y" },
                  { label: "No", value: "n" },
                  ]}
                onChange={(e) => onchangeDropDownFormData3("bn_AU_RE", e.value)}
                placeholder="Select  Auto Reset"
              />
            </div>
          </div>
        </div>
      </div> 
      <ClassCodePopup
        visible={TypeVisible}
        onClose={() => setTypeVisible(false)}
        onDataSelect={handleType}
        formData={data}
      />
    </Dialog>
  );
}
