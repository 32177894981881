import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AssetCashDialog from "./AssetCashDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  fmUkNo: "",
  fm_AT_NO: "",
  fm_AT_D1: "",
  fm_AT_D2: "",
  fm_AT_ST: "",
  fm_AT_SL: "",
  fm_AT_LO: "",
  fm_CO_NO: "",
  fm_CO_NM: "",
  fm_DT_AC: "",
  fm_CO_AC:"",
  assetCostAccountDes: "",
  fm_AD_AC: "",
  assetAccumulatedDes: "",
  fm_DP_AC: "",
  assetDepreciationDes: "",
  fm_DP_SD: "",
  fm_DP_MT: "",
  fm_AT_LF: "",
  gl_GL_DT:"",
  gl_AC_NO:"",
  gl_AT_NO:"",
  gl_AC_AM:"",
};

const initialFilters = {
  fm_AT_NO: { value: "", operation: "" },
  fm_AT_D1: { value: "", operation: "" },
  fm_AT_D2: { value: "", operation: "" },
  fm_AT_ST: { value: "", operation: "" },
  fm_AT_SL: { value: "", operation: "" },
  fm_AT_LO: { value: "", operation: "" },
  fm_CO_NO: { value: "", operation: "" },
  fm_CO_NM: { value: "", operation: "" },
  fm_DT_AC: { value: "", operation: "" },
  fm_CO_AC: { value: "", operation: "" },
  assetCostAccountDes: { value: "", operation: "" },
  fm_AD_AC: { value: "", operation: "" },
  assetAccumulatedDes: { value: "", operation: "" },
  fm_DP_AC: { value: "", operation: "" },
  assetDepreciationDes: { value: "", operation: "" },
  fm_DP_SD: { value: "", operation: "" },
  fm_DP_MT: { value: "", operation: "" },
  fm_AT_LF: { value: "", operation: "" },
};

const AssetCashMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,ListDesc,inputStyle1,
  inputStyle,tableCellStyle } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");
  const [SelectedemEMNOF, setSelectedemEMNOF] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");

  const [gl_CR_CD, setgl_CR_CD] = useState([]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setgl_CR_CD(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setgl_CR_CD(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AssetCash/Selects/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const AssetCashData = response.data;
          delete AssetCashData.fmUkNo;
          setgl_CR_CD(AssetCashData.gl_CR_CD);
          setFormData(AssetCashData.journalEntry);
          setSelectedemEMNO(AssetCashData.name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [gl_DO_CO, setgl_DO_CO] = useState("");
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    setgl_DO_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/FixedAssetMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({...prevData,  [name]: value,}));
    setgl_CR_CD(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/AssetCash/Selects/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const AssetCashData = response.data;
        setFormData(AssetCashData.journalEntry);
        //SelectedemEMNO1(AssetCashData.journalEntry.gl_AT_NO);
        setSelectedemEMNO(response.data.name);
        setgl_CR_CD(response.data.gl_CR_CD);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit =  async () => {
    const  Url = `${domain}/api/AssetCash/Add`
    const request = {
      "journalEntry":{
        "gl_AT_NO":document.getElementById("fm_AT_NO").value,
        "gl_BA_NO":document.getElementById("gl_BA_NO").value,
        "gl_DO_NO":document.getElementById("gl_DO_NO").value,
        "gl_DO_TY":document.getElementById("gl_DO_TY").value,
        "gl_DO_CO":document.getElementById("gl_DO_CO").value,
        "gl_GL_DT":FormData.gl_GL_DT,
        "gl_CR_CD": gl_CR_CD,
        "gl_CR_RT":document.getElementById("gl_CR_RT").value,
        "gl_AC_NO":document.getElementById("gl_AC_NO").value,
        "gl_AC_AM":document.getElementById("gl_AC_AM").value,
    }
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const contactStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width:"0%",
  };
  const tableCellStyleT = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
    width:"0%",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
            <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_NO"] ? filters["fm_AT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_NO",
                        e.target.value,
                        filters["fm_AT_NO"]
                          ? filters["fm_AT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_D1"] ? filters["fm_AT_D1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_D1",
                        e.target.value,
                        filters["fm_AT_D1"] ? filters["fm_AT_D1"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_D2"] ? filters["fm_AT_D2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_D2",
                        e.target.value,
                        filters["fm_AT_D2"]
                          ? filters["fm_AT_D2"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_ST"] ? filters["fm_AT_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_ST",
                        e.target.value,
                        filters["fm_AT_ST"]
                          ? filters["fm_AT_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_SL"] ? filters["fm_AT_SL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_SL",
                        e.target.value,
                        filters["fm_AT_SL"]
                          ? filters["fm_AT_SL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_LO"] ? filters["fm_AT_LO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_LO",
                        e.target.value,
                        filters["fm_AT_LO"]
                          ? filters["fm_AT_LO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_CO_NO"] ? filters["fm_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CO_NO",
                        e.target.value,
                        filters["fm_CO_NO"]
                          ? filters["fm_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_CO_NM"] ? filters["fm_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CO_NM",
                        e.target.value,
                        filters["fm_CO_NM"]
                          ? filters["fm_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DT_AC"] ? filters["fm_DT_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DT_AC",
                        e.target.value,
                        filters["fm_DT_AC"]
                          ? filters["fm_DT_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>         
                <td>
                  <input
                    type="text"
                    value={filters["fm_CO_AC"] ? filters["fm_CO_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_CO_AC",
                        e.target.value,
                        filters["fm_CO_AC"]
                          ? filters["fm_CO_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["assetCostAccountDes"] ? filters["assetCostAccountDes"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "assetCostAccountDes",
                        e.target.value,
                        filters["assetCostAccountDes"] ? filters["assetCostAccountDes"].operation : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AD_AC"] ? filters["fm_AD_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AD_AC",
                        e.target.value,
                        filters["fm_AD_AC"]
                          ? filters["fm_AD_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["assetAccumulatedDes"] ? filters["assetAccumulatedDes"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "assetAccumulatedDes",
                        e.target.value,
                        filters["assetAccumulatedDes"]
                          ? filters["assetAccumulatedDes"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_AC"] ? filters["fm_DP_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_AC",
                        e.target.value,
                        filters["fm_DP_AC"]
                          ? filters["fm_DP_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["assetDepreciationDes"] ? filters["assetDepreciationDes"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "assetDepreciationDes",
                        e.target.value,
                        filters["assetDepreciationDes"]
                          ? filters["assetDepreciationDes"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_SD"] ? filters["fm_DP_SD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_SD",
                        e.target.value,
                        filters["fm_DP_SD"]
                          ? filters["fm_DP_SD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_DP_MT"] ? filters["fm_DP_MT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_DP_MT",
                        e.target.value,
                        filters["fm_DP_MT"]
                          ? filters["fm_DP_MT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fm_AT_LF"] ? filters["fm_AT_LF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fm_AT_LF",
                        e.target.value,
                        filters["fm_AT_LF"]
                          ? filters["fm_AT_LF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Asset No </th>
                <th style={tableHeaderStyle}>Asset Description</th>
                <th style={tableHeaderStyle}>Asset Description2</th>
                <th style={tableHeaderStyle}>Asset Status</th>
                <th style={tableHeaderStyle}>Asset Serial No</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Date Acquired</th>
                <th style={tableHeaderStyle}>Asset Cost Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Asset Accumulated Dep Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Asset Deprication Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Deprication Start Date</th>
                <th style={tableHeaderStyle}>Deprication Method</th>
                <th style={tableHeaderStyle}>Life in Months</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fmUkNo}
                        checked={selectedRows.includes(rowData.fmUkNo)}
                        onChange={() => handleRowSelect(rowData.fmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fm_AT_NO}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_D1}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_D2}</td>
                    <td style={tableCellStyleC}>{rowData.fm_AT_ST}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_SL}</td>
                    <td style={tableCellStyle}>{rowData.fm_AT_LO}</td>
                    <td style={tableCellStyleT}>{rowData.fm_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.fm_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.fm_DT_AC}</td>
                    <td style={tableCellStyle}>{rowData.fm_CO_AC}</td>
                    <td style={tableCellStyle}>{rowData.assetCostAccountDes}</td>
                    <td style={tableCellStyle}>{rowData.fm_AD_AC}</td>
                    <td style={tableCellStyle}>{rowData.assetAccumulatedDes}</td>
                    <td style={tableCellStyle}>{rowData.fm_DP_AC}</td>
                    <td style={tableCellStyle}>{rowData.assetDepreciationDes}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.fm_DP_SD)}</td>  
                    <td style={tableCellStyle}>{rowData.fm_DP_MT}</td>
                    <td style={tableCellStyleT}>{rowData.fm_AT_LF}</td>                 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

         {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <AssetCashDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        gl_CR_CD={gl_CR_CD}
        gl_DO_CO={gl_DO_CO}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedemEMNO1={setSelectedemEMNO1}
        SelectedemEMNOE={SelectedemEMNOE}
        SelectedemEMNOF={SelectedemEMNOF}
        setSelectedemEMNOE={setSelectedemEMNOE}
        setSelectedemEMNOF={setSelectedemEMNOF}
     
      />
    </div>
  );
};

export default AssetCashMain;
