import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const initialFilters = {
  ri_VL_DT: { value: "", operation: "" },
  ri_BA_NK: { value: "", operation: "" },
  ri_AC_NO: { value: "", operation: "" },
  ri_AC_ID: { value: "", operation: "" },
  ri_AC_CR: { value: "", operation: "" },
  ri_CL_BL: { value: "", operation: "" },
  sys_BAL: { value: "", operation: "" },
  ri_DR_CR: { value: "", operation: "" },
  reconcile: { value: "", operation: "" },
};
const BankRecoStatusMain = () => {
  // buttonstyles
const {formatDate, checkboxStyle,tableHeaderStyle,ListDesc,
  inputStyle,tableCellStyle,rightalined,
 } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/BankReconAuto/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4"  right={rightToolbarTemplate} />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{borderCollapse: "collapse",width: "100%",marginTop:"20px"}} >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_VL_DT"] ? filters["ri_VL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_VL_DT",
                        e.target.value,
                        filters["ri_VL_DT"]
                          ? filters["ri_VL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_BA_NK"] ? filters["ri_BA_NK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_BA_NK",
                        e.target.value,
                        filters["ri_BA_NK"]
                          ? filters["ri_BA_NK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_AC_ID"] ? filters["ri_AC_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_AC_ID",
                        e.target.value,
                        filters["ri_AC_ID"]
                          ? filters["ri_AC_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["ri_AC_NO"] ? filters["ri_AC_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_AC_NO",
                        e.target.value,
                        filters["ri_AC_NO"]
                          ? filters["ri_AC_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["ridesc"] ? filters["ridesc"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ridesc",
                        e.target.value,
                        filters["ridesc"]
                          ? filters["ridesc"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["ri_AC_CR"] ? filters["ri_AC_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_AC_CR",
                        e.target.value,
                        filters["ri_AC_CR"]
                          ? filters["ri_AC_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_CL_BL"] ? filters["ri_CL_BL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_CL_BL",
                        e.target.value,
                        filters["ri_CL_BL"]
                          ? filters["ri_CL_BL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sys_BAL"] ? filters["sys_BAL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sys_BAL",
                        e.target.value,
                        filters["sys_BAL"]
                          ? filters["sys_BAL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ri_DR_CR"] ? filters["ri_DR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ri_DR_CR",
                        e.target.value,
                        filters["ri_DR_CR"]
                          ? filters["ri_DR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["reconcile"] ? filters["reconcile"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "reconcile",
                        e.target.value,
                        filters["reconcile"]
                          ? filters["reconcile"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
         
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Bank</th>                
                <th style={tableHeaderStyle}>Account ID</th>
                <th style={tableHeaderStyle}>Account No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Bank Balance</th>
                <th style={tableHeaderStyle}>System Balance</th>
                <th style={tableHeaderStyle}>Difference</th>
                <th style={tableHeaderStyle}>Reconciled</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr key={rowData.riUkNo}
                    style={{ backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff" }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.riUkNo}
                        checked={selectedRows.includes(rowData.riUkNo)}
                        onChange={() => handleRowSelect(rowData.riUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{formatDate(rowData.ri_VL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.ri_BA_NK}</td>
                    <td style={tableCellStyle}>{rowData.ri_AC_ID}</td>
                    <td style={tableCellStyle}>{rowData.ri_AC_NO}</td>                    
                    <td style={tableCellStyle}>{rowData.ridesc}</td>
                    <td style={tableCellStyle}>{rowData.ri_AC_CR}</td>
                    <td style={rightalined}>{rowData.ri_CL_BL}</td>
                    <td style={rightalined}>{rowData.sys_BAL}</td>
                    <td style={tableCellStyle}>{rowData.ri_DR_CR}</td>
                    <td style={tableCellStyle}>{rowData.reconcile}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
     {/* pagination */}
     <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      
    </div>
  );
};

export default BankRecoStatusMain;
