import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function MaterialIssueDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MaterialIssueTableData,
  setMaterialIssueTableData,
  jb_TR_UM,
  setjb_TR_UM,
}) {
  const {
    jbUkNo,
    jb_JB_NO,
    jb_JB_TY,
    jb_JB_CO,
    jb_OR_NO,
    jb_OR_TY,
    jb_OR_CO,jb_JB_QT,jb_JB_UM,jb_IS_DT,
    jh_IS_QT,
  } = data;
  const {plusstyle, minusstyle, tablebutton,tablestyle,borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [jb_TR_UMOptions, setjb_TR_UMOptions] = useState([]);
 // UM
  const onchangeDropDownFormData1 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setjb_TR_UM(value);
  };

  useEffect(() => {
    FetchUM();
  }, []);

  const FetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjb_TR_UMOptions(Options);
  };

  const addRow = () => {
    setMaterialIssueTableData([...MaterialIssueTableData, {}]);
  };
  const deleteRow = () => {
    if (MaterialIssueTableData.length > 0) {
      const updatedData = [...MaterialIssueTableData];
      updatedData.pop();
      setMaterialIssueTableData(updatedData);
    }
  };

  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jbUkNo ? "Revise Material Issue" : "Add Material Issue"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}> 
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_NO" className="font-bold">
                Related Order
              </label>
              <InputText disabled
                id="jb_OR_NO"
                name="jb_OR_NO"
                value={jb_OR_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_TY" className="font-bold">
                Related Type
              </label>
               <InputText disabled
                id="jb_OR_TY"
                name="jb_OR_TY"
                value={jb_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_CO" className="font-bold">
                Company
              </label>
               <InputText disabled
                  id="jb_OR_CO"
                  name="jb_OR_CO"
                  value={jb_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_NO" className="font-bold">
              Order Number
              </label>
              <InputText disabled
                id="jb_JB_NO"
                name="jb_JB_NO"
                value={jb_JB_NO}
                onChange={(e) => onchangedata(e)}
              />              
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_TY" className="font-bold">
                Order Type
              </label>
              <InputText disabled
                id="jb_JB_TY"
                name="jb_JB_TY"
                value={jb_JB_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_CO" className="font-bold">
                Order Company
              </label>
              <InputText disabled
                id="jb_JB_CO"
                name="jb_JB_CO"
                value={jb_JB_CO}
                onChange={(e) => onchangedata(e)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ep_TR_CR" className="font-bold">
              Item Number 
              </label>
                <InputText disabled
                  id="ep_PO_NM"
                  name="ep_PO_NM"
                  value={"ep_PO_NM"}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_QT" className="font-bold">
                Order Quantity
              </label>
                <InputText disabled
                  id="jb_JB_QT"
                  name="jb_JB_QT"
                  value={jb_JB_QT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_UM" className="font-bold">
                UM
              </label>
                <InputText disabled
                  id="jb_JB_UM"
                  name="jb_JB_UM"
                  value={jb_JB_UM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jh_IS_QT" className="font-bold">
                Issued Quantity
              </label>
                <InputText disabled
                  id="jh_IS_QT"
                  name="jh_IS_QT"
                  value={jh_IS_QT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jh_IS_QT" className="font-bold">
                Issue Quantity
              </label>
                <InputText 
                  id="jh_IS_QT"
                  name="jh_IS_QT"
                  value={jh_IS_QT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="jb_IS_DT" className="font-bold">
                    Issued Date
                </label>
                <Calendar
                    id="jb_IS_DT"
                    name="jb_IS_DT"
                    value={moment(data.jb_IS_DT, "YYYYMMDD").isValid() ? moment(data.jb_IS_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "jb_IS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
              </div>
            </div>
        </div>
        <div style={tablestyle}  >
        <div  className="table-buttons"  style={tablebutton} >
            <Button
              icon="pi pi-plus"
              onClick={addRow}
              style={plusstyle}
              tooltip="Add"
            />
            <Button
              icon="pi pi-minus"
              onClick={deleteRow}
              style={minusstyle}
              tooltip="Delete"
            />
          </div>
          <div style={{display:"flex",overflow:"auto"}}>
          <table className="custom-table" id="MaterialIssuetable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th> Line No </th>
                <th> Component Item </th>
                <th> Description </th>
                <th> Required Quantity </th>
                <th> UM </th>
                <th> Issued Quantity </th>
                <th> Cost Centre </th>
                <th> Location </th>
                <th> Stock </th>
                <th> Issue Type </th>
              </tr>
            </thead>
            <tbody>
              {MaterialIssueTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`jbUkNo_${index}`}
                      name={`jbUkNo_${index}`}
                      value={row.jbUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled style={{width:"7rem"}}
                      id={`jb_LN_NO_${index}`}
                      name={`jb_LN_NO_${index}`}
                      value={index +  1}
                    />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}}
                        id={`jb_CO_NM_${index}`}
                        name={`jb_CO_NM_${index}`}
                        value={row.jb_CO_NM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"15rem"}}
                        id={`jb_CO_NM_${index}`}
                        name={`jb_CO_NM_${index}`}
                        value={row.jb_CO_NM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}} disabled
                        id={`jb_OR_QT_${index}`}
                        name={`jb_OR_QT_${index}`}
                        value={row.jb_OR_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                    <Dropdown
                      id={`jb_TR_UM_${index}`}
                      name={`jb_TR_UM_${index}`}
                      value={ data[`jb_TR_UM_${index}`] ? data[`jb_TR_UM_${index}`]: row.jb_TR_UM}
                      options={jb_TR_UMOptions}
                      onChange={(e) =>onchangeDropDownFormData1(`jb_TR_UM_${index}`, e.value)}
                      placeholder="Select Exp Type"
                    />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}}
                        id={`jb_IS_QT_${index}`}
                        name={`jb_IS_QT_${index}`}
                        value={row.jb_IS_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}} disabled
                        id={`jb_CO_CT_${index}`}
                        name={`jb_CO_CT_${index}`}
                        value={row.jb_CO_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}} disabled
                        id={`jb_LO_CN_${index}`}
                        name={`jb_LO_CN_${index}`}
                        value={row.jb_LO_CN}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}} disabled
                        id={`jb_LT_NO_${index}`}
                        name={`jb_LT_NO_${index}`}
                        value={row.jb_LT_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}} disabled
                        id={`jb_IS_TY_${index}`}
                        name={`jb_IS_TY_${index}`}
                        value={row.jb_IS_TY}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>  
    </Dialog>
  );
}
