import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ItemMasterDialog from "./ItemMasterSetupDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  imItNo:"",
  im_IT_NM:"",
  im_IT_DS:"",
  im_ST_TY:"",
  im_GL_CD:"",
  im_PD_GP:"",
  im_PR_FM:"",
  im_PR_UM:"",
  im_SC_UM:"",
  im_IT_CD1:"",
  im_IT_CD2:"",
  im_IT_CD3:"",
  im_SA_CD1:"",
  im_SA_CD2:"",
  im_PR_CD1:"",
  im_PR_CD2:"",
  im_MF_CD1:"",
  im_MF_CD2:"",
  im_PO_UM:"",
  im_SO_UM:"",
  im_PR_GP:"",
};

const initialFilters = {
  im_IT_NM: { value: "", operation: "" },
  im_IT_DS: { value: "", operation: "" },
  im_ST_TY: { value: "", operation: "" },
  im_GL_CD: { value: "", operation: "" },
  im_PD_GP: { value: "", operation: "" },
  im_PR_FM: { value: "", operation: "" },
  im_PR_UM: { value: "", operation: "" },
  im_SC_UM: { value: "", operation: "" },
  im_IT_CD1: { value: "", operation: "" },
  im_IT_CD2: { value: "", operation: "" },
  im_IT_CD3: { value: "", operation: "" },
  im_SA_CD1: { value: "", operation: "" },
  im_SA_CD2: { value: "", operation: "" },
  m_PR_CD1: { value: "", operation: "" },
  im_PR_CD2: { value: "", operation: "" },
  im_MF_CD1: { value: "", operation: "" },
  im_MF_CD2: { value: "", operation: "" },
  im_PO_UM:  { value: "", operation: "" },
  im_SO_UM:  { value: "", operation: "" },
  im_PR_GP: { value: "", operation: "" },
};

const ItemMasterSetupMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles, copyButtonStyles, checkboxStyle,tableHeaderStyle,ListDesc,
      inputStyle,tableCellStyle,tableHeaderStyleH,inputStyleH,tableCellStyleH, } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [im_SG_IT1, setim_SG_IT1 ] = useState("");
  const [im_SG_IT2, setim_SG_IT2 ] = useState("");
  const [im_SG_IT3, setim_SG_IT3 ] = useState("");
  const [im_SG_IT4, setim_SG_IT4 ] = useState("");
  const [im_SG_IT5, setim_SG_IT5 ] = useState("");
  const [im_SG_IT, setim_SG_IT ] = useState("");
  const [im_PR_UM, setim_PR_UM ] = useState("");
  const [im_DU_UM, setim_DU_UM ] = useState("");
  const [im_GL_CD, setim_GL_CD ] = useState("");
  const [im_PD_GP, setim_PD_GP ] = useState("");
  const [im_PR_FM, setim_PR_FM ] = useState("");
  const [im_SC_UM, setim_SC_UM ] = useState("");
  const [im_LN_TY, setim_LN_TY ] = useState("");
  const [lm_CH_CS, setlm_CH_CS ] = useState("");
  const [im_ST_TY, setim_ST_TY ] = useState("");
 
  const [im_PR_GP, setim_PR_GP ] = useState("");
  const [im_PR_MD, setim_PR_MD ] = useState("");
  const [im_IN_CL, setim_IN_CL ] = useState("");
  const [im_PO_CN, setim_PO_CN ] = useState("");
  const [im_ST_PO, setim_ST_PO ] = useState("");
  const [im_FR_PO, setim_FR_PO ] = useState("");
  const [im_TO_PO, setim_TO_PO ] = useState("");
  const [im_GR_CN, setim_GR_CN ] = useState("");
  const [im_ST_GR, setim_ST_GR ] = useState("");
  const [im_FR_GR, setim_FR_GR ] = useState("");
  const [im_TO_GR, setim_TO_GR] = useState("");
  const [im_IT_CD1, setim_IT_CD1 ] = useState("");
  const [im_IT_CD2, setim_IT_CD2 ] = useState("");
  const [im_IT_CD3, setim_IT_CD3 ] = useState("");
  const [im_IT_CD4, setim_IT_CD4 ] = useState("");
  const [im_IT_CD5, setim_IT_CD5 ] = useState("");
  const [im_SA_CD1, setim_SA_CD1 ] = useState("");
  const [im_SA_CD2, setim_SA_CD2 ] = useState("");
  const [im_SA_CD3, setim_SA_CD3 ] = useState("");
  const [im_SA_CD4, setim_SA_CD4 ] = useState("");
  const [im_SA_CD5, setim_SA_CD5 ] = useState("");
  const [im_PR_CD1, setim_PR_CD1 ] = useState("");
  const [im_PR_CD2, setim_PR_CD2 ] = useState("");
  const [im_PR_CD3, setim_PR_CD3 ] = useState("");
  const [im_PR_CD4, setim_PR_CD4 ] = useState("");
  const [im_PR_CD5, setim_PR_CD5 ] = useState("");
  const [im_MF_CD1, setim_MF_CD1 ] = useState("");
  const [im_MF_CD2, setim_MF_CD2 ] = useState("");
  const [im_MF_CD3, setim_MF_CD3 ] = useState("");
  const [im_MF_CD4, setim_MF_CD4 ] = useState("");
  const [im_MF_CD5, setim_MF_CD5 ] = useState("");
  const [im_WH_CD1, setim_WH_CD1 ] = useState("");
  const [im_WH_CD2, setim_WH_CD2 ] = useState("");
  const [im_WH_CD3, setim_WH_CD3 ] = useState("");
  const [im_WH_CD4, setim_WH_CD4 ] = useState("");
  const [im_WH_CD5, setim_WH_CD5 ] = useState("");
  const [im_TR_CD1, setim_TR_CD1 ] = useState("");
  const [im_TR_CD2, setim_TR_CD2 ] = useState("");
  const [im_TR_CD3, setim_TR_CD3 ] = useState("");
  const [im_TR_CD4, setim_TR_CD4 ] = useState("");
  const [im_TR_CD5, setim_TR_CD5 ] = useState("");
  const [im_PO_UM, setim_PO_UM ] = useState("");
  const [im_SO_UM, setim_SO_UM ] = useState("");
  const [im_SH_UM, setim_SH_UM ] = useState("");
  const [im_PD_UM, setim_PD_UM ] = useState("");
  const [im_CO_UM, setim_CO_UM ] = useState("");
  const [im_WT_UM, setim_WT_UM ] = useState("");
  const [im_VL_UM, setim_VL_UM ] = useState("");
  const [im_PL_CD, setim_PL_CD ] = useState("");
  const [im_PL_RL, setim_PL_RL ] = useState("");
  const [im_CS_WT, setim_CS_WT ] = useState("");
  const [im_JW_WT, setim_JW_WT ] = useState("");
  const [im_WX_WT, setim_WX_WT ] = useState("");
  const [im_KT_WT, setim_KT_WT ] = useState("");
  const [im_JW_TY, setim_JW_TY ] = useState("");
  const [im_TX_AP, setim_TX_AP ] = useState("");
  const [im_VA_TX, setim_VA_TX ] = useState("");
  const [im_GS_RT, setim_GS_RT ] = useState("");
  const [im_CH_NO, setim_CH_NO ] = useState("");
  const [im_FD_TX, setim_FD_TX ] = useState("");
  const [im_ST_TX, setim_ST_TX ] = useState("");


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setim_SG_IT1(null);
    setim_PR_UM(null);
    setim_DU_UM(null);
    setim_PD_GP(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ItemMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ItemMasterData = response.data;
          setFormData(ItemMasterData);
          setim_SG_IT(ItemMasterData.im_SG_IT);
          setim_SG_IT1(ItemMasterData.im_SG_IT1);
          setim_SG_IT2(ItemMasterData.im_SG_IT2);
          setim_SG_IT3(ItemMasterData.im_SG_IT3);
          setim_SG_IT4(ItemMasterData.im_SG_IT4);
          setim_SG_IT5(ItemMasterData.im_SG_IT5);
          setim_PR_UM(ItemMasterData.im_PR_UM);
          setim_DU_UM(ItemMasterData.im_DU_UM);
          setim_ST_TY(ItemMasterData.im_ST_TY);
          setim_GL_CD(ItemMasterData.im_GL_CD);
          setim_LN_TY(ItemMasterData.im_LN_TY);
          setim_PD_GP(ItemMasterData.im_PD_GP);
          setim_PR_GP(ItemMasterData.im_PR_GP);
          setim_PR_MD(ItemMasterData.im_PR_MD);
          setim_PR_FM(ItemMasterData.im_PR_FM);
          setim_IN_CL(ItemMasterData.im_IN_CL);
          setim_PO_CN(ItemMasterData.im_PO_CN);
          setim_ST_PO(ItemMasterData.im_ST_PO);
          setim_FR_PO(ItemMasterData.im_FR_PO);
          setim_TO_PO(ItemMasterData.im_TO_PO);
          setim_GR_CN(ItemMasterData.im_GR_CN);
          setim_ST_GR(ItemMasterData.im_ST_GR);
          setim_FR_GR(ItemMasterData.im_FR_GR);
          setim_TO_GR(ItemMasterData.im_TO_GR);
          setim_IT_CD1(ItemMasterData.im_IT_CD1);
          setim_IT_CD2(ItemMasterData.im_IT_CD2);
          setim_IT_CD3(ItemMasterData.im_IT_CD3);
          setim_IT_CD4(ItemMasterData.im_IT_CD4);
          setim_IT_CD5(ItemMasterData.im_IT_CD5);
          setim_SA_CD1(ItemMasterData.im_SA_CD1);
          setim_SA_CD2(ItemMasterData.im_SA_CD2);
          setim_SA_CD3(ItemMasterData.im_SA_CD3);
          setim_SA_CD4(ItemMasterData.im_SA_CD4);
          setim_SA_CD5(ItemMasterData.im_SA_CD5);
          setim_PR_CD1(ItemMasterData.im_PR_CD1);
          setim_PR_CD2(ItemMasterData.im_PR_CD2);
          setim_PR_CD3(ItemMasterData.im_PR_CD3);
          setim_PR_CD4(ItemMasterData.im_PR_CD4);
          setim_PR_CD5(ItemMasterData.im_PR_CD5);
          setim_MF_CD1(ItemMasterData.im_MF_CD1);
          setim_MF_CD2(ItemMasterData.im_MF_CD2);
          setim_MF_CD3(ItemMasterData.im_MF_CD3);
          setim_MF_CD4(ItemMasterData.im_MF_CD4);
          setim_MF_CD5(ItemMasterData.im_MF_CD5);
          setim_WH_CD1(ItemMasterData.im_WH_CD1);
          setim_WH_CD2(ItemMasterData.im_WH_CD2);
          setim_WH_CD3(ItemMasterData.im_WH_CD3);
          setim_WH_CD4(ItemMasterData.im_WH_CD4);
          setim_WH_CD5(ItemMasterData.im_WH_CD5);
          setim_TR_CD1(ItemMasterData.im_TR_CD1);
          setim_TR_CD2(ItemMasterData.im_TR_CD2);
          setim_TR_CD3(ItemMasterData.im_TR_CD3);
          setim_TR_CD4(ItemMasterData.im_TR_CD4);
          setim_TR_CD5(ItemMasterData.im_TR_CD5);
          setim_PR_UM(ItemMasterData.im_PR_UM);
          setim_SC_UM(ItemMasterData.im_SC_UM);
          setim_PO_UM(ItemMasterData.im_PO_UM);
          setim_SO_UM(ItemMasterData.im_SO_UM);
          setim_SH_UM(ItemMasterData.im_SH_UM);
          setim_PD_UM(ItemMasterData.im_PD_UM);
          setim_CO_UM(ItemMasterData.im_CO_UM);
          setim_WT_UM(ItemMasterData.im_WT_UM);
          setim_VL_UM(ItemMasterData.im_VL_UM);
          setim_PL_CD(ItemMasterData.im_PL_CD);
          setim_PL_RL(ItemMasterData.im_PL_RL);
          setim_TX_AP(ItemMasterData.im_TX_AP);
          setim_VA_TX(ItemMasterData.im_VA_TX);
          setim_GS_RT(ItemMasterData.im_GS_RT);
          setim_CH_NO(ItemMasterData.im_CH_NO);
          setim_FD_TX(ItemMasterData.im_FD_TX);
          setim_ST_TX(ItemMasterData.im_ST_TX);
          setim_CS_WT(ItemMasterData.im_CS_WT);
          setim_JW_WT(ItemMasterData.im_JW_WT);
          setim_WX_WT(ItemMasterData.im_WX_WT);
          setim_KT_WT(ItemMasterData.im_KT_WT);
          setim_JW_TY(ItemMasterData.im_JW_TY);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [im_CO_NO , setim_CO_NO] = useState("");
 
    const handleDefault = async () => {
      setim_CO_NO(`${company}`)
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
           
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ItemMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ItemMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ItemMasterData = response.data;
        setFormData(ItemMasterData);
        setim_SG_IT(ItemMasterData.im_SG_IT);
        setim_SG_IT1(ItemMasterData.im_SG_IT1);
        setim_SG_IT2(ItemMasterData.im_SG_IT2);
        setim_SG_IT3(ItemMasterData.im_SG_IT3);
        setim_SG_IT4(ItemMasterData.im_SG_IT4);
        setim_SG_IT5(ItemMasterData.im_SG_IT5);
        setim_PR_UM(ItemMasterData.im_PR_UM);
        setim_DU_UM(ItemMasterData.im_DU_UM);
        setim_ST_TY(ItemMasterData.im_ST_TY);
        setim_GL_CD(ItemMasterData.im_GL_CD);
        setim_LN_TY(ItemMasterData.im_LN_TY);
        setim_PD_GP(ItemMasterData.im_PD_GP);
        setim_PR_GP(ItemMasterData.im_PR_GP);
        setim_PR_MD(ItemMasterData.im_PR_MD);
        setim_PR_FM(ItemMasterData.im_PR_FM);
        setim_IN_CL(ItemMasterData.im_IN_CL);
        setim_PO_CN(ItemMasterData.im_PO_CN);
        setim_ST_PO(ItemMasterData.im_ST_PO);
        setim_FR_PO(ItemMasterData.im_FR_PO);
        setim_TO_PO(ItemMasterData.im_TO_PO);
        setim_GR_CN(ItemMasterData.im_GR_CN);
        setim_ST_GR(ItemMasterData.im_ST_GR);
        setim_FR_GR(ItemMasterData.im_FR_GR);
        setim_TO_GR(ItemMasterData.im_TO_GR);

        setim_IT_CD1(ItemMasterData.im_IT_CD1);
        setim_IT_CD2(ItemMasterData.im_IT_CD2);
        setim_IT_CD3(ItemMasterData.im_IT_CD3);
        setim_IT_CD4(ItemMasterData.im_IT_CD4);
        setim_IT_CD5(ItemMasterData.im_IT_CD5);

        setim_SA_CD1(ItemMasterData.im_SA_CD1);
        setim_SA_CD2(ItemMasterData.im_SA_CD2);
        setim_SA_CD3(ItemMasterData.im_SA_CD3);
        setim_SA_CD4(ItemMasterData.im_SA_CD4);
        setim_SA_CD5(ItemMasterData.im_SA_CD5);

        setim_PR_CD1(ItemMasterData.im_PR_CD1);
        setim_PR_CD2(ItemMasterData.im_PR_CD2);
        setim_PR_CD3(ItemMasterData.im_PR_CD3);
        setim_PR_CD4(ItemMasterData.im_PR_CD4);
        setim_PR_CD5(ItemMasterData.im_PR_CD5);

        setim_MF_CD1(ItemMasterData.im_MF_CD1);
        setim_MF_CD2(ItemMasterData.im_MF_CD2);
        setim_MF_CD3(ItemMasterData.im_MF_CD3);
        setim_MF_CD4(ItemMasterData.im_MF_CD4);
        setim_MF_CD5(ItemMasterData.im_MF_CD5);

        setim_WH_CD1(ItemMasterData.im_WH_CD1);
        setim_WH_CD2(ItemMasterData.im_WH_CD2);
        setim_WH_CD3(ItemMasterData.im_WH_CD3);
        setim_WH_CD4(ItemMasterData.im_WH_CD4);
        setim_WH_CD5(ItemMasterData.im_WH_CD5);

        setim_TR_CD1(ItemMasterData.im_TR_CD1);
        setim_TR_CD2(ItemMasterData.im_TR_CD2);
        setim_TR_CD3(ItemMasterData.im_TR_CD3);
        setim_TR_CD4(ItemMasterData.im_TR_CD4);
        setim_TR_CD5(ItemMasterData.im_TR_CD5);

        setim_PR_UM(ItemMasterData.im_PR_UM);
        setim_SC_UM(ItemMasterData.im_SC_UM);
        setim_PO_UM(ItemMasterData.im_PO_UM);
        setim_SO_UM(ItemMasterData.im_SO_UM);
        setim_SH_UM(ItemMasterData.im_SH_UM);
        setim_PD_UM(ItemMasterData.im_PD_UM);
        setim_CO_UM(ItemMasterData.im_CO_UM);
        setim_WT_UM(ItemMasterData.im_WT_UM);
        setim_VL_UM(ItemMasterData.im_VL_UM);
        setim_PL_CD(ItemMasterData.im_PL_CD);
        setim_PL_RL(ItemMasterData.im_PL_RL);
        setim_TX_AP(ItemMasterData.im_TX_AP);
        setim_VA_TX(ItemMasterData.im_VA_TX);
        setim_GS_RT(ItemMasterData.im_GS_RT);
        setim_CH_NO(ItemMasterData.im_CH_NO);
        setim_FD_TX(ItemMasterData.im_FD_TX);
        setim_ST_TX(ItemMasterData.im_ST_TX);
        setim_CS_WT(ItemMasterData.im_CS_WT);
        setim_JW_WT(ItemMasterData.im_JW_WT);
        setim_WX_WT(ItemMasterData.im_WX_WT);
        setim_KT_WT(ItemMasterData.im_KT_WT);
        setim_JW_TY(ItemMasterData.im_JW_TY);

        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    if (FormData.imItNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ItemMaster/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.im_CO_NO = company;
      axios
        .post(`${domain}/api/ItemMaster/Add`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{  borderCollapse: "collapse",  width: "100%", marginTop: "20px", }} >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IT_NM"] ? filters["im_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_NM",
                        e.target.value,
                        filters["im_IT_NM"]
                          ? filters["im_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_DS",
                        e.target.value,
                        filters["im_IT_DS"] ? filters["im_IT_DS"].operation : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_ST_TY"] ? filters["im_ST_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_ST_TY",
                        e.target.value,
                        filters["im_ST_TY"]
                          ? filters["im_ST_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_GL_CD"] ? filters["im_GL_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_GL_CD",
                        e.target.value,
                        filters["im_GL_CD"]
                          ? filters["im_GL_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_PD_GP"] ? filters["im_PD_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PD_GP",
                        e.target.value,
                        filters["im_PD_GP"]
                          ? filters["im_PD_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_PR_FM"] ? filters["im_PR_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PR_FM",
                        e.target.value,
                        filters["im_PR_FM"]
                          ? filters["im_PR_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_PR_UM"] ? filters["im_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PR_UM",
                        e.target.value,
                        filters["im_PR_UM"]
                          ? filters["im_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_SC_UM"] ? filters["im_SC_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_SC_UM",
                        e.target.value,
                        filters["im_SC_UM"] ? filters["im_SC_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_IT_CD1"] ? filters["im_IT_CD1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_CD1",
                        e.target.value,
                        filters["im_IT_CD1"] ? filters["im_IT_CD1"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_IT_CD2"] ? filters["im_IT_CD2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_CD2",
                        e.target.value,
                        filters["im_IT_CD2"] ? filters["im_IT_CD2"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_IT_CD3"] ? filters["im_IT_CD3"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_CD3",
                        e.target.value,
                        filters["im_IT_CD3"] ? filters["im_IT_CD3"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_SA_CD1"] ? filters["im_SA_CD1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_SA_CD1",
                        e.target.value,
                        filters["im_SA_CD1"] ? filters["im_SA_CD1"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_SA_CD2"] ? filters["im_SA_CD2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_SA_CD2",
                        e.target.value,
                        filters["im_SA_CD2"] ? filters["im_SA_CD2"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_PR_CD1"] ? filters["im_PR_CD1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PR_CD1",
                        e.target.value,
                        filters["im_PR_CD1"] ? filters["im_PR_CD1"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_PR_CD2"] ? filters["im_PR_CD2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PR_CD2",
                        e.target.value,
                        filters["im_PR_CD2"] ? filters["im_PR_CD2"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_MF_CD1"] ? filters["im_MF_CD1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_MF_CD1",
                        e.target.value,
                        filters["im_MF_CD1"] ? filters["im_MF_CD1"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["im_MF_CD2"] ? filters["im_MF_CD2"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_MF_CD2",
                        e.target.value,
                        filters["im_MF_CD2"] ? filters["im_MF_CD2"].operation : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_PO_UM"] ? filters["im_PO_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PO_UM",
                        e.target.value,
                        filters["im_PO_UM"] ? filters["im_PO_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_SO_UM"] ? filters["im_SO_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_SO_UM",
                        e.target.value,
                        filters["im_SO_UM"] ? filters["im_SO_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_PR_GP"] ? filters["im_PR_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PR_GP",
                        e.target.value,
                        filters["im_PR_GP"] ? filters["im_PR_GP"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_SH_LF"] ? filters["im_SH_LF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_SH_LF",
                        e.target.value,
                        filters["im_SH_LF"] ? filters["im_SH_LF"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_LN_TY"] ? filters["im_LN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_LN_TY",
                        e.target.value,
                        filters["im_LN_TY"] ? filters["im_LN_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IN_CL"] ? filters["im_IN_CL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IN_CL",
                        e.target.value,
                        filters["im_IN_CL"] ? filters["im_IN_CL"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_TX_AP"] ? filters["im_TX_AP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_TX_AP",
                        e.target.value,
                        filters["im_TX_AP"] ? filters["im_TX_AP"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["im_VA_TX"] ? filters["im_VA_TX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_VA_TX",
                        e.target.value,
                        filters["im_VA_TX"] ? filters["im_VA_TX"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                 <td>
                  <input
                    type="text"
                    value={filters["im_TX_PE"] ? filters["im_TX_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_TX_PE",
                        e.target.value,
                        filters["im_TX_PE"] ? filters["im_TX_PE"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> */}
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Stocking</th>
                <th style={tableHeaderStyle}>GL Code</th>
                <th style={tableHeaderStyle}>Product Group</th>
                <th style={tableHeaderStyle}>Product Family</th>
                <th style={tableHeaderStyle}>Primary UM</th>
                <th style={tableHeaderStyle}>Secondary UM</th>
                <th style={tableHeaderStyleH}>Inv. Code1</th>
                <th style={tableHeaderStyleH}>Inv. Code2</th>
                <th style={tableHeaderStyleH}>Inv. Code3</th>
                <th style={tableHeaderStyleH}>Sales Code1</th>
                <th style={tableHeaderStyleH}>Sales Code2</th>
                <th style={tableHeaderStyleH}>Pur. Code1</th>
                <th style={tableHeaderStyleH}>Pur. Code2</th>
                <th style={tableHeaderStyleH}>mfg Code1</th>
                <th style={tableHeaderStyleH}>mfg Code2</th>
                <th style={tableHeaderStyle}>Purchasing UM</th>
                <th style={tableHeaderStyle}>Pricing UM</th>
                <th style={tableHeaderStyle}>Price Group</th>
                <th style={tableHeaderStyle}>Shelf Life</th>
                <th style={tableHeaderStyle}>Line Type</th>
                <th style={tableHeaderStyle}>Inventory Level</th>
                <th style={tableHeaderStyle}>Tax Applicable</th>
                {/* <th style={tableHeaderStyle}>Tax Type</th> */}
                {/* <th style={tableHeaderStyle}>Tax %</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.imItNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.imItNo}
                        checked={selectedRows.includes(rowData.imItNo)}
                        onChange={() => handleRowSelect(rowData.imItNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.im_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                    <td style={tableCellStyle}>{rowData.im_ST_TY}</td>
                    <td style={tableCellStyle}>{rowData.im_GL_CD}</td>
                    <td style={tableCellStyle}>{rowData.im_PD_GP}</td> 
                    <td style={tableCellStyle}>{rowData.im_PR_FM}</td>
                    <td style={tableCellStyle}>{rowData.im_PR_UM}</td>  
                    <td style={tableCellStyle}>{rowData.im_SC_UM}</td>   
                    <td style={tableCellStyleH}>{rowData.im_IT_CD1}</td>
                    <td style={tableCellStyleH}>{rowData.im_IT_CD2}</td>
                    <td style={tableCellStyleH}>{rowData.im_IT_CD3}</td> 
                    <td style={tableCellStyleH}>{rowData.im_SA_CD1}</td>
                    <td style={tableCellStyleH}>{rowData.im_SA_CD2}</td>       
                    <td style={tableCellStyleH}>{rowData.im_PR_CD1}</td>
                    <td style={tableCellStyleH}>{rowData.im_PR_CD2}</td>   
                    <td style={tableCellStyleH}>{rowData.im_MF_CD1}</td>
                    <td style={tableCellStyleH}>{rowData.im_MF_CD2}</td>
                    <td style={tableCellStyle}>{rowData.im_PO_UM}</td> 
                    <td style={tableCellStyle}>{rowData.im_SO_UM}</td>
                    <td style={tableCellStyle}>{rowData.im_PR_GP}</td>
                    <td style={tableCellStyle}>{rowData.im_SH_LF}</td>               
                    <td style={tableCellStyle}>{rowData.im_LN_TY}</td>
                    <td style={tableCellStyle}>{rowData.im_IN_CL}</td>
                    <td style={tableCellStyle}>{rowData.im_TX_AP}</td>
                    {/* <td style={tableCellStyle}>{rowData.im_VA_TX}</td> */}
                    {/* <td style={tableCellStyle}>{rowData.im_TX_PE}</td> */}

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ItemMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        im_CO_NO={im_CO_NO}
        im_LN_TY={im_LN_TY}
        setim_LN_TY={setim_LN_TY}
        im_SG_IT1={im_SG_IT1}
        setim_SG_IT1={setim_SG_IT1}
        im_SG_IT2={im_SG_IT2}
        setim_SG_IT2={setim_SG_IT2}
        im_SG_IT3={im_SG_IT3}
        setim_SG_IT3={setim_SG_IT3}
        im_SG_IT4={im_SG_IT4}
        setim_SG_IT4={setim_SG_IT4}
        im_SG_IT5={im_SG_IT5}
        setim_SG_IT5={setim_SG_IT5}
        im_SG_IT={im_SG_IT}
        setim_SG_IT={setim_SG_IT}
        im_PR_UM={im_PR_UM}
        setim_PR_UM={setim_PR_UM}
        im_DU_UM={im_DU_UM}
        setim_DU_UM={setim_DU_UM}
        im_PD_GP={im_PD_GP}
        setim_PD_GP={setim_PD_GP}
        lm_CH_CS={lm_CH_CS}
        setlm_CH_CS={setlm_CH_CS}
        im_ST_TY={im_ST_TY}
        setim_ST_TY={setim_ST_TY}
        im_GL_CD={im_GL_CD}
        setim_GL_CD={setim_GL_CD}
        im_PR_FM={im_PR_FM}
        setim_PR_FM={setim_PR_FM}
        im_SC_UM={im_SC_UM}
        setim_SC_UM={setim_SC_UM}
        im_PR_GP={im_PR_GP}
        setim_PR_GP={setim_PR_GP}
        im_PR_MD={im_PR_MD}
        setim_PR_MD={setim_PR_MD}
        im_IN_CL={im_IN_CL}
        setim_IN_CL={setim_IN_CL}
        im_PO_CN={im_PO_CN}
        setim_PO_CN={setim_PO_CN}
        im_ST_PO={im_ST_PO}
        setim_ST_PO={setim_ST_PO}
        im_FR_PO={im_FR_PO}
        setim_FR_PO={setim_FR_PO}
        im_TO_PO={im_TO_PO}
        setim_TO_PO={setim_TO_PO}
        im_GR_CN={im_GR_CN}
        setim_GR_CN={setim_GR_CN}
        im_ST_GR={im_ST_GR}
        setim_ST_GR={setim_ST_GR}
        im_FR_GR={im_FR_GR}
        setim_FR_GR={setim_FR_GR}
        im_TO_GR={im_TO_GR}
        setim_TO_GR={setim_TO_GR}
        im_IT_CD1={im_IT_CD1}
        setim_IT_CD1={setim_IT_CD1}
        im_IT_CD2={im_IT_CD2}
        setim_IT_CD2={setim_IT_CD2}
        im_IT_CD3={im_IT_CD3}
        setim_IT_CD3={setim_IT_CD3}
        im_IT_CD4={im_IT_CD4}
        setim_IT_CD4={setim_IT_CD4}
        im_IT_CD5={im_IT_CD5}
        setim_IT_CD5={setim_IT_CD5}
        im_SA_CD1={im_SA_CD1}
        setim_SA_CD1={setim_SA_CD1}
        im_SA_CD2={im_SA_CD2}
        setim_SA_CD2={setim_SA_CD2}
        im_SA_CD3={im_SA_CD3}
        setim_SA_CD3={setim_SA_CD3}
        im_SA_CD4={im_SA_CD4}
        setim_SA_CD4={setim_SA_CD4}
        im_SA_CD5={im_SA_CD5}
        setim_SA_CD5={setim_SA_CD5}
        im_PR_CD1={im_PR_CD1}
        setim_PR_CD1={setim_PR_CD1}
        im_PR_CD2={im_PR_CD2}
        setim_PR_CD2={setim_PR_CD2}
        im_PR_CD3={im_PR_CD3}
        setim_PR_CD3={setim_PR_CD3}
        im_PR_CD4={im_PR_CD4}
        setim_PR_CD4={setim_PR_CD4}
        im_PR_CD5={im_PR_CD5}
        setim_PR_CD5={setim_PR_CD5}
        im_MF_CD1={im_MF_CD1}
        setim_MF_CD1={setim_MF_CD1}
        im_MF_CD2={im_MF_CD2}
        setim_MF_CD2={setim_MF_CD2}
        im_MF_CD3={im_MF_CD3}
        setim_MF_CD3={setim_MF_CD3}
        im_MF_CD4={im_MF_CD4}
        setim_MF_CD4={setim_MF_CD4}
        im_MF_CD5={im_MF_CD5}
        setim_MF_CD5={setim_MF_CD5}
        im_WH_CD1={im_WH_CD1}
        setim_WH_CD1={setim_WH_CD1}
        im_WH_CD2={im_WH_CD2}
        setim_WH_CD2={setim_WH_CD2}
        im_WH_CD3={im_WH_CD3}
        setim_WH_CD3={setim_WH_CD3}
        im_WH_CD4={im_WH_CD4}
        setim_WH_CD4={setim_WH_CD4}
        im_WH_CD5={im_WH_CD5}
        setim_WH_CD5={setim_WH_CD5}
        im_TR_CD1={im_TR_CD1}
        setim_TR_CD1={setim_TR_CD1}
        im_TR_CD2={im_TR_CD2}
        setim_TR_CD2={setim_TR_CD2}
        im_TR_CD3={im_TR_CD3}
        setim_TR_CD3={setim_TR_CD3}
        im_TR_CD4={im_TR_CD4}
        setim_TR_CD4={setim_TR_CD4}
        im_TR_CD5={im_TR_CD5}
        setim_TR_CD5={setim_TR_CD5}
        im_PO_UM={im_PO_UM}
        setim_PO_UM={setim_PO_UM}
        im_SO_UM={im_SO_UM}
        setim_SO_UM={setim_SO_UM}
        im_SH_UM={im_SH_UM}
        setim_SH_UM={setim_SH_UM}
        im_PD_UM={im_PD_UM}
        setim_PD_UM={setim_PD_UM}
        im_CO_UM={im_CO_UM}
        setim_CO_UM={setim_CO_UM}
        im_WT_UM={im_WT_UM}
        setim_WT_UM={setim_WT_UM}
        im_VL_UM={im_VL_UM}
        setim_VL_UM={setim_VL_UM}
        im_PL_CD={im_PL_CD}
        setim_PL_CD={setim_PL_CD}
        im_PL_RL={im_PL_RL}
        setim_PL_RL={setim_PL_RL}
        im_CS_WT={im_CS_WT}
        setim_CS_WT={setim_CS_WT}
        im_JW_WT={im_JW_WT}
        setim_JW_WT={setim_JW_WT}
        im_WX_WT={im_WX_WT}
        setim_WX_WT={setim_WX_WT}
        im_KT_WT={im_KT_WT}
        setim_KT_WT={setim_KT_WT}
        im_JW_TY={im_JW_TY}
        setim_JW_TY={setim_JW_TY}
        im_TX_AP={im_TX_AP}
        setim_TX_AP={setim_TX_AP}
        im_VA_TX={im_VA_TX}
        setim_VA_TX={setim_VA_TX}
        im_GS_RT={im_GS_RT}
        setim_GS_RT={setim_GS_RT}
        im_CH_NO={im_CH_NO}
        setim_CH_NO={setim_CH_NO}
        im_FD_TX={im_FD_TX}
        setim_FD_TX={setim_FD_TX}
        im_ST_TX={im_ST_TX}
        setim_ST_TX={setim_ST_TX}
      />
    </div>
  );
};

export default ItemMasterSetupMain;
