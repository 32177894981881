import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SalesOrderDialog from "./SalesOrderDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sd_RQ_DT: "",
  cus_NAME: "",
  sh_SH_TO: "",
  sh_CO_CT: "",
  sh_DS_AT: "",
  sh_DS_PR: "",
  sh_CO_CT: "",
  sh_OR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sh_PO_DT: "",
  sh_CU_PO: "",
  sh_OR_CO: "",
  sh_OR_TY: "",
  sh_OR_CO: "",
  sh_CU_NO: "",
  sh_TR_CR:"",
  sh_PY_TR:"",
  sh_RQ_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sh_PR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sh_SH_DT:"",
  sh_TX_AP:"",
  sh_EX_RT: "",
};

const SalesOrderMain = () => {
  const initialFilters = {
    sd_RQ_DT: { value: "", operation: "" },
   // sh_CU_NO: { value: "", operation: "" },
    name: { value: "", operation: "" },
    sh_SH_TO: { value: "", operation: "" },
    sd_IT_NM: { value: "", operation: "" },
    IM_IT_DS: { value: "", operation: "" },
    sd_OR_QT: { value: "", operation: "" },
    SD_TR_CR: { value: "", operation: "" },
    SD_UN_PR: { value: "", operation: "" },
    sd_TR_UM: { value: "", operation: "" },
    sd_EX_PR: { value: "", operation: "" },
    sh_CO_CT: { value: "", operation: "" },
    sd_OR_DT: { value: "", operation: "" },
    sd_OR_CO: { value: "", operation: "" },
    sdOrNo: { value: "", operation: "" },
    sdOrTy: { value: "", operation: "" },
  };
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,ListDesc,
  copyButtonStyles,checkboxStyle,tableHeaderStyle, inputStyle,tableCellStyle, } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  
  const [sh_PY_TR, setsh_PY_TR] = useState("");
  const [sh_TX_AP, setsh_TX_AP] = useState("");
  const [sh_TR_CR, setsh_TR_CR] = useState("");
  const [sh_BS_CR, setsh_BS_CR] = useState("");
  const [sh_EX_RT, setsh_EX_RT] = useState("");
  const [sh_CU_NO, setsh_CU_NO] = useState("");
  const [sh_SH_TO, setsh_SH_TO] = useState("");
  const [sh_UK_NO, setsh_UK_NO] = useState("");
  const [customer, setCustomer] = useState("");
  const [Status, setStatus] = useState("");
  const [sh_OR_NO, setsh_OR_NO] = useState("");
  const [sh_CO_CT, setsh_CO_CT] = useState("");
  const [sd_CO_CT, setsd_CO_CT] = useState("");
  const [sd_ST_CD, setsd_ST_CD] = useState("");
  const [sd_ST_TY, setsd_ST_TY] = useState("");
  const [sd_LN_TY, setsd_LN_TY] = useState("");
  const [sd_SC_UM, setsd_SC_UM] = useState("");
  const [sh_CU_PG, setsh_CU_PG] = useState("");
  const [sh_PR_AG, setsh_PR_AG] = useState("");
  const [sh_TX_TY, setsh_TX_TY] = useState("");
  const [sh_TX_AR, setsh_TX_AR] = useState("");
  const [sh_AS_TO, setsh_AS_TO] = useState("");
  const [sh_PY_IN, setsh_PY_IN] = useState("");
  const [sales_PERSON, setsales_PERSON] = useState("");
  const [bank_NAME, setbank_NAME] = useState("");
  // FOR CONFIRM FUNCTION
  const [price_COSTCENTRE, setprice_COSTCENTRE] = useState("");
  const [wo_COSTCENTRE, setwo_COSTCENTRE] = useState("");
  const [wo_TYPE, setwo_TYPE] = useState("");
  const [create_PARENT_WO, setcreate_PARENT_WO] = useState("");
  const [parent_WO_TYPE, setparent_WO_TYPE] = useState("");
  const [parent_COM, setparent_COM] = useState("");
  const [pl_RT_FLAG, setpl_RT_FLAG] = useState("");
  const [same_WO_ATTACH, setsame_WO_ATTACH] = useState("");
  const [bl_RT_VAL_FLAG, setbl_RT_VAL_FLAG] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SalesOrderData, setSalesOrderData] = useState([{}]);
  const [sd_ST_CDOptions, setsd_ST_CDOptions] = useState([]);

  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [FDate, setFDate] = useState("");
  const [EDate, setEDate] = useState("");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };

  const addTableRow = () => {
    const newRow = {
      sd_IT_NM: "", 
      im_IT_DS: "",

    };
    setSalesOrderData([...SalesOrderData,  newRow]);

    const bsCr = document.getElementById("sh_BS_CR").value;
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    if(bsCr === sh_TR_CR){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_UT_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_FR_PR_" + i).setAttribute('disabled','disabled');
      }
    } else {
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_FR_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_UT_PR_" + i).setAttribute('disabled','disabled');
      }
    }
  };

  const deleteTableRow = () => {
    if (SalesOrderData.length > 0) {
      const updatedData = [...SalesOrderData];
      updatedData.pop();
      setSalesOrderData(updatedData);
    }
  };


  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      sd_CU_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      sd_ST_CD: {
        value: sts,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };  
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'sdOrNo') {
          constructedColumn = 'sdOrNo';
        } else if(column === 'sdOrTy') {
          constructedColumn = 'sdOrTy';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
  
    const updatedFilters = { ...filters };
    
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const url11 = `${domain}/api/ClassCode/All`;

  const parameter5 = "SO/ST";
  const finalUrl5 = `${url11}/${parameter5}`;

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsd_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setSalesOrderData([{}])
    setFormData(initialValue);
    setsh_PY_TR(null);
    setsh_TR_CR(null);
    setsh_TX_AP(null);
    setsh_OR_NO(null);
    setsh_UK_NO(null);
    setsh_EX_RT(null);
    setsh_CU_NO(null);
    setsh_SH_TO(null);
    setSelectedAbAdNN(null);
    setSelectedAbAdNM(null);
    setOpen(true);
  };



  const handleDataSelect = (data) => {
    setSelectedcustmer(data.ab_AD_NM);
    setCustomer(data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };

  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters2 (value);
  };
  const handleCopyData = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalesOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
  
      if (response.status === 200) {
        const SalesOrderDataOut = response.data;
        delete SalesOrderDataOut.salesHeader.sh_UK_NO;
        delete SalesOrderDataOut.salesHeader.sh_OR_NO;
        for (let i = 0; i < SalesOrderDataOut.salesDetail.length; i++) {
          delete SalesOrderDataOut.salesDetail[i].sh_UK_NO;
          SalesOrderDataOut.salesDetail[i].sd_ST_CD = sd_ST_CD;
        }
        setFormData(SalesOrderDataOut.salesHeader);
        setSalesOrderData(SalesOrderDataOut.salesDetail);
        setsh_PY_TR(SalesOrderDataOut.salesHeader.sh_PY_TR);
        setsh_TR_CR(SalesOrderDataOut.salesHeader.sh_TR_CR);
        setsh_TX_AP(SalesOrderDataOut.salesHeader.sh_TX_AP);
        setsh_CO_CT(SalesOrderDataOut.salesHeader.sh_CO_CT);
        setSelectedAbAdNN(SalesOrderDataOut.salesHeader.cus_NAME);
        setSelectedAbAdNM(SalesOrderDataOut.salesHeader.shipto_NAME);
        setsh_CU_NO(SalesOrderDataOut.salesHeader.sh_CU_NO);
        setsh_SH_TO(SalesOrderDataOut.salesHeader.sh_SH_TO);
        setsh_EX_RT(SalesOrderDataOut.salesHeader.sh_EX_RT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

const [sh_OR_CO , setsh_OR_CO ] = useState("");
const [sh_OR_TY, setsh_OR_TY] = useState("");

const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setsh_OR_CO(`${company}`)
            if(fName === "SH_OR_TY"){
              setsh_OR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SH_CO_CT"){
              onchangeDropDownFormData3("sh_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setsh_CO_CT(dataDefault.versionIds[i].default_VALUE);

            }
            if(fName === "SD_CO_CT"){
              setsd_CO_CT(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SD_ST_CD"){
              setsd_ST_CD(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SH_BS_CR"){
              setsh_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            // FOR CONFIRMFUNCTION FROM VERSION_ID
            if(fName === "PRICE_COSTCENTRE"){
              setprice_COSTCENTRE(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "WO_COSTCENTRE"){
              setwo_COSTCENTRE(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "WO_TYPE"){
              setwo_TYPE(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CREATE_PARENT_WO"){
              setcreate_PARENT_WO(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PARENT_WO_TYPE"){
              setparent_WO_TYPE(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "PL_RT_FLAG"){
              setpl_RT_FLAG(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SAME_WO_ATTACH"){
              setsame_WO_ATTACH(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "BL_RT_VAL_FLAG"){
              setbl_RT_VAL_FLAG(dataDefault.versionIds[i].default_VALUE)
            }
            setparent_COM(`${company}`);
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangeOrderdate = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    onChnageExchangeRate (sh_TR_CR,value);
  };

  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_TX_AP(value);
  };

  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_PY_TR(value);
  };

  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_TR_CR(value);

    const bsCr = document.getElementById("sh_BS_CR").value;
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    if(bsCr === value){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_UT_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_FR_PR_" + i).setAttribute('disabled','disabled');
        document.getElementById("sd_FR_AT_" + i).value = '0';
        document.getElementById("sd_FR_DS_" + i).value = '0';
        document.getElementById("fr_NET_AMOUNT_" + i).value = '0';
      }
    } else {
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_FR_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_UT_PR_" + i).setAttribute('disabled','disabled');
      }
    }
    onChnageExchangeRate (value, FormData.sh_OR_DT);
  };
  function onChnageExchangeRate (frCr,orderDate) {
    const exData={
      "cr_TO_CR": document.getElementById("sh_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": orderDate
    }
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("sh_EX_RT").value = dataExRt.cr_CO_MR;
            setsh_EX_RT(dataExRt.cr_CO_MR);
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  
  }
  

  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_CO_CT(value);
  };

  const onchangeDropDownFormData7 = (name, value, index= null) => {
    setFormData({ ...FormData, [name]: value });
    setsd_ST_CD(value);
   const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    SalesOrderData[index][name]=value;
  };

  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsd_ST_CD(value);
  };
  
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsd_ST_TY(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsd_LN_TY(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsd_SC_UM(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_CU_PG(value);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_PR_AG(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_TX_TY(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_TX_AR(value);
  };
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_AS_TO(value);
  };
  const onchangeDropDownFormData16 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsh_PY_IN(value);
  };

  useEffect(() => {
    handleDefault();
    fetchDropdownOptions();
  }, [currentPage, pageSize, filters]);

  
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SalesOrder/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  
  const onchangeFormData = (name, value, index) => {
    setFormData({ ...FormData, [name]: value });
    const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    SalesOrderData[index][fieldName]=value;
  };


  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleClose = () => {
    setSalesOrderData([{}])
    setFormData(initialValue);
    setsh_PY_TR(null);
    setsh_TR_CR(null);
    setsh_TX_AP(null);
    setsh_OR_NO(null);
    setsh_UK_NO(null);
    setsh_EX_RT(null);
    setsh_CU_NO(null);
    setsh_SH_TO(null);
    setSelectedAbAdNN(null);
    setSelectedAbAdNM(null);
    setOpen(false);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SalesOrder/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
  
      if (response.status === 200) {
        const SalesOrderDataOut = response.data;
        setFormData(SalesOrderDataOut.salesHeader);
        setSalesOrderData(SalesOrderDataOut.salesDetail);
        setsh_PY_TR(SalesOrderDataOut.salesHeader.sh_PY_TR);
        setsh_TR_CR(SalesOrderDataOut.salesHeader.sh_TR_CR);
        setsh_TX_AP(SalesOrderDataOut.salesHeader.sh_TX_AP);
        setsh_OR_NO(SalesOrderDataOut.salesHeader.sh_OR_NO);
        setsh_CO_CT(SalesOrderDataOut.salesHeader.sh_CO_CT);
        setSelectedAbAdNN(SalesOrderDataOut.salesHeader.cus_NAME);
        setSelectedAbAdNM(SalesOrderDataOut.salesHeader.shipto_NAME);
        setsh_UK_NO(SalesOrderDataOut.salesHeader.shUkNo);
        setsh_EX_RT(SalesOrderDataOut.salesHeader.sh_EX_RT);
        setsh_CU_NO(SalesOrderDataOut.salesHeader.sh_CU_NO);
        setsh_SH_TO(SalesOrderDataOut.salesHeader.sh_SH_TO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
      const salesHeader = {
        shUkNo: document.getElementById("shUkNo").value,
        sh_OR_NO: document.getElementById("sh_OR_NO").value,
        sh_OR_CO: document.getElementById("sh_OR_CO").value,
        sh_OR_TY: document.getElementById("sh_OR_TY").value,
        sh_OR_DT: FormData.sh_OR_DT,
        sh_RQ_DT: FormData.sh_RQ_DT,
        sh_CO_CT: sh_CO_CT,
        sh_CU_NO: document.getElementById("sh_CU_NO").value,
        sh_PY_TR: sh_PY_TR,
        sh_TX_AP: FormData.sh_TX_AP,
        sh_SH_TO: document.getElementById("sh_SH_TO").value,
        sh_DS_PR: document.getElementById("sh_DS_PR").value,
        sh_TR_CR: sh_TR_CR,
        sh_EX_RT: document.getElementById("sh_EX_RT").value,
        sh_DS_AT: document.getElementById("sh_DS_AT").value,
        sh_BS_CR: document.getElementById("sh_BS_CR").value,
        sh_PO_DT: FormData.sh_PO_DT,
        sh_PR_DT: FormData.sh_PR_DT,
        sh_CU_PO: document.getElementById("sh_CU_PO").value,
        sh_SH_DT: FormData.sh_SH_DT,

        parent_COM :document.getElementById("parent_COM").value,
        price_COSTCENTRE: document.getElementById("price_COSTCENTRE").value,
        parent_WO_TYPE: document.getElementById("parent_WO_TYPE").value,
        create_PARENT_WO :document.getElementById("create_PARENT_WO").value,
        wo_COSTCENTRE : document.getElementById("wo_COSTCENTRE").value,
        pl_RT_FLAG : document.getElementById("pl_RT_FLAG").value,
        wo_TYPE : document.getElementById("wo_TYPE").value,
        same_WO_ATTACH : document.getElementById("same_WO_ATTACH").value,
        bl_RT_VAL_FLAG : document.getElementById("bl_RT_VAL_FLAG").value,
      };
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const RQ_DT = document.getElementsByName("sd_RQ_DT_" + i)[0]; 
      const PR_DT = document.getElementsByName("sd_PR_DT_" + i)[0];    
      const txAP = document.getElementById ("sd_TX_AP_" + i).value;
      var TX_AP = 'n';
      if(txAP.toUpperCase() === 'Y'){
        TX_AP = 'y';
      }
      const Details = {
        sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
        sdLnNo: document.getElementById("sdLnNo_" + i).value,
        sd_IT_NM: document.getElementById("sd_IT_NM_" + i).value,
        sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
        sd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        sd_UT_PR: document.getElementById("sd_UT_PR_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_DS_PR: document.getElementById("sd_DS_PR_" + i).value,
        sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
        sd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sd_PR_DT: PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
        sd_FR_AT: document.getElementById("sd_FR_AT_" + i).value,
        sd_TX_AP: TX_AP,
        sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
        sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
        sd_ST_CD: document.getElementById("sd_ST_CD_" + i).value,
        sd_FR_DS: document.getElementById("sd_FR_DS_" + i).value,
        sd_CO_CT: document.getElementById("sd_CO_CT_" + i).value,

        sd_ST_TY: table.tBodies[0].rows[i].cells[22].children[0].children[1].children[0].value,
        sd_LN_TY: table.tBodies[0].rows[i].cells[23].children[0].children[1].children[0].value,
        sd_SH_QT: document.getElementById("sd_SH_QT_" + i).value,
        sd_CN_QT: document.getElementById("sd_CN_QT_" + i).value,
        sd_SC_QT: document.getElementById("sd_SC_QT_" + i).value,
        sd_SC_UM: table.tBodies[0].rows[i].cells[27].children[0].children[1].children[0].value,
        sd_LO_CN: document.getElementById("sd_LO_CN_" + i).value,
        sd_UT_CT: document.getElementById("sd_UT_CT_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_MR_PR: document.getElementById("sd_MR_PR_" + i).value,
        sd_MR_AT: document.getElementById("sd_MR_AT_" + i).value,
        sd_IT_PG: document.getElementById("sd_IT_PG_" + i).value,
        sd_HD_DS: document.getElementById("sd_HD_DS_" + i).value,
        sd_RL_NO: document.getElementById("sd_RL_NO_" + i).value,
        sd_RL_TY: document.getElementById("sd_RL_TY_" + i).value,
        sd_RL_CO: document.getElementById("sd_RL_CO_" + i).value,
      };
      salesDetail.push(Details);
    }
    requestBodyInput = {
      salesHeader,
      salesDetail,
    };
  }
  const handleFormSubmit = () => {
    values();
    const orNo = document.getElementById("sh_OR_NO").value;
    const ukNo = document.getElementById("shUkNo").value;
    if (orNo) {
      if (ukNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/SalesOrder/Update`;
    
        if (confirm) {
          axios.put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/SalesOrder/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
        
        const OR_DT = FormData.sh_OR_DT;
        if (OR_DT) {
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${sh_OR_TY}/${secondHalf}/1000/${firstHalf}`;
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
          fetch(finalUrl1, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
          })
            .then((resp) => {
              if (!resp.ok) {
                throw new Error(`Request failed with status: ${resp.status}`);
              }
              return resp.json();
            })
            .then((resp) => {
              setsh_OR_NO(resp.dn_DO_NO);
              document.getElementById("sh_OR_NO").value = resp.dn_DO_NO;

              values();
              axios.post(`${domain}/api/SalesOrder/Add`, requestBodyInput, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp) => {
                    handleResponse(resp.data);
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });

            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
  };  
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  // confirmFunction
  const handleConfirm = () => {
    values();
    // from version_id
    const price_COSTCENTRE = document.getElementById("price_COSTCENTRE").value;
    const wo_COSTCENTRE = document.getElementById("wo_COSTCENTRE").value;
    const wo_TYPE = document.getElementById("wo_TYPE").value;
    const create_PARENT_WO = document.getElementById("create_PARENT_WO").value;
    const parent_WO_TYPE = document.getElementById("parent_WO_TYPE").value;
    const parent_COM = document.getElementById("parent_COM").value;
    const pl_RT_FLAG = document.getElementById("pl_RT_FLAG").value;

    requestBodyInput.price_COSTCENTRE = price_COSTCENTRE;
    requestBodyInput.wo_COSTCENTRE = wo_COSTCENTRE;
    requestBodyInput.wo_TYPE = wo_TYPE;
    requestBodyInput.create_PARENT_WO = create_PARENT_WO;
    requestBodyInput.parent_WO_TYPE = parent_WO_TYPE;
    requestBodyInput.parent_COM = parent_COM;
    requestBodyInput.pl_RT_FLAG = pl_RT_FLAG;
    console.log(requestBodyInput);
    const orNo = document.getElementById("sh_OR_NO").value;
    const ukNo = document.getElementById("shUkNo").value;
    if (orNo) {
    if (ukNo) {
      const confirmurl = `${domain}/api/SalesOrder/ConfirmSalesOrderDtls`;
    try{     
      axios.post(confirmurl, requestBodyInput, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      }
      catch(error){
        console.error("Error updating data:", error);
      };
      } 
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
       {/* <Tooltip target=".confirm-button" position="bottom" mouseTrack>
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Confirm
      </div>
      </Tooltip>
      <Button
        style={{border:"none"}}
        icon="pi pi-check-circle" 
        outlined
        className="mr-2 confirm-button"
        onClick={handleConfirm}
      ></Button> */}
    </React.Fragment>
  ); 
  const tableHeaderStyleOS = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"5%"
  };
  const tableHeaderStyleCC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"6%"
  };
  const tableHeaderStyledes = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"20%"
  };
  const tableHeaderStyleT = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
  };
  const tableHeaderStyleN = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"10%"
  };
  const tableCellStyleQ = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
  };
  const tableCellStyleU = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  const AdvanceSearch = (
    <div className="formgrid grid">
  <div className="field col" >
    <label htmlFor="customer" className="font-bold">
      Customer <span style={{ color: 'red' }}>*</span>
    </label>
    <div style={{ display: "flex" }}>
      <InputText
      style={{display:"none"}}
        id="customer"
        name="customer"
        value={customer}
      />
      <InputText
        id="ab_AD_NM"
        name="ab_AD_NM"
        readOnly
        style={{backgroundColor:"#ffffff"}}
        onChange={(Event) => onchangedata(Event)}
        onFocus={() => setShowSearchIcon(true)}
        value={selectedcustmer}
      />
       {showSearchIcon && (
      <i
        className="pi pi-search"
        style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "4%",
          cursor: "pointer",
        }}
        onClick={openCustomerPopup}
      ></i>
       )}
    </div>
    </div>
 
  <div className="field col" style={{display:"grid"}}>
    <label htmlFor="Status" className="font-bold">
      Status
    </label>
    <Dropdown
    style={{width:"140px"}}
      id="Status"
      name="Status"
      value={Status}
      options={sd_ST_CDOptions}
      onChange={(e) => onchangeDropDown("Status", e.value)}
      placeholder="Select Status"
    />
  </div>
</div>
);

const handleClearButton=()=>{
  setStatus(null);
  setCustomer("");
  setSelectedcustmer("");
  setFilters(initialFilters);
}

const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
const inputStyleH = {
  border: "6px solid #a7d6ff8c",
  textAlign: "left",
  borderRadius: "4px",
 // width:"100%",
  backgroundColor:"#f5f4f4"
};
  return (   
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse",  width: "100%", marginTop: "20px", overflow: "auto"  }} >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrNo"] ? filters["sdOrNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrNo",
                        e.target.value,
                        filters["sdOrNo"]
                          ? filters["sdOrNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrTy"] ? filters["sdOrTy"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrTy",
                        e.target.value,
                        filters["sdOrTy"]
                          ? filters["sdOrTy"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_CO",
                        e.target.value,
                        filters["sd_OR_CO"]
                          ? filters["sd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_DT"] ? filters["sd_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_DT",
                        e.target.value,
                        filters["sd_OR_DT"]
                          ? filters["sd_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_RQ_DT"] ? filters["sd_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_RQ_DT",
                        e.target.value,
                        filters["sd_RQ_DT"]
                          ? filters["sd_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_CU_NO"] ? filters["sd_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_CU_NO",
                        e.target.value,
                        filters["sd_CU_NO"]
                          ? filters["sd_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"] ? filters["name"].operation : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_TO"] ? filters["sd_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_TO",
                        e.target.value,
                        filters["sd_SH_TO"]
                          ? filters["sd_SH_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_IT_NM",
                        e.target.value,
                        filters["sd_IT_NM"]
                          ? filters["sd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input disabled
                    type="text"
                    value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_DS",
                        e.target.value,
                        filters["im_IT_DS"]
                          ? filters["im_IT_DS"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_QT"] ? filters["sd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_QT",
                        e.target.value,
                        filters["sd_OR_QT"]
                          ? filters["sd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_TR_UM",
                        e.target.value,
                        filters["sd_TR_UM"]
                          ? filters["sd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_TR_CR"] ? filters["sd_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_TR_CR",
                        e.target.value,
                        filters["sd_TR_CR"]
                          ? filters["sd_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_UT_PR"] ? filters["sd_UT_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_UT_PR",
                        e.target.value,
                        filters["sd_UT_PR"]
                          ? filters["sd_UT_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_EX_PR"] ? filters["sd_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_EX_PR",
                        e.target.value,
                        filters["sd_EX_PR"]
                          ? filters["sd_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_CO_CT"] ? filters["sd_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_CO_CT",
                        e.target.value,
                        filters["sd_CO_CT"]
                          ? filters["sd_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ST_CD",
                        e.target.value,
                        filters["sd_ST_CD"]
                          ? filters["sd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyleOS}>Order No</th>
                <th style={tableHeaderStyleT}>Type</th>
                <th style={tableHeaderStyle}> Company</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Req. Date</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyleN}>Name</th>
                <th style={tableHeaderStyleOS}>Ship To</th>
                <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyledes}>Description</th>
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyleT}>UM</th>
                <th style={tableHeaderStyleT}>Currency</th>
                <th style={tableHeaderStyle}>Price</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyleCC}>Cost Center</th>
                <th style={tableHeaderStyleT}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sd_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.sd_UK_NO}
                        checked={selectedRows.includes(rowData.sd_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                    <td style={tableCellStyleU}>{rowData.sdOrTy}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_OR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_RQ_DT)}</td>
                    <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>
                    <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                    <td style={tableCellStyleQ}>{rowData.sd_OR_QT}</td>
                    <td style={tableCellStyleU}>{rowData.sd_TR_UM}</td>
                    <td style={tableCellStyleU}>{rowData.sd_TR_CR}</td>
                    <td style={tableCellStyleQ}>{rowData.sd_UT_PR}</td>
                    <td style={tableCellStyleQ}>{rowData.sd_EX_PR}</td>
                    <td style={tableCellStyleU}>{rowData.sd_CO_CT}</td>
                    <td style={tableCellStyleU}>{rowData.sd_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      {/*Customer */}
      <AddressBookPopup
          visible={CustomerPopupVisible}
          onClose={() => setCustomerPopupVisible(false)}
          onDataSelect={handleDataSelect}
          formData={FormData}
          addressType={addressTypeCust}
          setFormData={setFormData}
        />
      <SalesOrderDialog
        handleFormSubmit={handleFormSubmit}
        sh_OR_NO={sh_OR_NO}
        sh_OR_TY={sh_OR_TY}
        sh_OR_CO={sh_OR_CO}
        sh_TX_AP={sh_TX_AP}
        sh_PY_TR={sh_PY_TR}
        sh_TR_CR={sh_TR_CR}
        sh_CU_NO={sh_CU_NO}
        sh_SH_TO={sh_SH_TO}
        setsh_CU_NO={setsh_CU_NO}
        setsh_SH_TO={setsh_SH_TO}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        handleClose={handleClose}
        open={open}
        setOpen={setOpen}
        data={FormData}
        onchangedata={onchangedata}
        sh_UK_NO={sh_UK_NO}
        setsh_UK_NO={setsh_UK_NO}
        selectedAbAdNN={selectedAbAdNN}
        selectedAbAdNM={selectedAbAdNM}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
        FormData={FormData}
        setFormData={setFormData}
        initialValue={initialValue}
        isRecordSelected={isRecordSelected}
        selectedRows={selectedRows}
        handleClickOpen={handleClickOpen}
        onchangeOrderdate={onchangeOrderdate}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData={onchangeDropDownFormData}
        sd_CO_CT={sd_CO_CT}
        sd_ST_CD={sd_ST_CD}
        sh_CO_CT={sh_CO_CT}
        sh_BS_CR={sh_BS_CR}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        SalesOrderData={SalesOrderData}
        deleteTableRow={deleteTableRow}
        addTableRow={addTableRow}
        onchangeFormData={onchangeFormData}
        values={values}
        requestBodyInput={requestBodyInput}
        sh_EX_RT={sh_EX_RT}
        setsh_EX_RT={setsh_EX_RT}
        sd_ST_TY={sd_ST_TY}
        sd_LN_TY={sd_LN_TY}
        sd_SC_UM={sd_SC_UM}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        sh_CU_PG={sh_CU_PG}
        sh_PR_AG={sh_PR_AG}
        sh_TX_TY={sh_TX_TY}
        sh_TX_AR={sh_TX_AR}
        sh_AS_TO={sh_AS_TO}
        sh_PY_IN={sh_PY_IN}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        onchangeDropDownFormData14={onchangeDropDownFormData14}
        onchangeDropDownFormData15={onchangeDropDownFormData15}
        onchangeDropDownFormData16={onchangeDropDownFormData16}
        sales_PERSON={sales_PERSON}
        setsales_PERSON={setsales_PERSON}
        bank_NAME={bank_NAME}
        setbank_NAME={setbank_NAME}
        // CONFRIM FUNCTION FEILDS
        price_COSTCENTRE={price_COSTCENTRE}
        wo_COSTCENTRE={wo_COSTCENTRE}
        wo_TYPE={wo_TYPE}
        create_PARENT_WO={create_PARENT_WO}
        parent_WO_TYPE={parent_WO_TYPE}
        parent_COM={parent_COM}
        pl_RT_FLAG={pl_RT_FLAG}
        handleConfirm={handleConfirm}
        same_WO_ATTACH={same_WO_ATTACH}
        bl_RT_VAL_FLAG={bl_RT_VAL_FLAG}
      />
    </div>
  );
};


export default SalesOrderMain;
