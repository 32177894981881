import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";

export default function VehicleMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  onchangeFormData,
  vm_CO_CT,
  setvm_CO_CT,
  vm_VH_TY,
  setvm_VH_TY,
  vm_VH_ST,
  setvm_VH_ST,
  SelectedemEMNO,
  setSelectedemEMNO,
  vm_WT_UM,
  setvm_WT_UM,
  vm_VO_UM,
  setvm_VO_UM,
}) {
  const {
    vmUkNo,
    vm_VH_ID,
    vm_OW_NR,
    vm_VH_DS,
    vm_WT_CP,
    vm_VO_CP,
    vm_SR_NO,
    vm_VH_NO,

    

  } = data;
  const {borderbox,inboxstyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [vm_CO_CTOptions, setvm_CO_CTOptions] = useState([]);
  const [vm_VH_TYOptions, setvm_VH_TYOptions] = useState([]);
  const [vm_VH_STOptions, setvm_VH_STOptions] = useState([]);
  const [vm_WT_UMOptions, setvm_WT_UMOptions] = useState([]);
  const [vm_VO_UMOptions, setvm_VO_UMOptions] = useState([]);
  //advance search
  const [Addresstype, setAddresstype] = useState("E");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  
  
// Cost Centre
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setvm_CO_CT(value);
  };
// VehicleType
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setvm_VH_TY(value);
  };
// VehicleStatus
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setvm_VH_ST(value);
  };
// WeightUM
const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setvm_WT_UM(value);
};
// VolumeUM
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setvm_VO_UM(value);
};

 
  useEffect(() => {
    fetchDropdownCost();
    fetchVehicleType();
    fetchVehicleStatus();
    fetchWeightUM();
    fetchVolumeUM();


  }, []);
  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setvm_CO_CTOptions(Options);
  };

  const fetchVehicleType = async () => {
    const Options = await fetchClassCodeDS("VH/TY");
    setvm_VH_TYOptions(Options);
  };

  const fetchVehicleStatus = async () => {
    const Options = await fetchClassCodeDS("VH/ST");
    setvm_VH_STOptions(Options);
  };
  const fetchWeightUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setvm_WT_UMOptions(Options);
  };
  const fetchVolumeUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setvm_VO_UMOptions(Options);
  };

   
  const [FacilityAddressPopupVisible, setFacilityAddressPopupVisible] = useState(false);
  const openCustomerPopup = () => {
    setFacilityAddressPopupVisible(true);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("vm_OW_NR", data.abAdNo);
    setFacilityAddressPopupVisible(false);
    setShowSearchIcon(false);
  };

const isUpdateMode = Boolean(vmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );

    // DIALOG HEADER
    const dialogHeader = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h5>{vmUkNo ? "Revise Vehicle Master" : "Add Vehicle Master"}</h5>
        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
          
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        </div>
      </div>
    );

  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >


      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_VH_ID" className="font-bold">
                    Vehicle ID
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="vm_VH_ID"
                      name="vm_VH_ID"
                      value={vm_VH_ID}
                      onChange={(e) => onchangedata(e)}
                    />
                   
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="vm_CO_CT"
                    value={vm_CO_CT}
                    options={vm_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("vm_CO_CT", e.value)}
                    placeholder="Select Cost Center"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_VH_TY" className="font-bold">
                    Vehicle Type
                    </label>
                    <Dropdown
                    name="vm_VH_TY"
                    value={vm_VH_TY}
                    options={vm_VH_TYOptions}
                    onChange={(e) => onchangeDropDownFormData2("vm_VH_TY", e.value)}
                    placeholder="Select Vehicle Type"
                  />
                  </div>

               
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_VH_ST" className="font-bold">
                    Vehicle Status
                    </label>
                    <Dropdown
                    name="vm_VH_ST"
                    value={vm_VH_ST}
                    options={vm_VH_STOptions}
                    onChange={(e) => onchangeDropDownFormData3("vm_VH_ST", e.value)}
                    placeholder="Select Vehicle Status"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="vm_OW_NR" className="font-bold">
                     Owner <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                     {showSearchIcon && (
                    <i  className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                     )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="vm_OW_NR"
                      name="vm_OW_NR"
                      value={vm_OW_NR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_WT_CP" className="font-bold">
                    Weight Capacity
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="vm_WT_CP"
                      name="vm_WT_CP"
                      value={vm_WT_CP}
                      onChange={(e) => onchangedata(e)}
                    />
                      <Dropdown
                        id="vm_WT_UM"
                        name="vm_WT_UM"
                        value={vm_WT_UM}
                        options={vm_WT_UMOptions}
                        onChange={(e) => onchangeDropDownFormData4("vm_WT_UM", e.value)}
                        placeholder="Select  Weight UM"
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_VO_CP" className="font-bold">
                    Volume Capacity
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="vm_VO_CP"
                      name="vm_VO_CP"
                      value={vm_VO_CP}
                      onChange={(e) => onchangedata(e)}
                    />
                      <Dropdown
                        id="vm_VO_UM"
                        name="vm_VO_UM"
                        value={vm_VO_UM}
                        options={vm_VO_UMOptions}
                        onChange={(e) => onchangeDropDownFormData5("vm_VO_UM", e.value)}
                        placeholder="Select  Volume UM"
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="vm_VH_DS" className="font-bold">
                  Description
                  </label>
                  <InputText
                    id="vm_VH_DS"
                    name="vm_VH_DS"
                    value={vm_VH_DS}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                   <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_SR_NO" className="font-bold">
                    Serial Number
                    </label>
                    <InputText
                      id="vm_SR_NO"
                      name="vm_SR_NO"
                      value={vm_SR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="vm_VH_NO" className="font-bold">
                    Vehicle Number
                    </label>
                    <InputText
                      id="vm_VH_NO"
                      name="vm_VH_NO"
                      value={vm_VH_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
      </div>
      <AddressBookPopup
        visible={FacilityAddressPopupVisible}
        onClose={() => setFacilityAddressPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={Addresstype}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
