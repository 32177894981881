import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import PickSlipGenerationDialog from "./PickSlipGenerationDialog";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sd_UK_NO:"",
  sd_OR_CO: "",
  sd_LN_NO: "",
  sd_PK_NO: "",
  sd_CU_NO:"",
  ab_AD_NM:"",
  cus_NAME:"",
  sd_OR_DT:"",
  sd_RQ_DT:"",
  sd_PR_DT: "",
  sd_IT_NM: "",
  item_DESC: "",
  sd_OR_QT:"",
  sd_TR_UM:"",
  sd_SH_QT:"",
  sd_LO_CN:"",
  sd_ST_CD:"",
  sdOrNo:"",
  sdOrTy:"",
  sd_OR_CO:"",
};

const initialFilters = {
  sdOrNo: { value: "", operation: "" },
  sdOrTy: { value: "", operation: "" },
  sd_OR_CO: { value: "", operation: "" },
  sdLnNo: { value: "", operation: "" },
  sd_PK_NO: { value: "", operation: "" },
  sd_CU_NO: { value: "", operation: "" },
  cus_NAME: { value: "", operation: "" },
  sd_SH_TO: { value: "", operation: "" },
  sd_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" }, 
  sd_OR_QT: { value: "", operation: "" }, 
  sd_TR_UM: { value: "", operation: "" },
  sd_SH_QT: { value: "", operation: "" },
  sd_LO_CN: { value: "", operation: "" },
  sd_OR_DT: { value: "", operation: "" }, 
  sd_RQ_DT: { value: "", operation: "" },
  sd_PR_DT: { value: "", operation: "" },
  sd_ST_CD: { value: "", operation: "" }, 
};

const PickSlipGenerationMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, ListDesc,
  checkboxStyle,tableHeaderStyle,tableHeaderStyle1,inputStyle,tableCellStyle,inputStyle1 } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [ware_HOUSE, setware_HOUSE] = useState("");
  const [sh_UK_NO, setsh_UK_NO] = useState("");
  const [sd_IN_BR, setsd_IN_BR] = useState("");
  const [sd_LO_CN, setsd_LO_CN] = useState("");
  const [sdOrTy, setsdOrTy]= useState("");
  const [sd_ST_CD, setsd_ST_CD]= useState("");
  const [selectedCustomer, setselectedCustomer ] = useState("");
  const [selectedShipto, setselectedShipto ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;  
        if (column === 'sdOrNo') {
          constructedColumn = 'sdOrNo';
        } 
        else if (column === 'sdOrTy') {
          constructedColumn = 'sdOrTy';
        }
        else if (column === 'sdLnNo') {
          constructedColumn = 'sdLnNo';
        }
        else {
          constructedColumn = column.toUpperCase();
        }
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "SD_OR_TY"){
              setsdOrTy(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "SD_ST_CD"){
              setsd_ST_CD(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "WARE_HOUSE"){
              setware_HOUSE(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "SD_IN_BR"){
              setsd_IN_BR(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "SD_LO_CN"){
              onchangeDropDownFormData1("sd_LO_CN",dataDefault.versionIds[i].default_VALUE);
              setsd_LO_CN(dataDefault.versionIds[i].default_VALUE);
            }
          }

          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [PickSlipTableData, setPickSlipTableData] = useState([{}]);

  const addRow = () => {
    setPickSlipTableData([...PickSlipTableData, {}]);
  };
  const deleteRow = () => {
    if (PickSlipTableData.length > 0) {
      const updatedData = [...PickSlipTableData];
      updatedData.pop();
      setPickSlipTableData(updatedData);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_LO_CN(value);
  };
  const onchangeFormData = (name, value,index=null) => {
    setFormData((prevData) => ({...prevData,[name]: value, }));
    const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    PickSlipTableData[index][fieldName]=value;
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  }; 
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const table = document.getElementById("PickSliptable");
    const totalRowCount = table.tBodies[0].rows.length;
    var  selected = [];
    for (let i = 0; i < totalRowCount; i++) {
      if(table.tBodies[0].rows[i].cells[0].children[0].checked){
        var v = Number (table.tBodies[0].rows[i].cells[0].children[0].value);
        selected.push(v);
      }
    }
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setSelectedRows(selected);
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    // if (!isRecordSelected || selectedRows.length > 1) {
    //   toast.current.show({
    //     severity: "error",
    //     summary: "Error",
    //     detail: isRecordSelected
    //       ? "Select a single record to edit."
    //       : "Select only one record to edit.",
    //     life: 3000,
    //   });
    //   return;
    // }
    setFormData(initialValue);
    if(selectedRows){
      const salesDetail= [];
      const ukNos = selectedRows.toString().split(",");
      const size = ukNos.length;
   
      for (let i = 0; i < size; i++){
        const Details = {
          sd_UK_NO: ukNos[i],
        }
        salesDetail.push(Details);
      }
      const requestBodyInput = {
          "salesDetail" : salesDetail,
          "salesHeader":{
            "location": sd_LO_CN,
          }
        };
    // console.log(requestBodyInput);
    try {
      const response = await axios.post(
        `${domain}/api/PickSlip/Select`,requestBodyInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const PickSlipData = response.data.Result;
        setFormData(PickSlipData.salesDetail[0]);
        setPickSlipTableData(PickSlipData.salesDetail);
        setsh_UK_NO(PickSlipData.detail.sd_UK_NO);
        setselectedCustomer(PickSlipData.detail.cus_NAME);
        setselectedShipto(PickSlipData.detail.shipto_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleDefault();
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PickSlip/List/${currentPage}/${pageSize}/${sdOrTy}/${sd_ST_CD}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const leftToolbarTemplate = ( 
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Post
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        // disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />

    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table id="PickSliptable"
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["sd_UK_NO"] ? filters["sd_UK_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_UK_NO",
                        e.target.value,
                        filters["sd_UK_NO"]
                          ? filters["sd_UK_NO"].operation
                          : "equals"
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrNo"] ? filters["sdOrNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrNo",
                        e.target.value,
                        filters["sdOrNo"]
                          ? filters["sdOrNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrTy"] ? filters["sdOrTy"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrTy",
                        e.target.value,
                        filters["sdOrTy"]
                          ? filters["sdOrTy"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_CO",
                        e.target.value,
                        filters["sd_OR_CO"]
                          ? filters["sd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdLnNo"] ? filters["sdLnNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdLnNo",
                        e.target.value,
                        filters["sdLnNo"]
                          ? filters["sdLnNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td style={{width:"90px"}}>
                  <input
                    type="text"
                    value={filters["sd_PK_NO"] ? filters["sd_PK_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PK_NO",
                        e.target.value,
                        filters["sd_PK_NO"]
                          ? filters["sd_PK_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_CU_NO"] ? filters["sd_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_CU_NO",
                        e.target.value,
                        filters["sd_CU_NO"]
                          ? filters["sd_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input disabled
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_TO"] ? filters["sd_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_TO",
                        e.target.value,
                        filters["sd_SH_TO"]
                          ? filters["sd_SH_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_IT_NM",
                        e.target.value,
                        filters["sd_IT_NM"]
                          ? filters["sd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"]
                          ? filters["item_DESC"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_QT"] ? filters["sd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_QT",
                        e.target.value,
                        filters["sd_OR_QT"]
                          ? filters["sd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_TR_UM",
                        e.target.value,
                        filters["sd_TR_UM"]
                          ? filters["sd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_QT"] ? filters["sd_SH_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_QT",
                        e.target.value,
                        filters["sd_SH_QT"]
                          ? filters["sd_SH_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_LO_CN"] ? filters["sd_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_LO_CN",
                        e.target.value,
                        filters["sd_LO_CN"]
                          ? filters["sd_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_DT"] ? filters["sd_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_DT",
                        e.target.value,
                        filters["sd_OR_DT"]
                          ? filters["sd_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_RQ_DT"] ? filters["sd_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_RQ_DT",
                        e.target.value,
                        filters["sd_RQ_DT"]
                          ? filters["sd_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_PR_DT"] ? filters["sd_PR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PR_DT",
                        e.target.value,
                        filters["sd_PR_DT"]
                          ? filters["sd_PR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ST_CD",
                        e.target.value,
                        filters["sd_ST_CD"]
                          ? filters["sd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={{display:"none"}}>UK</th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle1}>Pick No</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Ship To</th>
                <th style={tableHeaderStyle}>Item </th>
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>Order Quantity</th>       
                <th style={tableHeaderStyle}>UM</th>                     
                <th style={tableHeaderStyle}>Shipped Quantity</th>   
                <th style={tableHeaderStyle}>Location</th> 
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Requested Date</th>                
                <th style={tableHeaderStyle}>Promised Date</th>               
                <th style={tableHeaderStyle}>Status</th>

              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr key={rowData.sd_UK_NO}
                    style={{ backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff", }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sd_UK_NO}
                        checked={selectedRows.includes(rowData.sd_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                      />
                    </td>
                    <td style={{display:"none"}}>{rowData.sd_UK_NO}</td>
                    <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                    <td style={tableCellStyle}>{rowData.sdOrTy}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sdLnNo}</td>
                    <td style={tableCellStyle}>{rowData.sd_PK_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>
                    <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td> 
                    <td style={tableCellStyle}>{rowData.sd_OR_QT}</td> 
                    <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_QT}</td>
                    <td style={tableCellStyle}>{rowData.sd_LO_CN}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_OR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sd_RQ_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_PR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.sd_ST_CD}</td> 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <PickSlipGenerationDialog
       open={open}
       handleClose={handleClose}
       data={FormData}
       onchangedata={onchangedata}
       PickSlipTableData={PickSlipTableData}
       addRow={addRow}
       deleteRow={deleteRow}
       setFormData={setFormData}
       onchangeFormData={onchangeFormData}
       ware_HOUSE={ware_HOUSE}
       selectedRows={selectedRows}
       setSelectedRows={setSelectedRows}
       sd_IN_BR={sd_IN_BR}
       setsd_IN_BR={setsd_IN_BR}
       sd_LO_CN={sd_LO_CN}
       setsd_LO_CN={setsd_LO_CN}
       selectedCustomer={selectedCustomer}
       setselectedCustomer={setselectedCustomer}
       selectedShipto={selectedShipto}
       setselectedShipto={setselectedShipto}
       sh_UK_NO={sh_UK_NO}
       setsh_UK_NO={setsh_UK_NO}
       handleResponse={handleResponse}
       sdOrTy={sdOrTy}
       sd_ST_CD={sd_ST_CD}
       onchangeDropDownFormData1={onchangeDropDownFormData1}
      />
    </div>
  );
};

export default PickSlipGenerationMain;
