import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import OperationSequencePopup from "../Popups/OperationSequencePopup";
export default function MaterialRequisitionDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  onchangedata,
  setMaterialRequisitiontable,
  MaterialRequisitiontable,
  jb_TR_UM,
  setjb_TR_UM,

  routingItemName,
  setroutingItemName,
  setrt_BT_UM,
  rt_RT_TY,
  setrt_RT_TY,
  rt_TB_CD,
  setrt_TB_CD,
  rtWK_dESC,
  setrtWK_dESC,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData4,
}) {
  const { 
    jbUkNo,
    jb_JB_NO,
    jb_JB_TY,
    jb_JB_CO,
    jb_OR_NO,
    jb_OR_TY,
    jb_OR_CO,jb_JB_QT,jb_JB_UM,jb_IS_DT,
    jh_IS_QT,
  } = data;
  const { borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle } 
    = ButtonStyles();
  // from main page
  const addRow = () => {
    setMaterialRequisitiontable([...MaterialRequisitiontable, {}]);
  };
  const deleteRow = () => {
    if (MaterialRequisitiontable.length > 0) {
      const updatedData = [...MaterialRequisitiontable];
      updatedData.pop();
      setMaterialRequisitiontable(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [RoutingItemPopupVisible, setRoutingItemPopupVisible] = useState(false);
  const openRoutingItemPopup = () => {
    setRoutingItemPopupVisible(true);
  };
  const handleDataSelectItem = (data) => {
  setroutingItemName(data.im_IT_DS);
  onchangeFormData("rt_IT_NM", data.im_IT_NM);
  setRoutingItemPopupVisible(false);
  setShowSearchIcon(false);
  };

  const [WorkCentrePopupVisible, setWorkCentrePopupVisible] = useState(false);
  const [OperSequPopupVisible, setOperSequPopupVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const openWorkcentrePopup = (e) => {
    setWorkCentrePopupVisible(true);
    setSelectedRowIndex(e);
  };
  const openOperationPopup = (e) => {
    setOperSequPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectWKC = (content) => {
    setrtWK_dESC(content.wc_WC_DS);
    document.getElementById(`rtWK_dESC_${SelectedRowIndex}`).value = content.wc_WC_DS;
    document.getElementById(`rt_WK_CT_${SelectedRowIndex}`).value = content.wc_WK_CT;
    setWorkCentrePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectOpSq = (content) => {
    document.getElementById(`rt_OP_DS_${SelectedRowIndex}`).value = content.os_SQ_DS;
    document.getElementById(`rt_OP_SQ_${SelectedRowIndex}`).value = content.os_OP_SQ;
    setOperSequPopupVisible(false);
    setShowSearchIcon(false);
  };

  // class code Dropdowns
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [rt_CO_CTOptions, setrt_CO_CTOptions] = useState([]);
  const [rt_BT_UMOptions, setrt_BT_UMOptions] = useState([]);
  const [rt_RT_TYOptions, setrt_RT_TYOptions] = useState([]);
  const [rt_TB_CDOptions, setrt_TB_CDOptions] = useState([]);

  // UM
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrt_BT_UM(value);
  };

  useEffect(() => {
    fetchCostCentre();
    fetchDropdownDataUM();
    fetchDropdownDataType();
    fetchDropdownTimeBasis();
  }, []);
  // COST center
  const fetchCostCentre = async () => {
    const UMOptions = await fetchCostCenter();
    setrt_CO_CTOptions(UMOptions);
  };
  // UM
  const fetchDropdownDataUM = async () => {
    const UMOptions = await fetchClassCodeCD("IN/UM");
    setrt_BT_UMOptions(UMOptions);
  };
  // Type
  const fetchDropdownDataType = async () => {
    const FVOptions = await fetchClassCodeDS("MF/TY");
    setrt_RT_TYOptions(FVOptions);
  };
  // Time basis
  const fetchDropdownTimeBasis = async () => {
    const Options = await fetchClassCodeDS("MF/TB");
    setrt_TB_CDOptions(Options);
  };

  // DATES DEFAULT MULTIROW
  const [rt_FR_DT, setrt_FR_DT] = useState(moment().toDate());
  const [rt_TO_DT, setrt_TO_DT] = useState(moment().toDate());

  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jbUkNo ? "Revise Material Requisition" : "Add Material Requisition"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
  <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
  >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
              <label htmlFor="jbUkNo" className="font-bold">
                uK
              </label>
              <InputText disabled
                id="jbUkNo"
                name="jbUkNo"
                value={jbUkNo}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_NO" className="font-bold">
                Related Order
              </label>
              <InputText disabled
                id="jb_OR_NO"
                name="jb_OR_NO"
                value={jb_OR_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_TY" className="font-bold">
                Related Type
              </label>
               <InputText disabled
                id="jb_OR_TY"
                name="jb_OR_TY"
                value={jb_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_CO" className="font-bold">
                Company
              </label>
               <InputText disabled
                  id="jb_OR_CO"
                  name="jb_OR_CO"
                  value={jb_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_NO" className="font-bold">
              Order Number
              </label>
              <InputText 
                id="jb_JB_NO"
                name="jb_JB_NO"
                value={jb_JB_NO}
                onChange={(e) => onchangedata(e)}
              />              
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_TY" className="font-bold">
                Order Type
              </label>
              <InputText disabled
                id="jb_JB_TY"
                name="jb_JB_TY"
                value={jb_JB_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_CO" className="font-bold">
                Order Company
              </label>
              <InputText disabled
                id="jb_JB_CO"
                name="jb_JB_CO"
                value={jb_JB_CO}
                onChange={(e) => onchangedata(e)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ep_TR_CR" className="font-bold">
              Item Number 
              </label>
                <InputText disabled
                  id="ep_PO_NM"
                  name="ep_PO_NM"
                  value={"ep_PO_NM"}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="jb_JB_QT" className="font-bold">
                  Order Quantity
                </label>
                <InputText disabled
                  id="jb_JB_QT"
                  name="jb_JB_QT"
                  value={jb_JB_QT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              
             
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="jb_IS_DT" className="font-bold">
                    Issued Date
                </label>
                <Calendar
                    id="jb_IS_DT"
                    name="jb_IS_DT"
                    value={moment(data.jb_IS_DT, "YYYYMMDD").isValid() ? moment(data.jb_IS_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "jb_IS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
              </div>
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="MaterialRequisitiontable" style={{overflow:"auto"}}>
              <thead>
                <tr>
                  <th style={{display:"none"}}>UK_NO</th>
                  <th>Line No</th>
                  <th>Work Centre</th>
                  <th>Description</th>
                  <th>Oper. Seq.</th>
                  <th>Description</th>
                  <th>Instruction</th>
                  <th>Labour Hrs</th>
                  <th>Machine Hrs</th>
                  <th>Setup Hrs</th>
                  <th>Time Basis</th>
                  <th>From Date</th>
                  <th>To Date</th>
                </tr>
              </thead>
              <tbody>
              {MaterialRequisitiontable.map((row, index) => (
                <tr key={index}>
                  <td style={{display:"none"}}>
                    <InputText
                      id={`jbUkNo_${index}`}
                      name={`jbUkNo_${index}`}
                      defaultValue={row.jbUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{width:"5rem"}}
                      id={`rt_LN_NO_${index}`}
                      name={`rt_LN_NO_${index}`}
                      value={index+1}
                    />
                  </td>
                  <td>
                <div style={{display:"flex"}}>
                  <InputText style={{width:"10rem"}}
                    id={`rt_WK_CT_${index}`}
                    name={`rt_WK_CT_${index}`}               
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}   
                    value={row.rt_WK_CT}
                  />
                  <i className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={() => openWorkcentrePopup(index)}
                    ></i>
                    </div>
                  </td>
                  <td>
                  <InputText style={{width:"15rem"}}
                    id={`rtWK_dESC_${index}`}
                    name={`rtWK_dESC_${index}`}
                    value={row.rtWK_dESC ? row.rtWK_dESC : data[`${"rtWK_dESC"}_${index}`]}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                  <div style={{display:'flex'}}>
                    <InputText style={{width:"10rem"}}
                      id={`rt_OP_SQ_${index}`}
                      name={`rt_OP_SQ_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.rt_OP_SQ}
                    />
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={() => openOperationPopup(index)}
                      ></i>
                    </div>
                  </td>
                  <td>
                  <InputText style={{width:"15rem"}}
                    id={`rt_OP_DS_${index}`}
                    name={`rt_OP_DS_${index}`}
                    value={row.rt_OP_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_IN_ST_${index}`}
                    name={`rt_IN_ST_${index}`}
                    value={row.rt_IN_ST}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_LB_HR_${index}`}
                    name={`rt_LB_HR_${index}`}
                    value={row.rt_LB_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_MC_HR_${index}`}
                    name={`rt_MC_HR_${index}`}
                    value={row.rt_MC_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_ST_HR_${index}`}
                    name={`rt_ST_HR_${index}`}
                    value={row.rt_ST_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`rt_TB_CD_${index}`}
                    name={`rt_TB_CD_${index}`}
                    value={data[`rt_TB_CD_${index}`] ? data[`rt_TB_CD_${index}`] : rt_TB_CD}
                    options={rt_TB_CDOptions}
                    onChange={(e) => onchangeDropDownFormData4(`rt_TB_CD_${index}`, e.value)}
                    placeholder="Select Time Basis"
                  />
                  </td>
                  <td>
                  {/* <Calendar style={{width:"7rem"}}
                    id={`rt_FR_DT_${index}`}
                    name={`rt_FR_DT_${index}`}
                    value={row.rt_FR_DT? moment(row.rt_FR_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "rt_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  /> */}
                  <Calendar style={{width:"7rem"}}
                    id={`rt_FR_DT_${index}`}
                    name={`rt_FR_DT_${index}`}
                    value={rt_FR_DT}
                    onChange={(e) => {setrt_FR_DT(e.value); onDateChange("rt_FR_DT", index, e.value);  }}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <Calendar style={{width:"7rem"}}
                    id={`rt_TO_DT_${index}`}
                    name={`rt_TO_DT_${index}`}
                    value={rt_TO_DT}
                    onChange={(e) => {setrt_TO_DT(e.value); onDateChange("rt_TO_DT", index, e.value);  }}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  </tr>
                  ))}
              </tbody>
            </table>  
          </div>
        </div>
      </div>
      <ItemPopup
          visible={RoutingItemPopupVisible}
          onClose={() => setRoutingItemPopupVisible(false)}
          onDataSelect={handleDataSelectItem}
          formData={data}
        />
      <WorkCentrePopup
          visible={WorkCentrePopupVisible}
          onClose={() => setWorkCentrePopupVisible(false)}
          onDataSelect={handleDataSelectWKC}
          formData={data}
        />
        <OperationSequencePopup
          visible={OperSequPopupVisible}
          onClose={() => setOperSequPopupVisible(false)}
          onDataSelect={handleDataSelectOpSq}
          formData={data}
        />
  </Dialog>
  );  
}
