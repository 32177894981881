import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
export default function ClassificationCodeDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  cl_CO_NO,
}) {
  const {
    clUkNo,
    cl_SO_CD,
    cl_CL_CD,
    cl_CL_DS,
    cl_TY_PE,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const isUpdateMode = Boolean(clUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{clUkNo ? "Revise Classification Code" : "Add Classification Code"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
            <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cl_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText  disabled
                    id="cl_CO_NO"
                    name="cl_CO_NO"
                    value={cl_CO_NO}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cl_SO_CD" className="font-bold">
                    Source Code
                  </label>
                  <InputText
                    id="cl_SO_CD"
                    name="cl_SO_CD"
                    value={cl_SO_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cl_TY_PE" className="font-bold">
                    Type
                  </label>
                  <InputText 
                    id="cl_TY_PE"
                    name="cl_TY_PE"
                    value={cl_TY_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
            </div>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cl_CL_CD" className="font-bold">
                  Class Code
                </label>
                <InputText 
                  id="cl_CL_CD"
                  name="cl_CL_CD"
                  value={cl_CL_CD}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cl_CL_DS" className="font-bold">
                Description
                </label>
                <InputText
                  id="cl_CL_DS"
                  name="cl_CL_DS"
                  value={cl_CL_DS}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
         </div>
      </div> 
    </Dialog>
  );
}
