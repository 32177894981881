import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function InventoryReclassificationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  InventoryReclassificationTableData,
  setInventoryReclassificationTableData,
  ic_TR_UM,
  setic_TR_UM,
  ic_CO_CT,
  setic_CO_CT,
  ic_LT_NO,
  setic_LT_NO,
  ic_LO_CN,
  setic_LO_CN,
  ic_FR_TO,
  setic_FR_TO,
  ic_DO_CO,
  ic_DO_TY,
}) {
  const {
    icUkNo,
    ic_DO_NO,
    ic_TR_DT,
    ic_GL_DT,
    ic_RF_CE,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [ic_CO_CTOptions, setic_CO_CTOptions] = useState([]);
  const [ic_TR_UMOptions, setic_TR_UMOptions] = useState([]);
  const [ic_LO_CNOptions, setic_LO_CNOptions] = useState([]);
  const [ic_LT_NOOptions, setic_LT_NOOptions] = useState([]);
  const [ic_FR_TOOptions, setic_FR_TOOptions] = useState([]);

  // Expense Type
  const FromCostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_CO_CT(value);
};

   // Expense Category
 const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_TR_UM(value);
};
// CostCentre
const LotNoDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setic_LT_NO(value);
};
  // Location
const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_LO_CN(value);
};
  // From To
  const FromToDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_FR_TO(value);
};

  useEffect(() => {
    FromCostCentre();
    UM();
    Location();
    LotNo();
    FromTo();

  }, []);

  const FromCostCentre = async () => {
    const Options = await fetchCostCenter();
    setic_CO_CTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setic_TR_UMOptions(Options);
  };
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setic_LO_CNOptions(Options);
  };
  const LotNo = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setic_LT_NOOptions(Options);
  };
  const FromTo = async () => {
    const Options = await fetchClassCodeCD("EX/LN");
    setic_FR_TOOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [ic_IT_NM, setic_IT_NM] = useState("");
  const [itemDES, setitemDES] = useState("");
  const handleItem = async (Result) => {
    setic_IT_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("ic_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`ic_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setInventoryReclassificationTableData([...InventoryReclassificationTableData, {}]);
  };
  const deleteRow = () => {
    if (InventoryReclassificationTableData.length > 0) {
      const updatedData = [...InventoryReclassificationTableData];
      updatedData.pop();
      setInventoryReclassificationTableData(updatedData);
    }
  };  
  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "ic_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "ic_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(icUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{icUkNo ? "Revise Inventory Reclassification" : "Add Inventory Reclassification"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div  style={inboxstyle} >
            <div className="formgrid grid">
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_DO_NO" className="font-bold">
            Transaction No
            </label>
            <InputText disabled
              id="ic_DO_NO"
              name="ic_DO_NO"
              defaultValue={ic_DO_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_DO_TY" className="font-bold">
            Type 
            </label>
            <InputText disabled
              id="ic_DO_TY"
              name="ic_DO_TY"
              value={ic_DO_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_DO_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="ic_DO_CO"
              name="ic_DO_CO"
              value={ic_DO_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_LO_CN" className="font-bold">
            Cost Centre
            </label>
            <Dropdown
            name="ic_LO_CN"
            value={ic_LO_CN}
            options={ic_LO_CNOptions}
            onChange={(e) => FromCostCentreDropdown("ic_LO_CN", e.value)}
            placeholder="Select Location"
            />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="ic_CO_CT" className="font-bold">
            Location
            </label>
            <Dropdown
            name="ic_CO_CT"
            value={ic_CO_CT}
            options={ic_CO_CTOptions}
            onChange={(e) => LocationDropdown("ic_CO_CT", e.value)}
            placeholder="Select Cost Centre"
            />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="ic_RF_CE" className="font-bold">
              Reference
              </label>
              <InputText
                id="ic_RF_CE"
                name="ic_RF_CE"
                value={ic_RF_CE}
                onChange={(e) => onchangedata(e)}
              />
            </div>   
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="ic_TR_DT" className="font-bold">
            Transfer Date
            </label>
                <Calendar
                id="ic_TR_DT"
                name="ic_TR_DT"
                value={moment(ic_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateTRChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="ic_GL_DT" className="font-bold">
            GL Date
              </label>
              <Calendar
                id="ic_GL_DT"
                name="ic_GL_DT"
                value={moment(ic_GL_DT, "YYYYMMDD").toDate()}
                onChange={handleDateGLChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            </div>
        </div>  
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
            </div>
          <div  style={multitablestyle}>
            <table className="custom-table" id="operationtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>From To</th>
                  <th>Item <span style={{ color: 'red' }}>*</span> </th>
                  <th>Description</th>
                  <th>UM</th>
                  <th>Lot No</th>
                  <th>Stock</th>
                  <th>Quantity</th>
                  <th>Unit Cost</th>
                  <th>Amount</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {InventoryReclassificationTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`icUkNo_${index}`}
                        name={`icUkNo_${index}`}
                        value={row.icUkNo}
                      />
                    </td>
                    <td>
              <Dropdown
              id={`ic_FR_TO_${index}`}
              name={`ic_FR_TO_${index}`}
              value={ data[`ic_FR_TO_${index}`] ? data[`ic_FR_TO_${index}`]: row.ic_FR_TO}
              options={ic_FR_TOOptions}
              onChange={(e) =>FromToDropdown(`ic_FR_TO_${index}`, e.value)}
              placeholder="Select From To"
              />
              </td>
              <td >
              <div style={{ display: "flex" }}>
              <InputText
                style={{ width: "10rem" }}
                id={`ic_IT_NM_${index}`}
                name={`ic_IT_NM_${index}`}
                onChange={(e) => onchangeFormData(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.ic_IT_NM ? row.ic_IT_NM: row[`${"ic_IT_NM"}_${index}`]}
                />
                {showSearchIcon && (
                <i className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                }}
                onClick={(e) => openItemPopup(index)}
                ></i>
                )}
                </div>
              </td>
                  <td>
                  <InputText disabled
                  style={{ width: "15rem" }}
                  id={`itemDES_${index}`}
                  name={`itemDES_${index}`}
                  defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                  readOnly
                  />
                  </td>
                    <td>
                    <Dropdown disabled style={{width:"7rem"}}
                      id={`ic_TR_UM_${index}`}
                      name={`ic_TR_UM_${index}`}
                      value={ data[`ic_TR_UM_${index}`] ? data[`ic_TR_UM_${index}`]: row.ic_TR_UM}
                      options={ic_TR_UMOptions}
                      onChange={(e) =>UMDropdown(`ic_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                    </td>
                    <td>
                      <Dropdown  style={{width:"8rem"}}
                        id={`ic_LT_NO_${index}`}
                        name={`ic_LT_NO_${index}`}
                        value={ data[`ic_LT_NO_${index}`] ? data[`ic_LT_NO_${index}`]: row.ic_LT_NO}
                        options={ic_LT_NOOptions}
                        onChange={(e) =>LotNoDropdown(`ic_LT_NO_${index}`, e.value)}
                        placeholder="Select Lot No"
                      />
                      </td>
                      <td > 
                      <InputText disabled style={{width:"10rem"}}
                        id={`ic_AV_QT_${index}`}
                        name={`ic_AV_QT_${index}`}
                        defaultValue={row.ic_AV_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                      <td >
                      <InputText  style={{width:"10rem"}}
                        id={`ic_TR_QT_${index}`}
                        name={`ic_TR_QT_${index}`}
                        defaultValue={row.ic_TR_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"10rem"}}
                        id={`ic_UN_CT_${index}`}
                        name={`ic_UN_CT_${index}`}
                        defaultValue={row.ic_UN_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"10rem"}}
                        id={`ic_FR_AM_${index}`}
                        name={`ic_FR_AM_${index}`}
                        defaultValue={row.ic_FR_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                      <td >
                      <InputText style={{width:"10rem"}}
                        id={`ic_RM_RK_${index}`}
                        name={`ic_RM_RK_${index}`}
                        defaultValue={row.ic_RM_RK}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
            
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
