import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WorkOrderEntryDialog from "./WorkOrderEntryDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  wdUkNo:"",
  wd_WO_NO:"",
  wd_IT_NM:"",
  wd_OR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  wd_WO_QT:"",
  wd_RQ_DT:"",
  wd_ST_DT:"",
  wd_WT_QT:"",
  wd_SR_QT:"",
  wd_CN_QT:"",
  wd_AT_PL:"",
  wd_AT_RT:"",
  wd_CU_NO:"",
  wd_PL_NO:"",
  wd_CO_QT:"",
  wd_IN_ST:"",
  wd_SU_NO:"",
  IT_DES1:"",
  wd_RL_NO:"",
  wd_WO_CO:"",
  wd_WO_TY:"",
  wd_CO_CT:"",
  wd_WO_ST:"",
  wd_PR_TY:"",
  wd_CL_01:"",
  wd_CL_02:"",
  wd_CL_03:"",
  wd_CL_04:"",
  wd_CL_05:"",
  wd_TR_UM:"",
};

const initialFilters = {
  wd_WO_NO: { value: "", operation: "" },
  wd_WO_TY: { value: "", operation: "" },
  wd_WO_CO: { value: "", operation: "" },
  wd_CO_CT: { value: "", operation: "" },
  wd_IT_NM: { value: "", operation: "" },
  descrption: { value: "", operation: "" },
  wd_WO_QT: { value: "", operation: "" },
  wd_TR_UM: { value: "", operation: "" },
  wd_OR_DT: { value: "", operation: "" },
  wd_RQ_DT: { value: "", operation: "" }, 
  wd_ST_DT: { value: "", operation: "" },
  wd_WO_ST: { value: "", operation: "" },
  wd_PR_TY: { value: "", operation: "" }, 
};

const WorkOrderEntryMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,ListDesc,
    inputStyle,tableCellStyle} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [wd_CO_CT, setwd_CO_CT] = useState([]);
  const [wd_WO_ST, setwd_WO_ST] = useState([]);
  const [wd_PR_TY, setwd_PR_TY] = useState([]);
  const [wd_CL_01, setwd_CL_01] = useState([]);
  const [wd_CL_02, setwd_CL_02] = useState([]);
  const [wd_CL_03, setwd_CL_03] = useState([]);
  const [wd_CL_04, setwd_CL_04] = useState([]);
  const [wd_CL_05, setwd_CL_05] = useState([]);
  const [itemdesc, setitemdesc] = useState("");
  const [wd_AT_PL, setwd_AT_PL] = useState([]);
  const [wd_AT_RT, setwd_AT_RT] = useState([]);
  const [wd_WO_CO, setwd_WO_CO] = useState("");
  const [wd_WO_TY, setwd_WO_TY] = useState("");
  const [wd_WO_NO, setwd_WO_NO] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setwd_CO_CT(null);
    setwd_PR_TY(null);
    setwd_CL_01(null);
    setwd_CL_02(null);
    setwd_CL_03(null);
    setwd_CL_04(null);
    setwd_CL_05(null);
    setwd_AT_PL(null);
    setitemdesc(null);
    setwd_AT_RT(null);
    setwd_WO_NO(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/WorkOrderEntry/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const WorkOrderEntryData = response.data;
          delete WorkOrderEntryData.wdUkNo;
          delete WorkOrderEntryData.wd_WO_NO;
          setFormData(WorkOrderEntryData);
          setwd_WO_TY(WorkOrderEntryData.wd_WO_TY);
          setwd_CO_CT(WorkOrderEntryData.wd_CO_CT);
          setwd_WO_ST(WorkOrderEntryData.wd_WO_ST.toString());
          setwd_PR_TY(WorkOrderEntryData.wd_PR_TY);
          setwd_CL_01(WorkOrderEntryData.wd_CL_01);
          setwd_CL_02(WorkOrderEntryData.wd_CL_02);
          setwd_CL_03(WorkOrderEntryData.wd_CL_03);
          setwd_CL_04(WorkOrderEntryData.wd_CL_04);
          setwd_CL_05(WorkOrderEntryData.wd_CL_05);
          setitemdesc(WorkOrderEntryData.it_DES);
          setwd_AT_PL(WorkOrderEntryData.wd_AT_PL);
          setwd_AT_RT(WorkOrderEntryData.wd_AT_RT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const onchangeDropDownFormData2 = (name, value) => {
    // setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setFormData({ ...FormData, [name]: value });
    setwd_WO_ST(value);
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => { 
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setwd_WO_CO(`${company}`);
            if(fName === "WD_WO_TY"){
              setwd_WO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "WD_WO_ST"){
              onchangeDropDownFormData2("wd_WO_ST",dataDefault.versionIds[i].default_VALUE);
              setwd_WO_ST(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WorkOrderEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData,[name]: value,}));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WorkOrderEntry/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkOrderEntryData = response.data;
        setFormData(WorkOrderEntryData);
        setwd_WO_TY(WorkOrderEntryData.wd_WO_TY);
        setwd_WO_NO(WorkOrderEntryData.wd_WO_NO);
        setwd_CO_CT(WorkOrderEntryData.wd_CO_CT);
        setwd_WO_ST(WorkOrderEntryData.wd_WO_ST.toString());
        setwd_PR_TY(WorkOrderEntryData.wd_PR_TY);
        setwd_CL_01(WorkOrderEntryData.wd_CL_01);
        setwd_CL_02(WorkOrderEntryData.wd_CL_02);
        setwd_CL_03(WorkOrderEntryData.wd_CL_03);
        setwd_CL_04(WorkOrderEntryData.wd_CL_04);
        setwd_CL_05(WorkOrderEntryData.wd_CL_05);
        setitemdesc(WorkOrderEntryData.it_DES);
        setwd_AT_PL(WorkOrderEntryData.wd_AT_PL);
        setwd_AT_RT(WorkOrderEntryData.wd_AT_RT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      wdUkNo : FormData.wdUkNo,
      wd_WO_NO:  document.querySelector("#wd_WO_NO").value,
      wd_IT_NM:  FormData.wd_IT_NM,
      wd_OR_DT:  FormData.wd_OR_DT,
      wd_WO_QT:  FormData.wd_WO_QT,
      wd_RQ_DT:  FormData.wd_RQ_DT,
      wd_ST_DT:  FormData.wd_ST_DT,
      wd_WT_QT:  FormData.wd_WT_QT,
      wd_SR_QT:  FormData.wd_SR_QT,
      wd_CN_QT:  FormData.wd_CN_QT,
      wd_AT_PL:  FormData.wd_AT_PL,
      wd_AT_RT:  FormData.wd_AT_RT,
      wd_CU_NO:  FormData.wd_CU_NO,
      wd_PL_NO:  FormData.wd_PL_NO,
      wd_CO_QT:  FormData.wd_CO_QT,
      wd_IN_ST:  FormData.wd_IN_ST,
      wd_SU_NO:  FormData.wd_SU_NO,
      IT_DES1 :  FormData.IT_DES1,
      // wd_RL_NO:  FormData.wd_RL_NO,
      wd_TR_UM:  document.getElementById("wd_TR_UM").value,
      wd_WO_TY:  document.querySelector("#wd_WO_TY").value,
      wd_WO_CO:  document.querySelector("#wd_WO_CO").value,
      wd_CO_CT:  FormData.wd_CO_CT,
      wd_WO_ST:  FormData.wd_WO_ST,
      wd_PR_TY:  FormData.wd_PR_TY,
      wd_CL_01:  FormData.wd_CL_01,
      wd_CL_02:  FormData.wd_CL_02,
      wd_CL_03:  FormData.wd_CL_03,
      wd_CL_04:  FormData.wd_CL_04,
      wd_CL_05:  FormData.wd_CL_05,
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.wd_WO_NO) {
    if (document.getElementById("wdUkNo").value) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/WorkOrderEntry/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/WorkOrderEntry/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstHalf = Math.floor(currentYear / 100);
        const secondHalf = String(currentYear % 100).padStart(2, "0");
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${wd_WO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setwd_WO_NO(resp.dn_DO_NO);
            document.getElementById("wd_WO_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/WorkOrderEntry/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse",width: "100%", marginTop: "20px"}}>
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_WO_NO"] ? filters["wd_WO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_WO_NO",
                        e.target.value,
                        filters["wd_WO_NO"]
                          ? filters["wd_WO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_WO_TY"] ? filters["wd_WO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_WO_TY",
                        e.target.value,
                        filters["wd_WO_TY"] ? filters["wd_WO_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_WO_CO"] ? filters["wd_WO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_WO_CO",
                        e.target.value,
                        filters["wd_WO_CO"]
                          ? filters["wd_WO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_CO_CT"] ? filters["wd_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_CO_CT",
                        e.target.value,
                        filters["wd_CO_CT"]
                          ? filters["wd_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_IT_NM"] ? filters["wd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_IT_NM",
                        e.target.value,
                        filters["wd_IT_NM"]
                          ? filters["wd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["it_DES"] ? filters["it_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "it_DES",
                        e.target.value,
                        filters["it_DES"]
                          ? filters["it_DES"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_WO_QT"] ? filters["wd_WO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_WO_QT",
                        e.target.value,
                        filters["wd_WO_QT"]
                          ? filters["wd_WO_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_TR_UM"] ? filters["wd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_TR_UM",
                        e.target.value,
                        filters["wd_TR_UM"]
                          ? filters["wd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_OR_DT"] ? filters["wd_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_OR_DT",
                        e.target.value,
                        filters["wd_OR_DT"]
                          ? filters["wd_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["wd_RQ_DT"] ? filters["wd_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_RQ_DT",
                        e.target.value,
                        filters["wd_RQ_DT"]
                          ? filters["wd_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wd_ST_DT"] ? filters["wd_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_ST_DT",
                        e.target.value,
                        filters["wd_ST_DT"]
                          ? filters["wd_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["wd_WO_ST"] ? filters["wd_WO_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_WO_ST",
                        e.target.value,
                        filters["wd_WO_ST"]
                          ? filters["wd_WO_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input disabled
                    type="text"
                    value={filters["wd_PR_TY"] ? filters["wd_PR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wd_PR_TY",
                        e.target.value,
                        filters["wd_PR_TY"]
                          ? filters["wd_PR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>           
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No </th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Order Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Requested Date</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Priority</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wdUkNo}
                        checked={selectedRows.includes(rowData.wdUkNo)}
                        onChange={() => handleRowSelect(rowData.wdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.wd_WO_NO}</td>
                    <td style={tableCellStyle}>{rowData.wd_WO_TY}</td>
                    <td style={tableCellStyle}>{rowData.wd_WO_CO}</td>
                    <td style={tableCellStyle}>{rowData.wd_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.wd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.it_DES}</td>
                    <td style={tableCellStyle}>{rowData.wd_WO_QT}</td>
                    <td style={tableCellStyle}>{rowData.wd_TR_UM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.wd_OR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.wd_RQ_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.wd_ST_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.wd_WO_ST}</td>
                    <td style={tableCellStyle}>{rowData.priority_DESC}</td>                 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
 <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <WorkOrderEntryDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        wd_WO_TY={wd_WO_TY}
        wd_WO_CO={wd_WO_CO}
        wd_CO_CT={wd_CO_CT}
        wd_WO_NO={wd_WO_NO}
        setwd_CO_CT={setwd_CO_CT}
        wd_WO_ST={wd_WO_ST}
        setwd_WO_ST={setwd_WO_ST}
        wd_PR_TY={wd_PR_TY}
        setwd_PR_TY={setwd_PR_TY}
        wd_CL_01={wd_CL_01}
        setwd_CL_01={setwd_CL_01}
        wd_CL_02={wd_CL_02}
        setwd_CL_02={setwd_CL_02}
        wd_CL_03={wd_CL_03}
        setwd_CL_03={setwd_CL_03}
        wd_CL_04={wd_CL_04}
        setwd_CL_04={setwd_CL_04}
        wd_CL_05={wd_CL_05}
        setwd_CL_05={setwd_CL_05}
        itemdesc={itemdesc}
        setitemdesc={setitemdesc}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        wd_AT_PL={wd_AT_PL}
        setwd_AT_PL={setwd_AT_PL}
        wd_AT_RT={wd_AT_RT}
        setwd_AT_RT={setwd_AT_RT}
      />
    </div>
  );
};

export default WorkOrderEntryMain;
