import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CustomerLedgerDialog from "./CustomerLedgerDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  clUkNo:"",
  cl_DO_NO:"",
  cl_DO_TY:"",
  cl_DO_CO:"",
  cl_DO_PI:"",
  cl_CU_NO:"",
  name:"",
  cl_GL_DT: moment(moment().toDate()).format("YYYYMMDD"),
  cl_IN_DT: moment(moment().toDate()).format("YYYYMMDD"),
  cl_DD_DT:"",
  cl_PY_CO:"",
  cl_GL_PO:"",
  cl_PA_ST:"",
  cl_GR_AM:"",
  cl_OP_AM:"",
  cl_TA_AM:"",
  cl_TX_AM:"",
  cl_PD_AM:"",
  cl_ST_DT:"",
  cl_CR_CD:"",
  cl_CR_RT:"",
  cl_OP_FR:"",
  cl_TA_FR:"",
  cl_TX_FR:"",
  cl_OR_NO:"",
  cl_OR_TY:"",
  cl_OR_CO:"",
  cl_SU_IN:"",
  cl_CO_NM:"",
  cl_BA_TY:"",
  cl_BA_NO:"",
  cl_BA_DT:"",
  cl_ST_CD:"",
  cl_DO_TY:"",

  cl_TX_CD:"",
  cl_DI_AM:"",
  cl_CR_AM:"",
  cl_DI_FR:"",
  cl_DO_PI:"",
  cl_TX_AM:"",
  cl_DI_AM:"",
  cl_CR_AM:"",
  cl_TA_FR:"",
  cl_TX_FR:"",
  cl_DI_FR:"",
  cl_OR_NO:"",
  
  cl_OR_CO:"",
};

const initialFilters = {
  cl_DO_NO: { value: "", operation: "" },
  cl_DO_TY: { value: "", operation: "" },
  cl_DO_CO: { value: "", operation: "" },
  cl_DO_PI: { value: "", operation: "" },
  cl_CU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  cl_GL_DT: { value: "", operation: "" },
  cl_IN_DT: { value: "", operation: "" },
  cl_DD_DT: { value: "", operation: "" },
  cl_PY_CO: { value: "", operation: "" },
  cl_GL_PO: { value: "", operation: "" },
  cl_PA_ST: { value: "", operation: "" },
  cl_GR_AM: { value: "", operation: "" },
  cl_OP_AM: { value: "", operation: "" },
  cl_TA_AM: { value: "", operation: "" },
  cl_TX_AM: { value: "", operation: "" },
  cl_PD_AM: { value: "", operation: "" },
  cl_ST_DT: { value: "", operation: "" },
  cl_CR_CD: { value: "", operation: "" },
  cl_CR_RT: { value: "", operation: "" },
  cl_CR_AM: { value: "", operation: "" },
  cl_OP_FR: { value: "", operation: "" },
  cl_TA_FR: { value: "", operation: "" },
  cl_TX_FR: { value: "", operation: "" },
  cl_OR_NO: { value: "", operation: "" },
  cl_OR_TY: { value: "", operation: "" },
  cl_OR_CO: { value: "", operation: "" },
  cl_SU_IN: { value: "", operation: "" },
  cl_CO_NM: { value: "", operation: "" },
  cl_BA_NO: { value: "", operation: "" },
  cl_BA_TY: { value: "", operation: "" },
  cl_BA_DT: { value: "", operation: "" },
  cl_ST_CD: { value: "", operation: "" },
};

const CustomerLedgerMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,rightalined,ListDesc,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1 } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [cl_DO_NO, setcl_DO_NO] = useState("");
  const [cl_ST_CD, setcl_ST_CD] = useState("");

  const [transCurr, settransCurr] = useState("");
  const [PaymentTerm, setPaymentTerm] = useState("");
  const [isGLSaved, setIsGLSaved] = useState(true);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  const [cl_CU_NO, setcl_CU_NO] = useState("");
  const [cl_PY_CO, setcl_PY_CO] = useState("");
  const [cl_CO_NM, setcl_CO_NM] = useState("");
  const [cl_IN_DT, setcl_IN_DT] = useState("");
  const [cl_OR_TY, setcl_OR_TY] = useState("");
  const [cl_CR_CD, setcl_CR_CD] = useState("");
  const [cl_GL_DT, setcl_GL_DT] = useState("");
  const [cl_DD_DT, setcl_DD_DT] = useState("");
  const [cl_TX_CD, setcl_TX_CD] = useState("");
  const [CustomerLedgertable, setCustomerLedgertable] = useState([{}]);
 
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [taxcode, settaxcode] = useState("");
  // const buildSearchRequestDto = () => {
  //   return Object.keys(filters)
  //     .filter((column) => filters[column].value)
  //     .map((column) => ({
  //       column: column.toUpperCase(),
  //       value: filters[column].value,
  //       operation: filters[column].operation,
  //     }));
  // };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setCustomerLedgertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO("");
    settaxcode(null)
    setSelectedemEMNO(null);
    setcl_IN_DT(null);
    setcl_OR_TY(null);
    setcl_CR_CD(null);
    setcl_PY_CO(null);
    setcl_TX_CD(null);
	setcl_ST_CD(10);
    setIsGLSaved(true);
    setOpen(true);
  };
  const handleClose = () => {
    setCustomerLedgertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setcl_IN_DT(null);
    setcl_OR_TY(null);
    setcl_CR_CD(null);
    setcl_PY_CO(null);
    setcl_TX_CD(null);
	setcl_ST_CD(null);
    setPaymentTerm(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
      setCustomerLedgertable([...CustomerLedgertable, {"cl_TX_CD":FormData.cl_TX_CD}]);
  };
  const deleteRow = () => {
    if (CustomerLedgertable.length > 0) {
      const updatedData = [...CustomerLedgertable];
      updatedData.pop();
      setCustomerLedgertable(updatedData);
    }
  };
  const handleCopyData = async () => {
    setIsGLSaved(true);
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CustomerLedger/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CustomerLedgerData = response.data;
          delete CustomerLedgerData.clUkNo;
          delete CustomerLedgerData.cl_DO_NO;
          for (let i = 0; i < CustomerLedgerData.customerLedgerList.length; i++) {
            delete CustomerLedgerData.customerLedgerList[i].clUkNo;
            delete CustomerLedgerData.customerLedgerList[i].cl_DO_NO;
          }
          setCustomerLedgertable(CustomerLedgerData.customerLedgerList);
          setFormData(CustomerLedgerData.customerLedgerList[0]);
          setcl_CO_NM(CustomerLedgerData.cl_CO_NM);
          setcl_OR_TY(CustomerLedgerData.cl_OR_TY);
          setcl_CR_CD(CustomerLedgerData.cl_CR_CD);
          setcl_PY_CO(CustomerLedgerData.cl_PY_CO);
          setcl_CU_NO(CustomerLedgerData.cl_CU_NO);
          setcl_TX_CD(CustomerLedgerData.cl_TX_CD);
		  setcl_ST_CD(10);
          setSelectedemEMNO(CustomerLedgerData.customerLedgerList[0].name);
          setcl_CU_NO(CustomerLedgerData.cl_CU_NO);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const url = `${domain}/api/Contact/AllFields`;
  const [cl_DO_CO , setcl_DO_CO] = useState("");
  const [cl_DO_TY , setcl_DO_TY] = useState("");
  const [cl_CR_CO, setcl_CR_CO] = useState("");
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setcl_DO_CO(`${company}`)
            if(fName === "CL_DO_TY"){
              setcl_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CL_CR_CO"){
              setcl_CR_CO(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CL_CO_NM"){
              onchangeDropDownFormData2("cl_CO_NM",dataDefault.versionIds[i].default_VALUE);
              setcl_CO_NM(dataDefault.versionIds[i].default_VALUE);
            }

          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleDefault();
    
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CustomerLedger/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function onChnageExchangeRate(frCr, invoiceDate) {
    const exData = {
      "cr_TO_CR": document.getElementById("cl_CR_CO").value,
      "cr_FR_CR": frCr,
      "cr_EF_DT": invoiceDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("cl_CR_RT").value = dataExRt.cr_CO_MR;
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    if(name=="cl_IN_DT")
      onChnageExchangeRate(transCurr, value);
  };
  // const onchangeDropDownFormData1 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setrc_CO_TY(value);
  // };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcl_CO_NM(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcl_OR_TY(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setcl_CR_CD(value);
    onChnageExchangeRate(value, FormData.cl_IN_DT);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcl_PY_CO(value);
    setPaymentTerm(value);
    const due={
      "pt_PY_CO": value,
      "inp_DATE": FormData.cl_IN_DT
    }

    axios.post(`${domain}/api/PaymentTerm/onChangeOfPaymentTerm`, due, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dueDate = resp.data.inp1;
          if (dueDate) {
            onchangeFormData("cl_DD_DT", dueDate.out_DATE);
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const onchangeDropDownFormData6 = (name, value, index=null) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setcl_TX_CD(value);
    const fieldName=(FormData && FormData.clUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      CustomerLedgertable[index][fieldName]=value;
    else{
      for(var i=0;i<CustomerLedgertable.length;i++){
        CustomerLedgertable[i][name]=value;
        setFormData((prevData) => ({
          ...prevData,
          [`${name}_${i}`]: value,
        }));
      }
    }
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    setIsGLSaved(true);
    try {
      const response = await axios.get(
        `${domain}/api/CustomerLedger/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CustomerLedgerData = response.data;
        setCustomerLedgertable(CustomerLedgerData.customerLedgerList);
        setFormData(CustomerLedgerData.customerLedgerList[0]);
        setcl_CO_NM(response.data.cl_CO_NM);
        setcl_OR_TY(response.data.cl_OR_TY);
        setcl_CR_CD(response.data.cl_CR_CD);
        setcl_PY_CO(response.data.cl_PY_CO);
        setcl_TX_CD(response.data.cl_TX_CD);
		setcl_ST_CD(response.data.cl_ST_CD);
        setSelectedemEMNO(CustomerLedgerData.customerLedgerList[0].name);
        setcl_CU_NO(CustomerLedgerData.cl_CU_NO);

          const gaGlDisplay = {
            "gl_DO_TY": CustomerLedgerData.customerLedgerList[0].cl_DO_TY,
            "gl_DO_NO": CustomerLedgerData.customerLedgerList[0].cl_DO_NO
          };
          axios.post(`${domain}/api/JournalEntry/GetGAGLToDisplay`, gaGlDisplay, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  const len = resp.data.header.length;
                  if(len > 0){
                    setIsGLSaved(false);
                  } else {
                    setIsGLSaved(true);
                  }
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
      
        
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values (){
    const UK_NO = FormData.clUkNo;
          const DO_NO = document.getElementById("cl_DO_NO");
          const DO_TY = document.getElementById("cl_DO_TY");
          const DO_CO = document.getElementById("cl_DO_CO");
          const IN_DT = FormData.cl_IN_DT;
          const GL_DT = FormData.cl_GL_DT;
          const DD_DT = FormData.cl_DD_DT;
          const CR_CD = FormData.cl_CR_CD;
          const CR_RT = document.getElementById("cl_CR_RT");
          const CR_CO = document.getElementById("cl_CR_CO");
          const SU_IN = document.getElementById("cl_SU_IN");
          const CO_NM = document.getElementById("cl_CO_NM");
            

              const table1 = document.getElementById("CustomerLedgertable");
              const totalRowCount1 = table1.tBodies[0].rows.length;
            
              const customerLedgerList = [];
              for (let i = 0; i < totalRowCount1; i++) {
                const UK_NO = document.getElementById("clUkNo_" + i);
                const DO_PI = document.getElementById("cl_DO_PI_" + i);
                const GR_AM = document.getElementById("cl_GR_AM_" + i);
                const TA_AM = document.getElementById("cl_TA_AM_" + i);
                const TX_AM = document.getElementById("cl_TX_AM_" + i);
                const TX_CD = table1.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value;
                const DI_AM = document.getElementById("cl_DI_AM_" + i);
                const CR_AM = document.getElementById("cl_CR_AM_" + i);
                const TA_FR = document.getElementById("cl_TA_FR_" + i);
                const TX_FR = document.getElementById("cl_TX_FR_" + i);
                const DI_FR = document.getElementById("cl_DI_FR_" + i);
                const OR_NO = document.getElementById("cl_OR_NO_" + i);
                const OR_TY = table1.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value;
                const OR_CO = document.getElementById("cl_OR_CO_" + i);
                const Details = {
                  clUkNo: UK_NO.value,
                  cl_DO_PI:DO_PI.value,
                  cl_GR_AM: GR_AM.value,
                  cl_TA_AM: TA_AM.value,
                  cl_TX_AM: TX_AM.value,
                  cl_TX_CD: TX_CD,
                  cl_DI_AM: DI_AM.value,
                  cl_CR_AM: CR_AM.value,
                  cl_TA_FR: TA_FR.value,
                  cl_TX_FR: TX_FR.value,
                  cl_DI_FR: DI_FR.value,
                  cl_OR_NO: OR_NO.value,
                  cl_OR_TY: OR_TY,
                  cl_OR_CO: OR_CO.value,
                  cl_CO_NM:cl_CO_NM
                };

              customerLedgerList.push(Details);
            }
            requestBodyInput = {
              customerLedgerList,
              clUkNo: UK_NO,
              cl_DO_NO: DO_NO.value,
              cl_DO_TY: DO_TY.value,
              cl_DO_CO: DO_CO.value,
              cl_CU_NO: document.getElementById("cl_CU_NO").value,
              cl_PY_CO: cl_PY_CO,
              cl_IN_DT: IN_DT,
              cl_GL_DT: GL_DT,
              cl_DD_DT: DD_DT,
              cl_CR_CD: CR_CD,
              cl_CR_RT: document.getElementById("cl_CR_RT").value,
              cl_CR_CO: CR_CO.value,
              cl_SU_IN: SU_IN.value,
              cl_CO_NM: cl_CO_NM,
            
            };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.cl_DO_NO) {
    if (FormData.clUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/CustomerLedger/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/CustomerLedger/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const IN_DT = FormData.cl_IN_DT;
        if(IN_DT){
          const currentYear = IN_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${cl_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setcl_DO_NO(resp.dn_DO_NO);
              document.getElementById("cl_DO_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/CustomerLedger/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_NO"] ? filters["cl_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_NO",
                        e.target.value,
                        filters["cl_DO_NO"]
                          ? filters["cl_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_TY"] ? filters["cl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_TY",
                        e.target.value,
                        filters["cl_DO_TY"]
                          ? filters["cl_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_CO"] ? filters["cl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_CO",
                        e.target.value,
                        filters["cl_DO_CO"]
                          ? filters["cl_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_PI"] ? filters["cl_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_PI",
                        e.target.value,
                        filters["cl_DO_PI"]
                          ? filters["cl_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["cl_CU_NO"] ? filters["cl_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CU_NO",
                        e.target.value,
                        filters["cl_CU_NO"]
                          ? filters["cl_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GL_DT"] ? filters["cl_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GL_DT",
                        e.target.value,
                        filters["cl_GL_DT"]
                          ? filters["cl_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_IN_DT"] ? filters["cl_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_IN_DT",
                        e.target.value,
                        filters["cl_IN_DT"]
                          ? filters["cl_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DD_DT"] ? filters["cl_DD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DD_DT",
                        e.target.value,
                        filters["cl_DD_DT"]
                          ? filters["cl_DD_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_PY_CO"] ? filters["cl_PY_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_PY_CO",
                        e.target.value,
                        filters["cl_PY_CO"]
                          ? filters["cl_PY_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GL_PO"] ? filters["cl_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GL_PO",
                        e.target.value,
                        filters["cl_GL_PO"]
                          ? filters["cl_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_PA_ST"] ? filters["cl_PA_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_PA_ST",
                        e.target.value,
                        filters["cl_PA_ST"]
                          ? filters["cl_PA_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GR_AM"] ? filters["cl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GR_AM",
                        e.target.value,
                        filters["cl_GR_AM"]
                          ? filters["cl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OP_AM"] ? filters["cl_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OP_AM",
                        e.target.value,
                        filters["cl_OP_AM"]
                          ? filters["cl_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_TA_AM"] ? filters["cl_TA_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_TA_AM",
                        e.target.value,
                        filters["cl_TA_AM"]
                          ? filters["cl_TA_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_TX_AM"] ? filters["cl_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_TX_AM",
                        e.target.value,
                        filters["cl_TX_AM"]
                          ? filters["cl_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_PD_AM"] ? filters["cl_PD_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_PD_AM",
                        e.target.value,
                        filters["cl_PD_AM"]
                          ? filters["cl_PD_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_ST_DT"] ? filters["cl_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_ST_DT",
                        e.target.value,
                        filters["cl_ST_DT"]
                          ? filters["cl_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_CD"] ? filters["cl_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_CD",
                        e.target.value,
                        filters["cl_CR_CD"]
                          ? filters["cl_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_RT"] ? filters["cl_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_RT",
                        e.target.value,
                        filters["cl_CR_RT"]
                          ? filters["cl_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_AM"] ? filters["cl_CR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_AM",
                        e.target.value,
                        filters["cl_CR_AM"]
                          ? filters["cl_CR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OP_FR"] ? filters["cl_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OP_FR",
                        e.target.value,
                        filters["cl_OP_FR"]
                          ? filters["cl_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_TA_FR"] ? filters["cl_TA_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_TA_FR",
                        e.target.value,
                        filters["cl_TA_FR"]
                          ? filters["cl_TA_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_TX_FR"] ? filters["cl_TX_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_TX_FR",
                        e.target.value,
                        filters["cl_TX_FR"]
                          ? filters["cl_TX_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OR_NO"] ? filters["cl_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OR_NO",
                        e.target.value,
                        filters["cl_OR_NO"]
                          ? filters["cl_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OR_TY"] ? filters["cl_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OR_TY",
                        e.target.value,
                        filters["cl_OR_TY"]
                          ? filters["cl_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["cl_OR_CO"] ? filters["cl_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OR_CO",
                        e.target.value,
                        filters["cl_OR_CO"]
                          ? filters["cl_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_SU_IN"] ? filters["cl_SU_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_SU_IN",
                        e.target.value,
                        filters["cl_SU_IN"]
                          ? filters["cl_SU_IN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CO_NM"] ? filters["cl_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CO_NM",
                        e.target.value,
                        filters["cl_CO_NM"]
                          ? filters["cl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_BA_NO"] ? filters["cl_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_BA_NO",
                        e.target.value,
                        filters["cl_BA_NO"]
                          ? filters["cl_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_BA_TY"] ? filters["cl_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_BA_TY",
                        e.target.value,
                        filters["cl_BA_TY"]
                          ? filters["cl_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_BA_DT"] ? filters["cl_BA_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_BA_DT",
                        e.target.value,
                        filters["cl_BA_DT"]
                          ? filters["cl_BA_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_ST_CD"] ? filters["cl_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_ST_CD",
                        e.target.value,
                        filters["cl_ST_CD"]
                          ? filters["cl_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Doc. No</th>
                <th style={tableHeaderStyle}>Doc. Type</th>
                <th style={tableHeaderStyle}>Doc. Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
                <th style={tableHeaderStyle}>Due Date</th>
                <th style={tableHeaderStyle}>Pay Term</th>
                <th style={tableHeaderStyle}>Post Code</th>
                <th style={tableHeaderStyle}>Pay Status</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Open Amount</th>
                <th style={tableHeaderStyle}>Taxable Amount</th>
                <th style={tableHeaderStyle}>Tax Amount</th>
                <th style={tableHeaderStyle}>PDC Amount</th>
                <th style={tableHeaderStyle}>Maturity Date</th>
                <th style={tableHeaderStyle}>Currency </th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Foreign Gross Amount</th>
                <th style={tableHeaderStyle}>Foreign Open Amount</th>
                <th style={tableHeaderStyle}>Foreign Taxable Amount</th>
                <th style={tableHeaderStyle}>Foreign Amount</th>
                <th style={tableHeaderStyle}>Rel. No</th>
                <th style={tableHeaderStyle}>Rel. Ty</th>
                <th style={tableHeaderStyle}>Rel. Co</th>
                <th style={tableHeaderStyle}>Customer PO</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Batch Number</th>
                <th style={tableHeaderStyle}>Batch Type</th>
                <th style={tableHeaderStyle}>Batch Date</th>
                <th style={tableHeaderStyle}>Status</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.clUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.clUkNo}
                        checked={selectedRows.includes(rowData.clUkNo)}
                        onChange={() => handleRowSelect(rowData.clUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cl_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.cl_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.cl_DO_PI}</td>
                    <td style={tableCellStyle}>{rowData.cl_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cl_GL_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.cl_IN_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.cl_DD_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.cl_PY_CO}</td>
                    <td style={tableCellStyle}>{rowData.cl_GL_PO}</td>
                    <td style={tableCellStyle}>{rowData.cl_PA_ST}</td>
                    <td style={rightalined}>{rowData.cl_GR_AM}</td>
                    <td style={rightalined}>{rowData.cl_OP_AM}</td>
                    <td style={rightalined}>{rowData.cl_TA_AM}</td>
                    <td style={rightalined}>{rowData.cl_TX_AM}</td>
                    <td style={rightalined}>{rowData.cl_PD_AM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cl_ST_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.cl_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.cl_CR_RT}</td>
                    <td style={rightalined}>{rowData.cl_CR_AM}</td>
                    <td style={rightalined}>{rowData.cl_OP_FR}</td>
                    <td style={rightalined}>{rowData.cl_TA_FR}</td>
                    <td style={rightalined}>{rowData.cl_TX_FR}</td>
                    <td style={tableCellStyle}>{rowData.cl_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.cl_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.cl_SU_IN}</td>
                    <td style={tableCellStyle}>{rowData.cl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.cl_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_BA_TY}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cl_BA_DT)}</td>
                    <td style={tableCellStyle}>{rowData.cl_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
     {/* pagination */}
     <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

      
  
      <CustomerLedgerDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}       
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        handleDefault={handleDefault}
        cl_CO_NM={cl_CO_NM}
        cl_OR_TY={cl_OR_TY}
        cl_CR_CD={cl_CR_CD}
        cl_PY_CO={cl_PY_CO}
        cl_DO_TY={cl_DO_TY}
        cl_DO_CO={cl_DO_CO}
        cl_CU_NO={cl_CU_NO}
        setcl_CU_NO={setcl_CU_NO}
        cl_GL_DT={cl_GL_DT}
        cl_DD_DT={cl_DD_DT}
        cl_TX_CD={cl_TX_CD}
        cl_CR_CO={cl_CR_CO}
        setcl_PY_CO={setcl_PY_CO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        CustomerLedgertable={CustomerLedgertable} 
        setCustomerLedgertable={setCustomerLedgertable}
        addRow={addRow} 
        deleteRow={deleteRow} 
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        setcl_CO_NM={setcl_CO_NM}
        isGLSaved={isGLSaved}
		cl_ST_CD={cl_ST_CD}
      />
    </div>
  );
};

export default CustomerLedgerMain;

