import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";

export default function FinancialForeCastDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  onchangedata,
  setFormData,
  onchangeFormData,
  fc_CT_RY,
  setfc_CT_RY,
  fc_FI_YR,
  setfc_FI_YR,
  fc_ST_CD,
  setfc_ST_CD,
  fc_DO_CO,
  FinancialForeCasttable,
  setFinancialForeCasttable,

  from1,
  setfrom1,
  account1,
  setaccount1,
}) {
  const {
    fcUkNo,
    accountRange,
    forecastYear,
    growth,
    fc_CO_CT,
    fc_OB_SB,
    fc_AC_ID,
    fc_AC_DS,
    fc_LV_DT,
    fc_PE_CD,
    fc_BU_AM,
    fc_PE_01,
    fc_PE_02,
    fc_PE_03,
    fc_PE_04,
    fc_PE_05,
    fc_PE_06,
    fc_PE_07,
    fc_PE_08,
    fc_PE_09,
    fc_PE_10,
    fc_PE_11,
    fc_PE_12,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, savestyle, cancelstyle, Dialogstyle } = ButtonStyles();



  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();

  const [fc_CT_RYOptions, setfc_CT_RYOptions] = useState([]);
  const [fc_FI_YROptions, setfc_FI_YROptions] = useState([]);
  const [fc_ST_CDOptions, setfc_ST_CDOptions] = useState([]);

  const addRow = () => {
    setFinancialForeCasttable([...FinancialForeCasttable, {}]);
  };
  const deleteRow = () => {
    if (FinancialForeCasttable.length > 0) {
      const updatedData = [...FinancialForeCasttable];
      updatedData.pop();
      setFinancialForeCasttable(updatedData);
    }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setfc_CT_RY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setfc_FI_YR(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setfc_ST_CD(value);
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "CM/TY";
  const finalUrl1 = `${url}/${parameter1}`;
  // comp level
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
  // provider
  const parameter3 = "FA/ST";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);

  const fetchDropdownOptions1 = async () => {
    const Options = await fetchClassCodeDS("CM/TY");
    setfc_CT_RYOptions(Options);
  };
  const fetchDropdownOptions2 = async () => {
    const Options = await fetchClassCodeDS("YE/AR");
    setfc_FI_YROptions(Options);
  };
  const fetchDropdownOptions3 = async () => {
    const Options = await fetchClassCodeDS("FA/ST");
    setfc_ST_CDOptions(Options);
  };
 
  const [Account1, setAccount1] = useState([]);
  const [am_OB_AC, setfrom] = useState([]);

  const onchangedata1 = (e) => {
    const { name, value } = e.target;
    if (name === "account1") {
      setAccount1(value);
    }
    handleOnChangeRate();
  };

  
  const handleOnChangeRate = async () => {
    const Url = `${domain}/api/AccountMaster/GetByAccountRange`;
    const requestData = {
      "am_OB_AC": document.getElementById("from1").value,
      "account": document.getElementById("account1").value,
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;

      if (dataLead && dataLead.length > 0) {
        setFinancialForeCasttable(dataLead.map(item => ({
          //fcUkNo: item.amUkNo,
          fc_CO_CT: item.am_CO_NM,
          fc_OB_AC: item.am_OB_AC,
          fc_OB_SB: item.am_OB_SB,
          fc_AC_ID: item.am_AC_ID,
          fc_AC_DS: item.am_AC_DS,
          fc_LV_DT: item.am_AC_LD,
          fc_PE_CD: item.am_PE_CD,
        })));
        handlePeriod();
      } else {
        console.error("Invalid API response:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePeriod = async () => {
    const Url = `${domain}/api/FinancialForeCast/GetFIFCGetAmount`;
    const requestData = {
      "fcUkNo": fcUkNo,
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;

      if (dataLead && dataLead.length > 0) {
        setFinancialForeCasttable(dataLead.map(item => ({
          fcUkNo: item.fcUkNo,
          fb_PE_01: item.fb_PE_01,
          // fb_OB_AC: item.am_OB_AC,
          // fb_OB_SB: item.am_OB_SB,
          // fb_AC_ID: item.am_AC_ID,
          // fb_AC_DS: item.am_AC_DS,
          // fb_LV_DT: item.am_AC_LD,
          // fb_PE_CD: item.am_PE_CD,
        })));

      } else {
        console.error("Invalid API response:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const isUpdateMode = Boolean(fcUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
      <h5>{fcUkNo ? "Revise Financial Forecast" : "Add Financial Forecast"}</h5>
      <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%' }}>
        {isUpdateMode && (
          <div style={{ display: 'flex', width: "10%" }}>
            <Button
              style={{ width: "50%", marginRight: "8px", backgroundColor: "transparent", color: "blue", border: 'none' }}
              icon="pi pi-check-circle"
              onClick={handleConfirm}
              tooltip={"Confirm"}>
            </Button>
          </div>
        )}
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fc_DO_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="fc_DO_CO"
                name="fc_DO_CO"
                value={fc_DO_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fc_CT_RY" className="font-bold">
                Century
              </label>
              <Dropdown
                id="fc_CT_RY"
                name="fc_CT_RY"
                value={fc_CT_RY}
                options={fc_CT_RYOptions}
                onChange={(e) => onchangeDropDownFormData1("fc_CT_RY", e.value)}
                placeholder="Select Century"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fc_FI_YR" className="font-bold">
                Fiscal Year
              </label>
              <Dropdown
                id="fc_FI_YR"
                name="fc_FI_YR"
                value={fc_FI_YR}
                options={fc_FI_YROptions}
                onChange={(e) => onchangeDropDownFormData2("fc_FI_YR", e.value)}
                placeholder="Select financial Year"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="forecastYear" className="font-bold">
                Forecast Year
              </label>
              <InputText
                id="forecastYear"
                name="forecastYear"
                value={forecastYear}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor=" accountRange" className="font-bold">
                Account Range
              </label>

            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="from1" className="font-bold">
                From
              </label>
              <InputText
                id="from1"
                name="from1"
                value={from1}
                onChange={(e) => onchangedata(e)}
              />
            </div>
             <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="account1" className="font-bold">
                Thru
                </label>
                <InputText 
                  id="account1"
                  name="account1"
                  value={account1}
                  onChange={(e) => { 
                    setAccount1(e.target.value); 
                    onchangedata1(e);  
                  }}
                />
              </div> 

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="growth" className="font-bold">
                Growth %
              </label>
              <InputText
                id="growth"
                name="growth"
                value={growth}
                onChange={(e) => onchangedata(e)}
                
              />
            </div>
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="FinancialForeCasttable" style={{ overflow: "auto" }}>
              <thead>
                <tr>
                  <th style={{ display: "none" }} >UK_NO</th>
                  <th>Cost Center</th>
                  <th>Account</th>
                  <th>Subsidiary</th>
                  <th>Account ID</th>
                  <th>Account Description</th>
                  <th>Level </th>
                  <th>Post Code</th>
                  <th>Budget Amount</th>
                  <th>Status</th>
                  <th>Period01</th>
                  <th>Period02</th>
                  <th>Period03</th>
                  <th>Period04</th>
                  <th>Period05</th>
                  <th>Period06</th>
                  <th>Period07</th>
                  <th>Period08</th>
                  <th>Period09</th>
                  <th>Period10</th>
                  <th>Period11</th>
                  <th>Period12</th>
                </tr>
              </thead>
              <tbody>
                {FinancialForeCasttable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }} >
                      <InputText
                        id={`fcUkNo_${index}`}
                        name={`fcUkNo_${index}`}
                        defaultValue={row.fcUkNo}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_CO_CT_${index}`}
                        name={`fc_CO_CT_${index}`}
                        defaultValue={row.fc_CO_CT}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_OB_AC_${index}`}
                        name={`fc_OB_AC_${index}`}
                        defaultValue={row.fc_OB_AC}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_OB_SB_${index}`}
                        name={`fc_OB_SB_${index}`}
                        defaultValue={row.fc_OB_SB}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_AC_ID_${index}`}
                        name={`fc_AC_ID_${index}`}
                        defaultValue={row.fc_AC_ID}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_AC_DS_${index}`}
                        name={`fc_AC_DS_${index}`}
                        defaultValue={row.fc_AC_DS}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_LV_DT_${index}`}
                        name={`fc_LV_DT_${index}`}
                        defaultValue={row.fc_LV_DT}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_CD_${index}`}
                        name={`fc_PE_CD_${index}`}
                        defaultValue={row.fc_PE_CD}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText style={{ width: "90px" }}
                        id={`fc_BU_AM_${index}`}
                        name={`fc_BU_AM_${index}`}
                        defaultValue={row.fc_BU_AM}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`fc_ST_CD_${index}`}
                        name={`fc_ST_CD_${index}`}
                        value={data[`fc_ST_CD_${index}`] ? data[`fc_ST_CD_${index}`] : row.fc_ST_CD}
                        options={fc_ST_CDOptions}
                        onChange={(e) => handleFormDataChange(`fc_ST_CD_${index}`, e.value)}
                        placeholder="Select Status"
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_01_${index}`}
                        name={`fc_PE_01_${index}`}
                        defaultValue={row.fc_PE_01}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_02_${index}`}
                        name={`fc_PE_02_${index}`}
                        defaultValue={row.fc_PE_02}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_03_${index}`}
                        name={`fc_PE_03_${index}`}
                        defaultValue={row.fc_PE_03}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_04_${index}`}
                        name={`fc_PE_04_${index}`}
                        defaultValue={row.fc_PE_04}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_05_${index}`}
                        name={`fc_PE_05_${index}`}
                        defaultValue={row.fc_PE_05}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_06_${index}`}
                        name={`fc_PE_06_${index}`}
                        defaultValue={row.fc_PE_06}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_07_${index}`}
                        name={`fc_PE_07_${index}`}
                        defaultValue={row.fc_PE_07}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_08_${index}`}
                        name={`fc_PE_08_${index}`}
                        defaultValue={row.fc_PE_08}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_09_${index}`}
                        name={`fc_PE_09_${index}`}
                        defaultValue={row.fc_PE_09}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_10_${index}`}
                        name={`fc_PE_10_${index}`}
                        defaultValue={row.fc_PE_10}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_11_${index}`}
                        name={`fc_PE_11_${index}`}
                        defaultValue={row.fc_PE_11}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "90px" }}
                        id={`fc_PE_12_${index}`}
                        name={`fc_PE_12_${index}`}
                        defaultValue={row.fc_PE_12}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
