import MainDashboard from "views/admin/default";
import SignInCentered from "views/auth/signIn";
import axios from "axios";
// CRM
import CrmContact from "views/admin/CrmContact";
import SalesLead from "views/admin/SalesLead";
import AddressBook from "views/admin/AddressBook";
import SalesOppurtunities from "views/admin/SalesOppurtunities";
import ActivityPlan from "views/admin/Activity Plan";
import VisitPlan from "views/admin/VisitPlan";
import VisitDetails from "views/admin/VisitDetails";
import SalesMethodology from "views/admin/SalesMethodology";
import LeadQualification from "views/admin/LeadQualification";
import SalesTarget from "views/admin/SalesTarget";
import ManageCase from "views/admin/ManageCase";
import ServiceContract from "views/admin/ServiceContract";
import TeritoryGrouping from "views/admin/TeritoryGrouping";
import Campaign from "views/admin/Campaign";
import IndustryGrouping from "views/admin/IndustryGrouping";
import TaskManagement from "views/admin/TaskManagement";
// HCM
import EmployeeMaster from "views/admin/EmployeeMaster";
import CompensationDetails from "views/admin/CompensationDetails";
import LeaveStatus from "views/admin/LeaveStatus";
import OverTime from "views/admin/OverTime";
import TimeEntry from "views/admin/TimeEntry";
import UpdateAttendence from "views/admin/UpdateAttendence";
import UpdateOverTime from "views/admin/UpdateOverTime";
import SalaryDetails from "views/admin/SalaryDetails";
import LeaveSettlement from "views/admin/LeaveSettlement";
import FinalSettlement from "views/admin/FinalSettelement";
import EmployeeBenefits from "views/admin/EmployeeBenefits";
import CourseDetails from "views/admin/CourseDetails";
import CompetencyDetails from "views/admin/CompetencyDetails";
import ResourceCompetencyDetails from "views/admin/ResourceCompetencyDetails";
import ApplicationInformation from "views/admin/ApplicantInformation";
import WorkRequisition from "views/admin/WorkRequisition";
import LeaveDetails from "views/admin/LeaveDetails";
import SalaryProcessing from "views/admin/SalaryProcessing";
import CompensationHistory from "views/admin/CompensationHistory";
import TimeCardInterface from "views/admin/TimeCardInterface";
import EmployeeRateMaster from "views/admin/EmployeeRateMaster";




// Real Estate
import PropertyInformation from "views/admin/PropertyInformation";
import BuildingInformation from "views/admin/BuildingInformation";
import FloorInformation from "views/admin/FloorInformation";
import UnitAttributes from "views/admin/UnitAttributes";
import SecurityDeposit from "views/admin/SecurityDeposit";
import ManualBilling from "views/admin/ManualBilling";
import LeaseInformation from "views/admin/LeaseInformation";
import UnitInformation from "views/admin/UnitInformation";
import LeaseInvoiceDetails from "views/admin/LeaseInvoiceDetails";
import MarketRent from "views/admin/MarketRent";
import DepositRefund from "views/admin/DepositRefund";
import TenantWorkOrderCost from "views/admin/TenantWorkOrderCost";
import TenantWorkOrder from "views/admin/TenantWorkOrder";
import MultiUnitInformation from "views/admin/MultiUnitInformation";
import PropertyInspection from "views/admin/PropertyInspection";
import PropertyListing from "views/admin/PropertyListing";


// Finance
import JournalEntry from "views/admin/JournalEntry";
import AnnualClosure from "views/admin/AnnualClosure";
import AssetClosure from "views/admin/AssetClosure";
import BankRecoAuto from "views/admin/BankRecoAuto";
import BankReconcilation from "views/admin/BankReconcilation";
import BankRecoStatus from "views/admin/BankRecoStatus";
import Allocation from "views/admin/Allocation";
import AssetLedger from "views/admin/AssetLedger";
import CompanyMaster from "views/admin/CompanyMaster";
import UpdateAR from "views/admin/UpdateAR";
import FiscalDatePattern from "views/admin/FiscalDatePattern";
import AccountMaster from "views/admin/AccountMaster";
import DocumentNumber from "views/admin/DocumentNumber";
import BatchNumber from "views/admin/BatchNumber";
import CostCentre from "views/admin/CostCentre";
import ClassificationCode from "views/admin/ClassificationCode";
import CustomerLedger from "views/admin/CustomerLedger";
import ReceiptDetails from "views/admin/ReceiptDetails";
import PDCReceipts from "views/admin/PDCReceipts";
import CustomerAdvance from "views/admin/CustomerAdvance";
import ARAPNetting from "views/admin/ARAPNetting";
import CustomerMaster from "views/admin/CustomerMaster";
import PaymentTerm from "views/admin/PaymentTerm";
import SupplierLedger from "views/admin/SupplierLedger";
import PaymentGeneration from "views/admin/PaymentGeneration";
import PaymentProcessing from "views/admin/PaymentProcessing";
import PaymentDetails from "views/admin/PaymentDetails";
import SupplierAdvance from "views/admin/SupplierAdvance";
import AssetMaster from "views/admin/AssetMaster";
import AssetCost from "views/admin/AssetCost";
import AssetDisposal from "views/admin/AssetDisposal";
import AssetCash from "views/admin/AssetCash";
import FinancialBudget from "views/admin/FinancialBudget";
import BudgetComparision from "views/admin/BudgetComparision";
import FinancialForeCast from "views/admin/FinancialForeCast";
import ForecastComparision from "views/admin/ForecastComparision";
import CashFlow from "views/admin/CashFlow";
import GroupProfile from "views/admin/GroupProfile";
import ExpenseReport from "views/admin/ExpenseReport";
import ExpensePolicy from "views/admin/ExpensePolicy";
import EmployeeProfile from "views/admin/EmployeeProfile";
import ErrorBatches from "views/admin/ErrorBatches";
import PostInvoice from "views/admin/PostInvoice";
import PostPayments from "views/admin/PostPayments";
import PostReceipts from "views/admin/PostReceipts";
import PostVouchers from "views/admin/PostVouchers";
import SupplierMaster from "views/admin/SupplierMaster";
import AutomaticPostingInstructions from "views/admin/AutomaticPostingInstructions";
import DistributionPostingInstruction from "views/admin/DistributionPostingInstruction";
import NotificationSetup from "views/admin/NotificationSetup";
import ExchangeRate from "views/admin/ExchangeRate";
import FlexibleAccounting from "views/admin/FlexibleAccounting";
import ReceiptReversal from "views/admin/ReceiptReversal";
import PaymentReversal from "views/admin/PaymentReversal";
import AccountLedger from "views/admin/AccountLedger";
import AccountBalance from "views/admin/AccountBalance";
import VoucherWithTds from "views/admin/VoucherWithTds";




// sales
import CustomerEnquiry from "views/admin/CustomerEnquiry";
import SalesQuote from "views/admin/SalesQuote"; //QUOTE ORDER
import ReviseQuote from "views/admin/ReviseQuote";
import SalesOrder from "views/admin/SalesOrder";
import PickSlipGeneration from "views/admin/PickSlipGeneration";
import ShipConfirmation from "views/admin/ShipConfirmation";
import ShipmentReturn from "views/admin/ShipmentReturn";
import DeliveryConfirmation from "views/admin/DeliveryConfirmation";
import SalesInvoice from "views/admin/SalesInvoice";
import SalesReturn from "views/admin/SalesReturn";
import SalesProjection from "views/admin/SalesProjection";
import InterCompanySalesOrder from "views/admin/InterCompanySalesOrder";
import ApprovalSetup from "views/admin/ApprovalSetup";


// procurement
import PurchaseRequisition from "views/admin/PurchaseRequisition";
import PurchaseQuote from "views/admin/PurchaseQuote";
import QuoteComparision from "views/admin/QuoteComparision";
import PurchaseOrder from "views/admin/PurchaseOrder";
import PurchaseReceipts from "views/admin/PurchaseReceipts";
import VoucherMatch from  "views/admin/VoucherMatch";
import PurchaseReturn from "views/admin/PurchaseReturn";
import PurchaseProjection from "views/admin/PurchaseProjection";
// APPROVALS
import Approval from "views/admin/Approval";
import CreditHoldApproval from "views/admin/CreditHoldApproval";
// inventory
import ItemMaster from "views/admin/ItemMaster";
import InventoryTransfer from "views/admin/InventoryTransfer";
import MaterialTransfer from "views/admin/MaterialTransfer";   
import InventoryIssues from "views/admin/InventoryIssues";
import InventoryAdjustment from "views/admin/InventoryAdjustment";
import InventoryReclassification from "views/admin/InventoryReclassification";
import CycleCountProcess from "views/admin/CycleCountProcess"; 
import CycleCountGeneration from "views/admin/CycleCountGeneration";
import MaterialRequest from "views/admin/MaterialRequest"; 
import AlternativeDescription from "views/admin/AlternativeDescription";
import CrossReference from "views/admin/CrossReference";
import ItemUMConversion from "views/admin/ItemUMConversion";
import StandardUMConversion from "views/admin/StandardUMConversion";
import ItemCost from "views/admin/ItemCost";
import LocationMaster from "views/admin/LocationMaster";
import StockAvailability from "views/admin/StockAvailability";
import ItemLedger from "views/admin/ItemLedger";
import ItemMasterSetup from "views/admin/ItemMasterSetup"; 
import ReceiptFromTransit from "views/admin/ReceiptFromTransit"; 

// Manufacturing
import BillofMaterial from "views/admin/BillofMaterial";
import RoutingMaster from "views/admin/RoutingMaster";
import WorkCentre from "views/admin/WorkCentre";
import DisruptionMaster from "views/admin/DisruptionMaster";
import OperationSequence from "views/admin/OperationSequence";
import WorkOrderEntry from "views/admin/WorkOrderEntry";
import PartList from "views/admin/PartList";
import WorkOrderRouting from "views/admin/WorkOrderRouting";
import MaterialRequisition from "views/admin/MaterialRequisition"; // needs to check
import MaterialIssue from "views/admin/MaterialIssue";
import WorkOrderCompletion from "views/admin/WorkOrderCompletion";
import ReleaseQCHold from "views/admin/ReleaseQCHold";
import WorkOrderTimeEntry from "views/admin/WorkOrderTimeEntry";
import WorkOrderDownTime from "views/admin/WorkOrderDownTime";
import CoByProducts from "views/admin/CoByProducts";
import DefineProcess from "views/admin/DefineProcess"; // needs to check again
//Quality Control
import Specification from "views/admin/Specification";
import AttachSpecToProduct from "views/admin/AttachSpecToProduct";
import EnterTestResults from "views/admin/EnterTestResults";
import TestMaster from "views/admin/TestMaster";
import AttachTestToItem from "views/admin/AttachTestToItem";


//Planning & Scheduling
import WorkDayCalendar from "views/admin/WorkDayCalendar";
import WorkCenterProductCapacity from "views/admin/WorkCenterProductCapacity";
import WorkCentreCapacity from "views/admin/WorkCentreCapacity";
import ProductionPlan from "views/admin/ProductionPlan";
//Product Costing
import ProductionCost from "views/admin/ProductionCost";
import PurchaseCostEnquiry from "views/admin/PurchaseCostEnquiry";
import ComponentCostSimulation from "views/admin/ComponentCostSimulation";
import ParentCostSimulation from "views/admin/ParentCostSimulation";
// maintaintainance
import EquipmentMaster from "views/admin/EquipmentMaster";
import MaintainanceTimeEntry from "views/admin/MaintainanceTimeEntry";
import FailureAnalysis from "views/admin/FailureAnalysis";
import MaintainanceWorkOrder from "views/admin/MaintainanceWorkOrder";
import SparePartsEntry from "views/admin/SparePartsEntry";
import SparePartsIssue from "views/admin/SparePartsIssue";
import PreventiveMaintenance from "views/admin/PreventiveMaintenance";

// warehose
import FixedLocation from "views/admin/FixedLocation"; 
import MovementInstruction from "views/admin/MovementInstruction";
import OrderGroups from "views/admin/OrderGroups";
import ProcessRules from "views/admin/ProcessRules";
import PickingRequest from "views/admin/PickingRequest";
import PutawayRequest from "views/admin/PutawayRequest";
import ReplenishmentRequest from "views/admin/ReplenishmentRequest";
// transportation
import CarrierMaster from "views/admin/CarrierMaster";
import VehicleMaster from "views/admin/VehicleMaster";
import ShipmentStatus from "views/admin/ShipmentStatus";
import ShippingDetails from "views/admin/ShippingDetails";
import RouteMaster from "views/admin/RouteMaster";
import LoadBuildingDetails from "views/admin/LoadBuildingDetails";
import RateDetails from "views/admin/RateDetails";
// project
import ProjectMaster from "views/admin/ProjectMaster";
import JobMaster from "views/admin/JobMaster";
import EstimateRate from "views/admin/EstimateRate";
import ProjectBudget from "views/admin/ProjectBudget";
import ProjectAccountStructure from "views/admin/ProjectAccountStructure";
import JobBudget from "views/admin/JobBudget";
import JobAccountStructure from "views/admin/JobAccountStructure";
import ScheduleActivity from "views/admin/ScheduleActivity";
import EstimateEntry from "views/admin/EstimateEntry";
import EstimateHistory from "views/admin/EstimateHistory";
import EstimateMaterial from "views/admin/EstimateMaterial";
import EstimateLabour from "views/admin/EstimateLabour";
// contract billing
import ContractMaster from "views/admin/ContractMaster";
import ContractBillingDetails from "views/admin/ContractBillingDetails";
import FixedBilling from "views/admin/FixedBilling";
import ProgressBilling from "views/admin/ProgressBilling";
import MileStoneBilling from "views/admin/MileStoneBilling";
import LumpSumBilling from "views/admin/LumpSumBilling";
import BillingInvoiceGeneration from "views/admin/BillingInvoiceGeneration";
import TimeMaterialBilling from "views/admin/TimeMaterialBilling";
// service billing
import ServiceOrder from "views/admin/ServiceOrder";
import ServiceInvoiceGeneration from "views/admin/ServiceInvoiceGeneration";
// service management
import ProductRegistration from "views/admin/ProductRegistration";
import EquipmentParentChild from "views/admin/EquipmentParentChild";
import ContractCoverage from "views/admin/ContractCoverage";
import ServiceType from "views/admin/ServiceType";
import ServicePackage from "views/admin/ServicePackage";
import InspectionMaster from "views/admin/InspectionMaster";
import WarrantyClaim from "views/admin/WarrantyClaim";
import ServiceBooking from "views/admin/ServiceBooking";
import InspectionDetails from "views/admin/InspectionDetails";
// Tax
import VatReceivables from "views/admin/VatReceivables"; 
import VATPayables from "views/admin/VATPayables"; 
import GSTReceivables from "views/admin/GSTReceivables"; 
import GSTPayables from "views/admin/GSTPayables"; 
import TaxDeductedSource from "views/admin/TaxDeductedSource"; 
import VatSetOff from "views/admin/VatSetOff"; 

// Retail
import LoginCredentials from "views/admin/LoginCredentials";
import PayMethod from "views/admin/PayMethod";
import BankCharges from "views/admin/BankCharges";
import SalesHierarchy from "views/admin/SalesHierarchy";
import SalesTables from "views/admin/SalesTables";
import TableReservation from "views/admin/TableReservation";
import CashInOut from "views/admin/CashInOut";
import CashDeskClosing from "views/admin/CashDeskClosing";
import KioskDirectSales from "views/admin/KioskDirectSales";
import DirectSales from "views/admin/DirectSales";
import HospitalityDirectSales from "views/admin/HospitalityDirectSales";
// interface
import ImportLead from "views/admin/ImportLead";
import PurchaseOrderInterface from "views/admin/PurchaseOrderInterface";
import GPSInterface from "views/admin/GPSInterface";
// healthcare
import AllergyMaster from "views/admin/AllergyMaster";
import SymptomMaster from "views/admin/SymptomMaster";
import DiagnosisMaster from "views/admin/DiagnosisMaster";
import LabTestMaster from "views/admin/LabTestMaster";
import ProcedureMaster from "views/admin/ProcedureMaster";
import DoctorMaster from "views/admin/DoctorMaster";
import MedicineMaster from "views/admin/MedicineMaster";
import PatientQuestionnaire from "views/admin/PatientQuestionnaire";
import OperationDetails from "views/admin/OperationDetails";
import OperationMaster from "views/admin/OperationMaster";
import BedMaster from "views/admin/BedMaster";
import AppointmentBooking from "views/admin/AppointmentBooking";
import PatientDetails from "views/admin/PatientDetails";
import BedAllocationQueue from "views/admin/BedAllocationQueue";
import LabTestResult from "views/admin/LabTestResult";
import BedDetails from "views/admin/BedDetails";
import IpdQueue from "views/admin/IpdQueue";
import HospitalPaymentDetails from "views/admin/HospitalPaymentDetails";


// Pricing
import BasePriceItem from "views/admin/BasePriceItem";
import BasePriceItemCustomer from "views/admin/BasePriceItemCustomer";
import BasePriceItemCustomerGroup from "views/admin/BasePriceItemCustomerGroup";
import BasePriceItemGroupCustomerGroup from "views/admin/BasePriceItemGroupCustomerGroup";
import PreferenceHierarchy from "views/admin/PreferenceHierarchy";
import PriceAdjustmentSchedule from "views/admin/PriceAdjustmentSchedule";
import PriceHistory from "views/admin/PriceHistory";
import FreeGoodsCatalog from "views/admin/FreeGoodsCatalog";
import FreeGoodsRevision from "views/admin/FreeGoodsRevision";
import DetailPriceBreakup from "views/admin/DetailPriceBreakup";
import PriceAdjustmentDefinition from "views/admin/PriceAdjustmentDefinition";
import PriceAdjustmentDetail from "views/admin/PriceAdjustmentDetail";
// rental
import RentalItem from "views/admin/RentalItem";
import RentalSecurityDeposit from "views/admin/RentalSecurityDeposit";
import RentalContract from "views/admin/RentalContract";
import RentalInvoice from "views/admin/RentalInvoice";

//Jewellery
import BaggingProcess from "views/admin/BaggingProcess";
import BaggingTransfer from "views/admin/BaggingTransfer";
import WaxSettingProcess from "views/admin/WaxSettingProcess";
import JewelleryTransfer from "views/admin/JewelleryTransfer";//pending 
import FindingTransfer from "views/admin/FindingTransfer";
import JewelleryAcknowledgement from "views/admin/JewelleryAcknowledgement";
import JewelleryRejection from "views/admin/JewelleryRejection";


// FOUNDATION
import UserDetails from "views/admin/UserDetails";
import RoleCreation from "views/admin/RoleCreation";
import MenusCreation from "views/admin/MenusCreation";



// customer portal
import CustomerPortal from "views/admin/CustomerPortal";
// reports
import Reports from "views/admin/Reports";
// dashboards
import PropertyOverview from "views/admin/Dashboards/DashboardRealEstate";
import AutomotiveOverview from "views/admin/Dashboards/AutomotiveDashBoard";
import RentalDashBoard from "views/admin/Dashboards/RentalDashBoard/RentalDashBoard";
import ConstructionOverview from "views/admin/Dashboards/ConstructionDashBoard/ConstructionOverview";
import CRMOverview from "views/admin/Dashboards/CRMDashboard";
import HCMOverview from "views/admin/Dashboards/HCMDashBoard/HCMOverview";




const token = localStorage.getItem("token");
const domain = localStorage.getItem("domain");
const company = localStorage.getItem("company");
const mapComponents = {
  "default":MainDashboard,
  "contact" : CrmContact,
  "customer": AddressBook,
  "saleslead" : SalesLead,
  "salesopportunities" : SalesOppurtunities,
  "activityplan" : ActivityPlan,
  "visitplan" : VisitPlan,
  "visitdetails" : VisitDetails,
  "salesquote" : SalesQuote,
  "salesorder" : SalesOrder,
  "salestarget" : SalesTarget,
  "salesmethodology" : SalesMethodology,
  "leadqualification" : LeadQualification,
  "servicecontract" : ServiceContract,
  "managecase" : ManageCase,
  "revisequote":ReviseQuote,
  "employeemaster" :EmployeeMaster,
  "compensationdetails" : CompensationDetails,
  "leavestatus" :LeaveStatus,
  "timeentry" : TimeEntry,
  "overtime" : OverTime,
  "updateattendence" : UpdateAttendence,
  "updateovertime" : UpdateOverTime,
  "salarydetails" : SalaryDetails,
  "leavesettelement" : LeaveSettlement,
  "finalsettelement" : FinalSettlement,
  "employeebenefits" : EmployeeBenefits,
  "coursedetails" : CourseDetails,
  "competencydetails" : CompetencyDetails,
  "resourcecompentency" : ResourceCompetencyDetails,
  "applicationinformation" : ApplicationInformation,
  "workrequisition" : WorkRequisition,
  "unitinformation" : UnitInformation,
  "unitattributes" : UnitAttributes,
  "buildinginformation" : BuildingInformation,
  "floorinformation" : FloorInformation,
  "leaseinformation" : LeaseInformation,
  "manualbilling" : ManualBilling,
  "leaseinvoicedetails" : LeaseInvoiceDetails,
  "securitydeposit" : SecurityDeposit,
  "marketrent" : MarketRent,
  "depositrefund" :DepositRefund,
  "tenantworkordercost" :TenantWorkOrderCost,
  "tenantworkorder" : TenantWorkOrder,
  "multiunitinformation" : MultiUnitInformation,
  "customerledger" : CustomerLedger,
  "receiptdetails": ReceiptDetails,
  "journalentry" : JournalEntry,
  "receiptdetails": ReceiptDetails,
  "propertyinformation": PropertyInformation,
  "customeradvance": CustomerAdvance,
  "customermaster":CustomerMaster,
  "costcentre":CostCentre,
  "suppliermaster":SupplierMaster,
  "paymentterm":PaymentTerm,
  "importlead":ImportLead,
  "batchnumber":BatchNumber,
  "documentnumber":DocumentNumber,
  "classificationcode":ClassificationCode,
  "companymaster":CompanyMaster,
  "fiscaldatepattern":FiscalDatePattern,
 "leavedetails":LeaveDetails,
  "accountmaster":AccountMaster,
  "report":Reports,
  "salaryprocessing":SalaryProcessing,
  "userdetails":UserDetails,
  "purchaserequisition":PurchaseRequisition,
  "purchasequote":PurchaseQuote,
  "purchaseorder":PurchaseOrder,
  "purchasereceipts":PurchaseReceipts,
  "timecardinterface":TimeCardInterface,
  "vouchermatch":VoucherMatch,
  "supplierledger":SupplierLedger,
  "paymentdetails":PaymentDetails,
  "salesinvoice":SalesInvoice,
  "pickslipgeneration":PickSlipGeneration,
  "supplieradvance":SupplierAdvance,
  "pdcreceipts":PDCReceipts,
  "itemmaster":ItemMaster,
  "shipmentreturn":ShipmentReturn,
  "bankreconcilation":BankReconcilation,
  "bankrecostatus":BankRecoStatus,
  "bankrecoauto":BankRecoAuto,
  "assetclosure":AssetClosure,
  "billofmaterial":BillofMaterial,
  "allocation":Allocation,
  "assetmaster":AssetMaster,
  "assetledger":AssetLedger,
  "assetcost":AssetCost,
  "assetcash":AssetCash,
  "assetdisposal":AssetDisposal,
  "annualclosure":AnnualClosure,
  "financialbudget":FinancialBudget,
  "teritorygrouping":TeritoryGrouping,
  "industrygrouping":IndustryGrouping,
  "budgetcomparision":BudgetComparision,
  "approval":Approval,
  "creditholdapproval":CreditHoldApproval,
  "routingmaster":RoutingMaster,
  "workcentre":WorkCentre,
  "campaign":Campaign,
  "propertyinspection":PropertyInspection,
  "errorbatches":ErrorBatches,
  "postinvoice":PostInvoice,
  "postpayments":PostPayments,
  "postreceipts":PostReceipts,
  "postvouchers":PostVouchers,
  "updatear":UpdateAR,
  "arapnetting":ARAPNetting,
  "workorderentry":WorkOrderEntry,
  "operationsequence":OperationSequence,
  "disruptionmaster":DisruptionMaster,
  "groupprofile":GroupProfile,
  "employeeprofile":EmployeeProfile,
  "expensepolicy":ExpensePolicy,
  "expensereport":ExpenseReport,
  "cashflow":CashFlow,
  "workordercompletion":WorkOrderCompletion,
  "cobyproducts":CoByProducts,
  "paymentgeneration":PaymentGeneration,
  "paymentprocessing":PaymentProcessing,
  "financialforecast":FinancialForeCast,
  "alternativedescription":AlternativeDescription,
  "forecastcomparision":ForecastComparision,
  "crossreference":CrossReference,
  "itemumconversion":ItemUMConversion,
  "standardumconversion":StandardUMConversion,
  "itemcost":ItemCost,
  "locationmaster":LocationMaster,
  "inventorytransfer":InventoryTransfer,
  "inventoryissues":InventoryIssues,
  "inventoryadjustment":InventoryAdjustment,
  "stockavailability":StockAvailability,
  "materialtransfer":MaterialTransfer,
  "materialrequest":MaterialRequest,
  "itemledger":ItemLedger,
  "inventoryreclassification":InventoryReclassification,
  "itemmastersetup":ItemMasterSetup,
  "fixedlocation":FixedLocation,
  "partlist":PartList,
  "workorderrouting":WorkOrderRouting,
  "materialissue":MaterialIssue,
  "defineprocess":DefineProcess,
  "projectmaster":ProjectMaster,
  "estimaterate":EstimateRate,
  "jobmaster":JobMaster,
  "contractmaster":ContractMaster,
  "contractbillingdetails":ContractBillingDetails,
  "billinginvoicegeneration":BillingInvoiceGeneration,
  "timematerialbilling":TimeMaterialBilling,
  "projectbudget":ProjectBudget,
  "jobbudget":JobBudget,
  "projectaccountstructure":ProjectAccountStructure,
  "jobaccountstructure":JobAccountStructure,
  "scheduleactivity":ScheduleActivity,
  "estimatelabour":EstimateLabour,
  "estimateentry":EstimateEntry,
  "estimatehistory":EstimateHistory,
  "estimatematerial":EstimateMaterial,
  "rentalitem":RentalItem,
  "rentalsecuritydeposit":RentalSecurityDeposit,
  "productregistration":ProductRegistration,
  "rentalcontract":RentalContract,
  "equipmentparentchild":EquipmentParentChild,
  "contractcoverage":ContractCoverage,
  "rentalinvoice":RentalInvoice,
  "servicetype":ServiceType,
  "servicepackage":ServicePackage,
  "lumpsumbilling":LumpSumBilling,
  "progressbilling":ProgressBilling,
  "fixedbilling":FixedBilling,
  "milestonebilling":MileStoneBilling,
  "inspectionmaster":InspectionMaster,
  "warrantyclaim":WarrantyClaim,
  "serviceorder":ServiceOrder,
  "serviceinvoicegeneration":ServiceInvoiceGeneration,
  "gpsinterface":GPSInterface,
  "maintainancetimeentry":MaintainanceTimeEntry,
  "failureanalysis":FailureAnalysis,
  "womaintainance":MaintainanceWorkOrder,
  "sparepartsentry":SparePartsEntry,
  "sparepartsissue":SparePartsIssue,
  "equipmentmaster":EquipmentMaster,
  "servicebooking":ServiceBooking,
  "inspectiondetails":InspectionDetails,
  "appointmentbooking":AppointmentBooking,
  "allergymaster":AllergyMaster,
  "symptommaster":SymptomMaster,
  "diagnosismaster":DiagnosisMaster,
  "labtestmaster":LabTestMaster,
  "proceduremaster":ProcedureMaster,
  "doctormaster":DoctorMaster,
  "medicinemaster":MedicineMaster,
  "patientquestionnaire":PatientQuestionnaire,
  "operationmaster":OperationMaster,
  "operationdetails":OperationDetails,
  "bedmaster":BedMaster,
  "patientdetails":PatientDetails,
  "logincredentials":LoginCredentials,
  "ordergroups":OrderGroups,
  "paymethod":PayMethod,
  "bankcharges":BankCharges,
  "saleshierarchy":SalesHierarchy,
  "processrules":ProcessRules,
  "salestables":SalesTables,
  "tablereservation":TableReservation,
  "pickingrequest":PickingRequest,
  "replenishmentrequest":ReplenishmentRequest,
  "cashinout":CashInOut,
  "carriermaster":CarrierMaster,
  "cashdeskclosing":CashDeskClosing,
  "quotecomparision":QuoteComparision,
  "purchaseprojection":PurchaseProjection,
  "purchasereturn":PurchaseReturn,
  "taskmanagement":TaskManagement,
  "customerportal":CustomerPortal,
  "compensationhistory":CompensationHistory,
  "kioskdirectsales":KioskDirectSales,
  "directsales":DirectSales,
  "hospitalitydirectsales":HospitalityDirectSales,
  "movementintruction":MovementInstruction,
  "purchaseorderinterface":PurchaseOrderInterface,
  "vehiclemaster":VehicleMaster,
  "routemaster":RouteMaster,
  "shipmentstatus":ShipmentStatus,
  "ratedetails":RateDetails,
  "preferencehierarchy":PreferenceHierarchy,
  "shippingdetails":ShippingDetails,
  "customerenquiry":CustomerEnquiry,
  "basepriceitem":BasePriceItem,
  "loadbuildingdetails" :LoadBuildingDetails,
  "workdaycalendar" :WorkDayCalendar,
  "basepriceitemcustomer":BasePriceItemCustomer,
  "basepriceitemgroupcustomer":BasePriceItemCustomerGroup,
  "basepriceitemgroupcustomergroup":BasePriceItemGroupCustomerGroup,
  "workcentrecapacity":WorkCentreCapacity,
  "workcenterproductcapacity":WorkCenterProductCapacity,
  "priceadjustmentschedule":PriceAdjustmentSchedule,
  "pricehistory":PriceHistory,
  "freegoodscatalog":FreeGoodsCatalog,
  "shipconfirmation": ShipConfirmation,
  "deliveryconfirmation":DeliveryConfirmation,
  "salesreturn":SalesReturn,
  "salesprojection":SalesProjection,
  "intercompanysalesorder":InterCompanySalesOrder,
  "productionplan":ProductionPlan,
  "putawayrequest":PutawayRequest,
  "specification":Specification,
  "attachspectoproduct":AttachSpecToProduct,
  "releaseqchold":ReleaseQCHold,
  "materialrequisition":MaterialRequisition,
  "entertestresults":EnterTestResults,
  "workordertimeentry":WorkOrderTimeEntry,
  "workorderdowntime":WorkOrderDownTime,
  "productioncost":ProductionCost,
  "purchasecostenquiry":PurchaseCostEnquiry,
  "componentcostsimulation":ComponentCostSimulation,
  "parentcostsimulation":ParentCostSimulation,
  "testmaster":TestMaster,
  "cyclecountprocess":CycleCountProcess,
  "cyclecountgeneration":CycleCountGeneration,
  "vatreceivables":VatReceivables,
  "vatpayables":VATPayables,
  "gstpayables":GSTPayables,
  "exchangerate":ExchangeRate,
  "gstreceivables":GSTReceivables,
  "taxdeductedsource":TaxDeductedSource,
  "automaticpostinginstructions":AutomaticPostingInstructions,
  "distributionpostinginstruction":DistributionPostingInstruction,
  "notificationsetup":NotificationSetup,
  "flexibleaccounting":FlexibleAccounting,
  "freegoodsrevision":FreeGoodsRevision,
  "receiptreversal":ReceiptReversal,
  "propertylisting":PropertyListing,
  "detailpricebreakup":DetailPriceBreakup,
  "paymentreversal":PaymentReversal,
  "vatsetoff":VatSetOff,
  "preventivemaintenance":PreventiveMaintenance,
  "receiptfromtransit":ReceiptFromTransit,
  "priceadjustmentdefinition":PriceAdjustmentDefinition,
  "baggingprocess":BaggingProcess,
  "priceadjustmentdetail":PriceAdjustmentDetail,
  "baggingtransfer":BaggingTransfer,
  "bedallocationqueue":BedAllocationQueue,
  "jewelleryprocess":WaxSettingProcess,
  "labtestresult":LabTestResult,
  "jewellerytransfer":JewelleryTransfer,
  "employeeratemaster":EmployeeRateMaster,
  "beddetails":BedDetails,
  "ipdqueue":IpdQueue,
  "hospitalpaymentdetails":HospitalPaymentDetails, //BILLING DETIALS(healthcare)
  "findingtransfer":FindingTransfer,
  "jewelleryacknowledgement":JewelleryAcknowledgement,
  "jewelleryrejection":JewelleryRejection,
  "rolecreation":RoleCreation,
  "menuscreation":MenusCreation,
  "accountledger":AccountLedger,
  "accountbalance":AccountBalance,
  "voucherwithtds":VoucherWithTds,
  "attachtesttoitem":AttachTestToItem,
  "approvalsetup":ApprovalSetup,//20241007

  // dashboards
  "dashboardproperty":PropertyOverview,
  // "dashboardrental":RentalDashBoard,
  "dashboardrental":RentalDashBoard,
  "dashboardautomotive":AutomotiveOverview,
  "dashboardconstruction":ConstructionOverview,
  "dashboardcrm":CRMOverview,
  "dashboardhcm":HCMOverview,

};

// const routes = await fetchRoutes();
async function canAccessUser(element){

  try {
    const url = `${domain}/api/UserRoles/GetUserRolesByRole`;
    const body={
      "role" : element.role
    }
    const resp = await axios.post(url,body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (resp.data.access === "true") {
      return true;
    }
  } catch (error) {
    console.error("Error fetching data from the API: ", error);
    return false;
  }
};
async function onSuccesscall(data){  
  const newArray = [];
  await Promise.all(data.map(async (element) => {
    if(element.menus.length>0) {
      const modifiedArray = await onSuccesscall(element.menus);
      const modifiedElement = { 
        name: element.description,
        layout: "/admin",
        path: "/default",
        category:true,
        items:modifiedArray,
        orderId:element.order_ID
      };
        newArray.push(modifiedElement);
    } else {
      var modifiedElement = {};
      if(element.version_ID){
        modifiedElement = {
          name: element.description,
          layout: "/admin",
          path: "/"+element.screen_ID+"&id="+element.version_ID,
          component: mapComponents[element.screen_ID],
          orderId:element.order_ID
        };
      } else if(element.report_ID){
        modifiedElement = {
          name: element.description,
          layout: "/admin",
          path: "/"+element.screen_ID+"&id="+element.report_ID,
          component: mapComponents[element.screen_ID],
          orderId:element.order_ID
        };
      } else {
        modifiedElement = {
          name: element.description,
          layout: "/admin",
          path: "/"+element.screen_ID,
          component: mapComponents[element.screen_ID],
          orderId:element.order_ID
        };
      }
      
        newArray.push(modifiedElement);
    }
  }));
  return newArray;
}
let routes;
export async function fetchRoutes() {
  try {
    const response = await axios.get(`${domain}/api/Menu/GetMenu`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    let unsortedRoutes = await onSuccesscall(response.data);
    routes=unsortedRoutes.sort((a, b) => a.orderId - b.orderId);
  } catch (error) {
    console.error("Error fetching data from the API: ", error);
  }
}

await fetchRoutes();

export default routes;

