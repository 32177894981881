import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function NotificationSetupDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  NotificationSetupTableData,
  setNotificationSetupTableData,
  ns_EV_NT,
  setns_EV_NT,
  SelectedemEMNO,
  setSelectedemEMNO,

  addressName,
  setaddressName,
}) {
  const {
    nsUkNo,
  } = data;
  const { savestyle, cancelstyle, Dialogstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ns_EV_NTOptions, setns_EV_NTOptions] = useState([]);
  // Event
  const onchangeDropDownFormDataEvent = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setns_EV_NT(value);
  };
  useEffect(() => {
    Event();
  }, []);
  const Event = async () => {
    const Options = await fetchClassCodeDS("NO/EV");
    setns_EV_NTOptions(Options);
  };
  const addRow = () => {
    setNotificationSetupTableData([...NotificationSetupTableData, {}]);
  };
  const deleteRow = () => {
    if (NotificationSetupTableData.length > 0) {
      const updatedData = [...NotificationSetupTableData];
      updatedData.pop();
      setNotificationSetupTableData(updatedData);
    }
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddressNamePopupVisible, setAddressNamePopupVisible] = useState(false);
  const [addressType, setaddressType] = useState("E");

  const openAddressNamePopup = (e) => {
    setAddressNamePopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };

  const handleSelectAddressName = (data) => {
    setaddressName(data.ab_AD_NM);
    document.getElementById(`addressName_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`ns_AD_NO_${SelectedRowIndex}`).value = data.abAdNo;
    setAddressNamePopupVisible(false);
    setShowSearchIcon(false);
  };

  const isUpdateMode = Boolean(nsUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{nsUkNo ? "Revise Notification Setup" : "Add Notification Setup"}</h5>
      <div style={{ width: '70rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="NotificationSetuptable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Event</th>
                <th>Address No</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {NotificationSetupTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`nsUkNo_${index}`}
                      name={`nsUkNo_${index}`}
                      value={row.nsUkNo}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ns_EV_NT_${index}`}
                      name={`ns_EV_NT_${index}`}
                      value={data[`ns_EV_NT_${index}`] ? data[`ns_EV_NT_${index}`] : row.ns_EV_NT}
                      options={ns_EV_NTOptions}
                      onChange={(e) => onchangeDropDownFormDataEvent(`ns_EV_NT_${index}`, e.value)}
                      placeholder="Event"
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <InputText
                        id={`addressName_${index}`}
                        name={`addressName_${index}`}
                        onFocus={() => setShowSearchIcon(true)}
                         onChange={(Event) => onchangedata(Event)}
                        onChange={(e) => onchangedata('addressName', e.value, index)}
                        defaultValue={row.addressName ? row.addressName : row[`${'addressName'}_${index}`]}
                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "7%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openAddressNamePopup(index)}
                        ></i>
                      )}
                    </div>
                    <InputText
                      style={{ display: "none" }}
                      id={`ns_AD_NO_${index}`}
                      name={`ns_AD_NO_${index}`}
                      onChange={(Event) => onchangedata(Event)}
                      defaultValue={row.ns_AD_NO ? row.ns_AD_NO : row[`${'ns_AD_NO'}_${index}`]}
                    />

                  </td>
                  <td >
                    <InputText
                      id={`ns_MS_AG_${index}`}
                      name={`ns_MS_AG_${index}`}
                      defaultValue={row.ns_MS_AG ? row.ns_MS_AG : data[`${"ns_AD_NO"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      { /*AddressNo*/}
      <AddressBookPopup
        visible={AddressNamePopupVisible}
        onClose={() => setAddressNamePopupVisible(false)}
        onDataSelect={handleSelectAddressName}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
