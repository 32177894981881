import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function AutomaticPostingInstructionsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AutomaticPostingInstructionsTableData,
  setAutomaticPostingInstructionsTableData,
  pi_CO_NO,
  SelectedITNM,
  setSelectedITNM,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  pi_CO_NM,
  setpi_CO_NM,
  pi_PO_IT,
  setpi_PO_IT,
}) {
  const { piUkNo, } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton,
     tablestyle, multitablestyle, savestyle, cancelstyle, Dialogstyle  } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();
  const [pi_PO_ITOptions, setpi_PO_ITOptions] = useState([]);
  const [pi_CO_NMOptions, setpi_CO_NMOptions] = useState([]);
  // Cost Center
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpi_CO_NM(value);
  };
  // PostinItem
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpi_PO_IT(value);
  };
  useEffect(() => {
    Costcenter1();
    PostingItem();
  }, []);

  const Costcenter1 = async () => {
    const Options = await fetchCostCenter();
    setpi_CO_NMOptions(Options);
  };
  const PostingItem = async () => {
    const Options = await fetchClassCodeDS("PO/IN");
    setpi_PO_ITOptions(Options);
  };
  const addRow = () => {
    setAutomaticPostingInstructionsTableData([...AutomaticPostingInstructionsTableData, {}]);
  };
  const deleteRow = () => {
    if (AutomaticPostingInstructionsTableData.length > 0) {
      const updatedData = [...AutomaticPostingInstructionsTableData];
      updatedData.pop();
      setAutomaticPostingInstructionsTableData(updatedData);
    }
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("pi_PO_IT").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(piUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{piUkNo ? "Revise Automatic Posting Instructions" : "Add Automatic Posting Instructions"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
          <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pi_PO_IT" className="font-bold">
                Posting Item Number
                </label>
                <Dropdown
                  name="pi_PO_IT"
                  value={pi_PO_IT}
                  options={pi_PO_ITOptions}
                  onChange={(e) => onchangeDropDownFormData("cr_TO_CR", e.value)}
                  placeholder=" Posting Item Number"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pi_CO_NO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="pi_CO_NO"
                  name="pi_CO_NO"
                  value={pi_CO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
          <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
                <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
              )}
              {(
                <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div style={multitablestyle}>
              <table className="custom-table" id="AutomaticPostingInstructionstable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th>Cost center</th>
                    <th>Account Object</th>
                    <th>Account Subsidiary</th>
                    <th>Description</th>
                    <th>Description 02</th>
                    <th>Cost center Option</th>
                    <th>Object Option</th>
                    <th>Subsidiary Option</th>
                    <th>System code</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {AutomaticPostingInstructionsTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`piUkNo_${index}`}
                          name={`piUkNo_${index}`}
                          value={row.piUkNo}
                        />
                      </td>
                      <td>
                        <Dropdown
                          id={`pi_CO_NM_${index}`}
                          name={`pi_CO_NM_${index}`}
                          value={data[`pi_CO_NM_${index}`] ? data[`pi_CO_NM_${index}`] : row.pi_CO_NM}
                          options={pi_CO_NMOptions}
                          onChange={(e) => onchangeDropDownFormDataCostCentre(`pi_CO_NM_${index}`, e.value)}
                          placeholder="Cost center"
                        />
                      </td>
                      <td >
                        <InputText style={{ width: "10rem" }}
                          id={`pi_OB_AC_${index}`}
                          name={`pi_OB_AC_${index}`}
                          defaultValue={row.pi_OB_AC}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{ width: "10rem" }}
                          id={`pi_OB_SB_${index}`}
                          name={`pi_OB_SB_${index}`}
                          defaultValue={row.pi_OB_SB}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{ width: "10rem" }}
                          id={`pi_PI_DS_${index}`}
                          name={`pi_PI_DS_${index}`}
                          defaultValue={row.pi_PI_DS}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{ width: "10rem" }}
                          id={`pi_PI_D2_${index}`}
                          name={`pi_PI_D2_${index}`}
                          defaultValue={row.pi_PI_D2}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td>
                        <Dropdown style={{ width: "10rem" }}
                          id={`pi_CC_OPT_${index}`}
                          name={`pi_CC_OP_${index}`}
                          value={data[`pi_CC_OP_${index}`] ? data[`pi_CC_OP_${index}`] : row.pi_CC_OP}
                          options={[
                            { label: "Yes", value: "y" },
                            { label: "No", value: "n" },
                          ]}
                          onChange={(e) => onchangeDropDownFormData1(`pi_CC_OP_${index}`, e.value)}
                          placeholder="Cost center Option"
                        />
                      </td>
                      <td>
                        <Dropdown style={{ width: "10rem" }}
                          id={`pi_OB_OP_${index}`}
                          name={`pi_OB_OP_${index}`}
                          value={data[`pi_OB_OP_${index}`] ? data[`pi_OB_OP_${index}`] : row.pi_OB_OP}
                          options={[
                            { label: "Yes", value: "y" },
                            { label: "No", value: "n" },
                          ]}
                          onChange={(e) => onchangeDropDownFormData2(`pi_OB_OP_${index}`, e.value)}
                          placeholder="Object Option"
                        />
                      </td>
                      <td>
                        <Dropdown style={{ width: "10rem" }}
                          id={`pi_SB_OP_${index}`}
                          name={`pi_SB_OP_${index}`}
                          value={data[`pi_SB_OP_${index}`] ? data[`pi_SB_OP_${index}`] : row.pi_SB_OP}
                          options={[
                            { label: "Yes", value: "y" },
                            { label: "No", value: "n" },
                          ]}
                          onChange={(e) => onchangeDropDownFormData3(`pi_SB_OP_${index}`, e.value)}
                          placeholder="Subsidiary Option"
                        />
                      </td>
                      <td>
                        <InputText style={{ width: "10rem" }}
                          id={`pi_SY_CO_${index}`}
                          name={`pi_SY_CO_${index}`}
                          defaultValue={row.pi_SY_CO}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{ width: "10rem" }}
                          id={`pi_TY_PE_${index}`}
                          name={`pi_TY_PE_${index}`}
                          defaultValue={row.pi_TY_PE}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
      </div>
      { /*Posting Item Number */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />

    </Dialog>
  );
}
