import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ItemMasterDialog from "./ItemMasterDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  imItNo:"",
  im_IT_NM: "",
  im_IT_DS: "",
  im_PR_UM: "",
  im_ST_TY:"",
  im_PD_GP:"",
  im_TX_AP:"",
  im_SH_LF:"",
  im_LD_TM:"",
  im_UN_CT:"",
  im_UN_PR:"",
  im_CR_CD:"",
};

const initialFilters = {
  im_IT_NM: { value: "", operation: "" },
  im_IT_DS: { value: "", operation: "" },
  im_PR_UM: { value: "", operation: "" },
  im_ST_TY: { value: "", operation: "" },
  im_PD_GP: { value: "", operation: "" },
  im_TX_AP: { value: "", operation: "" },
  im_SH_LF: { value: "", operation: "" },
  im_LD_TM: { value: "", operation: "" },
  im_UN_CT: { value: "", operation: "" },
  im_UN_PR: { value: "", operation: "" },
 
};

const ItemMasterMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [im_PR_UM, setim_PR_UM] = useState("");
  const [im_ST_TY, setim_ST_TY] = useState("");
  const [im_PD_GP, setim_PD_GP] = useState("");
  const [im_TX_AP, setim_TX_AP] = useState("");
  const [im_TX_TY, setim_TX_TY] = useState("");
  const [im_TX_PE, setim_TX_PE] = useState("");
 
  const [ItemMastertable, setItemMastertable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setItemMastertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setim_PR_UM(null);
    setim_ST_TY(null);
    setim_PD_GP(null);
    setim_TX_AP(null);
    setim_TX_TY(null);
    setOpen(true);
  };

  const handleClose = () => {
    setItemMastertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setim_PR_UM(null);
    setim_ST_TY(null);
    setim_PD_GP(null);
    setim_TX_AP(null);
    setim_TX_TY(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setItemMastertable([...ItemMastertable, {}]);
  };

  const deleteRow = () => {
    if (ItemMastertable.length > 0) {
      const updatedData = [...ItemMastertable];
      updatedData.pop();
      setItemMastertable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ItemMasterBasic/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ItemMasterData = response.data;
          const updatedItemMaster = ItemMasterData.itemMasterList.map((target) => {
            const UpdatedItemMasterData = { ...target };
            delete UpdatedItemMasterData.imItNo;
            return UpdatedItemMasterData;
          });
          setItemMastertable(updatedItemMaster);
          setFormData(updatedItemMaster[0]);         
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
    
  }, [currentPage, pageSize, filters]);

  
  const [im_CR_CD , setim_CR_CD ] = useState("");
  const [im_PR_CT , setim_PR_CT ] = useState("");

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ItemMasterBasic/List/${currentPage}/${pageSize}/${im_PR_CT}/${im_CR_CD}/${im_CO_TY}/${im_CO_CT}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [im_LN_TY , setim_LN_TY ] = useState("");
  const [im_IN_CL , setim_IN_CL ] = useState("");
  const [im_CO_CT , setim_CO_CT ] = useState("");
  const [ic_CO_TY , setic_CO_TY ] = useState("");
  const [ic_CO_CT , setic_CO_CT ] = useState("");
  const [bp_CR_CD , setbp_CR_CD ] = useState("");
  const [im_CO_TY , setim_CO_TY ] = useState("");
  const [im_TO_DT , setim_TO_DT ] = useState("");
  const handleDefault = async () => {
  const linkPath = String(window.location);
  const l_array = linkPath.split("=");
  const LinkId = l_array[1];
  
  const requestBody = {
    "version_ID" : LinkId
  };
  const paginationUrl = `${domain}/api/LinkId/Select`;
  try {
    const response = await axios.post(paginationUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataDefault = response.data;
    const listDefault = [];
    var count = 0;
    if (dataDefault.versionIds != null) {
      for (let i = 0; i < dataDefault.versionIds.length; i++) {
        if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
          const fName = dataDefault.versionIds[i].field_ID;
          // setfi_CO_NO(`${company}`);
          if(fName === "IM_LN_TY"){
            setim_LN_TY(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IM_IN_CL"){
            setim_IN_CL(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IM_CO_CT"){
            setim_CO_CT(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IC_CO_TY"){
            setic_CO_TY(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IC_CO_CT"){
            setic_CO_CT(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "BP_CR_CD"){
            setbp_CR_CD(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IM_PR_CT"){
            setim_PR_CT(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IM_CO_TY"){
            setim_CO_TY(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IM_CR_CD"){
            setim_CR_CD(dataDefault.versionIds[i].default_VALUE)
          }
          if(fName === "IM_TO_DT"){
            setim_TO_DT(dataDefault.versionIds[i].default_VALUE)
          }

        }
        if(dataDefault.versionIds[i].field_TYPE === "LIST"){
          count++;
          const def = {
            "column" : dataDefault.versionIds[i].field_ID ,
            "value" : dataDefault.versionIds[i].default_VALUE,
            "operation" : "EQUAL"
          }
          listDefault.push(def);
        }
      }
      handleSearch(listDefault,count);
    } else {
      handleSearch(listDefault,count);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.imItNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    ItemMastertable[index][fieldName]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setim_PR_UM(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setim_ST_TY(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setim_PD_GP(value);
  };
 const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setim_TX_AP(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setim_TX_TY(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ItemMasterBasic/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ItemMasterData = response.data;
        setItemMastertable(ItemMasterData.itemMasterList);
        setFormData(ItemMasterData.itemMasterList[0]);
        setim_TX_TY(ItemMasterData.itemMasterList.im_TX_TY);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleFormSubmit = () => {    
      if (FormData.id) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/ItemMasterBasic/Update`;
        if (confirm) {
          const table = document.getElementById("ItemMastertable");
          const totalRowCount = table.tBodies[0].rows.length;
          const itemMasterList = [];
          for (let i = 0; i < totalRowCount; i++) {
            const UK_NO = document.getElementById("imItNo_" + i);
            const IT_NM = document.getElementById("im_IT_NM_" + i);
            const IT_DS = document.getElementById("im_IT_DS_" + i);
            const PR_UM = table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value;
            const ST_TY = table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value;
            const PD_GP = table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value;
            const UN_CT = document.getElementById("im_UN_CT_" + i);
            const UN_PR = document.getElementById("im_UN_PR_" + i);
            const TX_AP = table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value;
            const TX_TY = table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value;
            const TX_PE = table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value;
            const SH_LF = document.getElementById("im_SH_LF_" + i);
            const LD_TM = document.getElementById("im_LD_TM_" + i);
            const LN_TY = document.getElementById("im_LN_TY_" + i);
            const IN_CL = document.getElementById("im_IN_CL_" + i);
            const CO_CT = document.getElementById("im_CO_CT_" + i);
            const CO_TY = document.getElementById("ic_CO_TY_" + i);
            const CO_TT = document.getElementById("ic_CO_CT_" + i);
            const CR_CD = document.getElementById("bp_CR_CD_" + i);
            const PR_CT = document.getElementById("im_PR_CT_" + i);
            const IM_TY = document.getElementById("im_CO_TY_" + i);
            const IM_CR = document.getElementById("im_CR_CD_" + i);
            const DO_DT = document.getElementsByName("im_TO_DT_" + i)[0];
            const Details = {
              imItNo: UK_NO.value,
              im_IT_NM: IT_NM.value,
              im_IT_DS: IT_DS.value,
              im_PR_UM: PR_UM,
              im_ST_TY: ST_TY,
              im_PD_GP: PD_GP,
              im_UN_CT: UN_CT.value,
              im_UN_PR: UN_PR.value,
              im_TX_AP: TX_AP,
              im_TX_TY: TX_TY,
              im_TX_PE: TX_PE.value,
              im_SH_LF: SH_LF.value,
              im_LD_TM: LD_TM.value,
              im_LN_TY: LN_TY.value,
              im_IN_CL: IN_CL.value,
              im_CO_CT: CO_CT.value,
              ic_CO_TY: CO_TY.value,
              ic_CO_CT: CO_TT.value,
              bp_CR_CD: CR_CD.value,
              im_PR_CT: PR_CT.value,
              im_CO_TY: IM_TY.value,
              im_CR_CD: IM_CR.value,
              im_TO_DT: DO_DT.value?moment(DO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
            };
            itemMasterList.push(Details);
          }
          const requestBody = {
            itemMasterList,
          
        
          };
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        const table = document.getElementById("ItemMastertable");
        const totalRowCount = table.tBodies[0].rows.length;
        const itemMasterList = [];
          for (let i = 0; i < totalRowCount; i++) {
            const UK_NO = document.getElementById("imItNo_" + i);
            const IT_NM = document.getElementById("im_IT_NM_" + i);
            const IT_DS = document.getElementById("im_IT_DS_" + i);
            const PR_UM = table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value;
            const ST_TY = table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value;
            const PD_GP = table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value;
            const UN_CT = document.getElementById("im_UN_CT_" + i);
            const UN_PR = document.getElementById("im_UN_PR_" + i);
            const TX_AP = table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value;
            const TX_TY = table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value;
            const TX_PE = document.getElementById("im_TX_PE_" + i);
            const SH_LF = document.getElementById("im_SH_LF_" + i);
            const LD_TM = document.getElementById("im_LD_TM_" + i);
            const LN_TY = document.getElementById("im_LN_TY_" + i);
            const IN_CL = document.getElementById("im_IN_CL_" + i);
            const CO_CT = document.getElementById("im_CO_CT_" + i);
            const CO_TY = document.getElementById("ic_CO_TY_" + i);
            const CO_TT = document.getElementById("ic_CO_CT_" + i);
            const CR_CD = document.getElementById("bp_CR_CD_" + i);
            const PR_CT = document.getElementById("im_PR_CT_" + i);
            const IM_TY = document.getElementById("im_CO_TY_" + i);
            const IM_CR = document.getElementById("im_CR_CD_" + i);
            const DO_DT = document.getElementsByName("im_TO_DT_" + i)[0];
            const Details = {
                imItNo: UK_NO.value,
              im_IT_NM: IT_NM.value,
              im_IT_DS: IT_DS.value,
              im_PR_UM: PR_UM,
              im_ST_TY: ST_TY,
              im_PD_GP: PD_GP,
              im_UN_CT: UN_CT.value,
              im_UN_PR: UN_PR.value,
              im_TX_AP: TX_AP,
              im_TX_TY: TX_TY,
              im_TX_PE: TX_PE.value,
              im_SH_LF: SH_LF.value,
              im_LD_TM: LD_TM.value,
              im_LN_TY: LN_TY.value,
              im_IN_CL: IN_CL.value,
              im_CO_CT: CO_CT.value,
              ic_CO_TY: CO_TY.value,
              ic_CO_CT: CO_TT.value,
              bp_CR_CD: CR_CD.value,
              im_PR_CT: PR_CT.value,
              im_CO_TY: IM_TY.value,
              im_CR_CD: IM_CR.value,
              im_TO_DT: DO_DT.value?moment(DO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
            };
            itemMasterList.push(Details);
          }
          const requestBody = {
            itemMasterList,
          
        
          };
        axios
          .post(`${domain}/api/ItemMasterBasic/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          // error
          //   const infos = resp.data.infos;
          //  document.write(infos);
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }}>
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IT_NM"] ? filters["im_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_NM",
                        e.target.value,
                        filters["im_IT_NM"]
                          ? filters["im_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_DS",
                        e.target.value,
                        filters["im_IT_DS"]
                          ? filters["im_IT_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_PR_UM"] ? filters["im_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PR_UM",
                        e.target.value,
                        filters["im_PR_UM"]
                          ? filters["im_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_ST_TY"] ? filters["im_ST_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_ST_TY",
                        e.target.value,
                        filters["im_ST_TY"]
                          ? filters["im_ST_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["im_PD_GP"] ? filters["im_PD_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_PD_GP",
                        e.target.value,
                        filters["im_PD_GP"]
                          ? filters["im_PD_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_TX_AP"] ? filters["im_TX_AP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_TX_AP",
                        e.target.value,
                        filters["im_TX_AP"]
                          ? filters["im_TX_AP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_SH_LF"] ? filters["im_SH_LF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_SH_LF",
                        e.target.value,
                        filters["im_SH_LF"]
                          ? filters["im_SH_LF"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_LD_TM"] ? filters["im_LD_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_LD_TM",
                        e.target.value,
                        filters["im_LD_TM"]
                          ? filters["im_LD_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_UN_CT"] ? filters["im_UN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_UN_CT",
                        e.target.value,
                        filters["im_UN_CT"]
                          ? filters["im_UN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["im_UN_PR"] ? filters["im_UN_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_UN_PR",
                        e.target.value,
                        filters["im_UN_PR"]
                          ? filters["im_UN_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Item Code</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Stocking Type</th>
                <th style={tableHeaderStyle}>Product group</th>
                <th style={tableHeaderStyle}>Tax Flag</th>
                <th style={tableHeaderStyle}>Shelf Life</th>
                <th style={tableHeaderStyle}>Lead time</th>
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>Unit price</th>
                {/* <th style={tableHeaderStyle}>Currency</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.id}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.imItNo}
                        checked={selectedRows.includes(rowData.imItNo)}
                        onChange={() => handleRowSelect(rowData.imItNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.im_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                    <td style={tableCellStyle}>{rowData.im_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.im_ST_TY}</td>
                    <td style={tableCellStyle}>{rowData.im_PD_GP}</td>
                    <td style={tableCellStyle}>{rowData.im_TX_AP}</td>
                    <td style={tableCellStyle}>{rowData.im_SH_LF}</td>
                    <td style={tableCellStyle}>{rowData.im_LD_TM}</td>
                    <td style={tableCellStyle}>{rowData.im_UN_CT}</td>
                    <td style={tableCellStyle}>{rowData.im_UN_PR}</td>
                    {/* <td style={tableCellStyle}>{rowData.im_CR_CD}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

      <ItemMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        im_LN_TY={im_LN_TY}
        im_PR_UM={im_PR_UM}
        im_ST_TY={im_ST_TY}
        im_PD_GP={im_PD_GP}
        im_TX_AP={im_TX_AP}
        im_TX_TY={im_TX_TY}
        im_IN_CL={im_IN_CL}
        im_CO_CT={im_CO_CT}
        ic_CO_TY={ic_CO_TY}
        ic_CO_CT={ic_CO_CT}
        bp_CR_CD={bp_CR_CD}
        im_PR_CT={im_PR_CT}
        im_CO_TY={im_CO_TY}
        im_CR_CD={im_CR_CD}
        im_TO_DT={im_TO_DT}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        ItemMastertable={ItemMastertable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        im_TX_PE={im_TX_PE}
        setim_TX_PE={setim_TX_PE}
        setFormData={setFormData}
  
      />
    </div>
  );
};

export default ItemMasterMain;
