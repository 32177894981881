import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PaymentReversalDialog from "./PaymentReversalDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import { InputText } from "primereact/inputtext";
import SecondPopup from "views/admin/Popups/AddressBookPopup";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
const initialValue = {
  ph_UK_NO: "",
  ph_PM_NO:"",
  ph_PD_TY:"",
  ph_DO_CO:"",
  ph_PY_NO:"",
  payee_DESC:"",
  ph_GL_BA:"",
  ph_PY_DT:"",
  ph_GL_DT:"", 
  ph_BA_NO:"",
  ph_BA_TY:"",
  ph_BA_DT:"",
  ph_PA_AM:"",
  ph_CR_CD:"",
  ph_CR_RT:"",
  ph_GL_PO:"",
  phPyId:"",
  ph_RM_EX:"",
  ph_VO_DT:"",
  ph_VO_FL:"",
};

const initialFilters = {
  ph_PM_NO: { value: "", operation: "" },
  ph_PD_TY: { value: "", operation: "" },
  ph_DO_CO: { value: "", operation: "" },
  ph_PY_NO: { value: "", operation: "" },
  payee_DESC: { value: "", operation: "" },
  ph_GL_BA: { value: "", operation: "" },
  ph_PY_DT: { value: "", operation: "" },
  ph_GL_DT: { value: "", operation: "" }, 
  ph_BA_NO: { value: "", operation: "" },
  ph_BA_TY: { value: "", operation: "" },
  ph_BA_DT: { value: "", operation: "" },
  ph_PA_AM: { value: "", operation: "" },
  ph_CR_CD: { value: "", operation: "" },
  ph_CR_RT: { value: "", operation: "" },
  ph_GL_PO: { value: "", operation: "" },
  phPyId: { value: "", operation: "" },
  ph_RM_EX: { value: "", operation: "" },
  ph_VO_DT: { value: "", operation: "" },
  ph_VO_FL: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle, rightalined
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");


  // drodpdowns
  const [ph_VO_RE, setph_VO_RE] = useState("");
  // AdvanceSearch

  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [rh_GL_BA, setrh_GL_BA] = useState("");
  const [ph_VO_DT, setph_VO_DT] = useState("");
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [addressTypeC, setaddressTypeC] = useState("S");
  const [bankFilter, setbankFilter] = useState("BA");
  const [customer, setCustomer] = useState("");
  const [rh_RC_DT, setrh_RC_DT] = useState("");
  const [ph_GL_DT, setph_GL_DT] = useState("");



  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [PaymentReversalTableData, setPaymentReversalTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
    if (cus !== null) {
      updatedFilters = {
        ...updatedFilters,
        cc_AD_NO: {
          value: cus,
          operation: "EQUAL",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setPaymentReversalTableData([{}]);
    setph_VO_RE(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const openSecondPopup = () => {
    setSecondPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    setSelectedcustmer(data.ab_AD_NM);
    setCustomer(data.abAdNo);
    setSecondPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters(data.abAdNo);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNOA(data.am_AC_DS);
    onchangeFormData("rh_GL_BA", data.account);
    changeaccount(data);
    setAccountPopupVisible(false);
  };
  const AdvanceSearch = (
    <div style={{ width: '85rem', padding: '1rem' }}>
      <div className="formgrid grid" style={{ display: 'flex', flexWrap: 'nowrap', gap: '1rem' }}>
        
        <div className="field" style={{ flex: '1' }}>
          <label htmlFor="customer" className="font-bold">
            Payee Number <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputText
              style={{ display: 'none' }}
              id="customer"
              name="customer"
              value={customer}
            />
            <InputText
              id="ab_AD_NM"
              name="ab_AD_NM"
              readOnly
              onChange={(event) => onchangedata(event)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedcustmer}
              style={{ backgroundColor: "#ffffff", flex: '1' }}
            />
            {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginLeft: '0.5rem',
                  cursor: "pointer",
                }}
                onClick={openSecondPopup}
              ></i>
            )}
          </div>
        </div>
  
        <div className="field" style={{ flex: '1' }}>
          <label htmlFor="accountDes" className="font-bold">
            Bank Account <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputText
              id="accountDes"
              name="accountDes"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedemEMNOA}
              style={{ flex: '1' }}
            />
            {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginLeft: '0.5rem',
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
            )}
          </div>
        </div>
  
        <div className="field" style={{ flex: '1' }}>
          <label htmlFor="rh_RC_DT" className="font-bold">
            From Date
          </label>
          <InputText
            id="rh_RC_DT"
            name="rh_RC_DT"
            defaultValue={rh_RC_DT}
            style={{ width: '100%' }}
          />
        </div>
  
        <div className="field" style={{ flex: '1' }}>
          <label htmlFor="ph_GL_DT" className="font-bold">
            To Date
          </label>
          <InputText
            id="ph_GL_DT"
            name="ph_GL_DT"
            defaultValue={ph_GL_DT}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
  
  
  const handleClearButton = () => {
    setCustomer("");
    setSelectedcustmer("");
    setFilters("");
  };
  const accapi = `${domain}/api/JournalLedger/GetSummaryFromGABL`
  const changeaccount = async (data) => {
    const request = {
      "account": data.account,
      "gl_DATE": ph_GL_DT
    }
    try {
      const response = await axios.post(accapi, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      const dataacc = response.data.Result;
      if (dataacc) {
        onchangeFormData("rh_AC_BL", dataacc.gb_BG_BL);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PaymentReversal/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.paymentDetailList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.ph_UK_NO;
            return UpdatedworkData;
          });
          setPaymentReversalTableData(updatedCashFlowData);
          const updatedFormData = { ...CashFlowData.paymentEntry };
          delete updatedFormData.ph_UK_NO;

          setFormData(updatedFormData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [ph_DO_CO, setph_DO_CO] = useState("");

  const handleDefault = async () => {
    setph_DO_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PaymentReversal/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PaymentReversal/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setPaymentReversalTableData(CashFlowData.paymentDetailList);
        setFormData(CashFlowData);
        //setselectedSupplier(CashFlowData.PaymentReversalList.sup_NAME);

        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBody = {};
  function values() {
    // const table = document.getElementById("PaymentReversaltable");
    // const totalRowCount1 = table.tBodies[0].rows.length;
    // const paymentDetailList = [];
    // for (let i = 0; i < totalRowCount1; i++) {
    //   const details = {
    //   };
    //   paymentDetailList.push(details);
    // }
    requestBody = {
     // paymentDetailList,
      ph_UK_NO: FormData.ph_UK_NO,
      ph_VO_DT: FormData.ph_VO_DT,//document.getElementById("ph_VO_DT").value,
      ph_VO_RE: ph_VO_RE,
    };
  }
  const handleFormSubmit = () => {
    values();
    if (FormData.ph_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/PaymentReversal/SavePaymentReversalDetails`;
      if (confirm) {
        axios
          .post(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ph_DO_CO = company;
      axios
        .post(`${domain}/api/PaymentReversal/SavePaymentReversalDetails`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };


  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", border: "none", backgroundColor: "#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const rightClear = (
    <React.Fragment>
      <Tooltip target=".clear-button" position="bottom" mouseTrack>
        <div>Clear Filter</div>
      </Tooltip>

      <Button
        style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
        icon="pi pi-times"
        className="mr-2 clear-button"
        link
        onClick={handleClearButton}
      />
    </React.Fragment>
  );
  const inputStyleN = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  //  width:"100%",
    backgroundColor:"#f5f4f4"
  };
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%" }}>
          <Toolbar
            className="p-mb-4"
            left={AdvanceSearch}
            right={rightClear}
          />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_PM_NO"] ? filters["ph_PM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_PM_NO",
                        e.target.value,
                        filters["ph_PM_NO"]
                          ? filters["ph_PM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_PD_TY"] ? filters["ph_PD_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_PD_TY",
                        e.target.value,
                        filters["ph_PD_TY"] ? filters["ph_PD_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_DO_CO"] ? filters["ph_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_DO_CO",
                        e.target.value,
                        filters["ph_DO_CO"]
                          ? filters["ph_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["ph_PY_NO"] ? filters["ph_PY_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_PY_NO",
                        e.target.value,
                        filters["ph_PY_NO"]
                          ? filters["ph_PY_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["payee_DESC"] ? filters["payee_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "payee_DESC",
                        e.target.value,
                        filters["payee_DESC"] ? filters["payee_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyleN}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_GL_BA"] ? filters["ph_GL_BA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_GL_BA",
                        e.target.value,
                        filters["ph_GL_BA"]
                          ? filters["ph_GL_BA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_PY_DT"] ? filters["ph_PY_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_PY_DT",
                        e.target.value,
                        filters["ph_PY_DT"]
                          ? filters["ph_PY_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_GL_DT"] ? filters["ph_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_GL_DT",
                        e.target.value,
                        filters["ph_GL_DT"]
                          ? filters["ph_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_BA_NO"] ? filters["ph_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_BA_NO",
                        e.target.value,
                        filters["ph_BA_NO"]
                          ? filters["ph_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_BA_TY"] ? filters["ph_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_BA_TY",
                        e.target.value,
                        filters["ph_BA_TY"]
                          ? filters["ph_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_BA_DT"] ? filters["ph_BA_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_BA_DT",
                        e.target.value,
                        filters["ph_BA_DT"]
                          ? filters["ph_BA_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_PA_AM"] ? filters["ph_PA_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_PA_AM",
                        e.target.value,
                        filters["ph_PA_AM"]
                          ? filters["ph_PA_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_CR_CD"] ? filters["ph_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CR_CD",
                        e.target.value,
                        filters["ph_CR_CD"]
                          ? filters["ph_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_CR_MO"] ? filters["ph_CR_MO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CR_MO",
                        e.target.value,
                        filters["ph_CR_MO"]
                          ? filters["ph_CR_MO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_CR_RT"] ? filters["ph_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_CR_RT",
                        e.target.value,
                        filters["ph_CR_RT"]
                          ? filters["ph_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_GL_PO"] ? filters["ph_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_GL_PO",
                        e.target.value,
                        filters["ph_GL_PO"]
                          ? filters["ph_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["phPyId"] ? filters["phPyId"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "phPyId",
                        e.target.value,
                        filters["phPyId"]
                          ? filters["phPyId"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> */}
                <td>
                  <input
                    type="text"
                    value={filters["ph_RM_EX"] ? filters["ph_RM_EX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_RM_EX",
                        e.target.value,
                        filters["ph_RM_EX"]
                          ? filters["ph_RM_EX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_VO_DT"] ? filters["ph_VO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_VO_DT",
                        e.target.value,
                        filters["ph_VO_DT"]
                          ? filters["ph_VO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ph_VO_FL"] ? filters["ph_VO_FL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ph_VO_FL",
                        e.target.value,
                        filters["ph_VO_FL"]
                          ? filters["ph_VO_FL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Payment Number</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Payee Number</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Bank Account</th>
                <th style={tableHeaderStyle}>Payment Date</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Batch Number	</th>
                <th style={tableHeaderStyle}>Batch Type</th>
              
                <th style={tableHeaderStyle}>Batch Date</th>
                <th style={tableHeaderStyle}>Payment  Number</th>
                <th style={tableHeaderStyle}>Currency Code</th>
                <th style={tableHeaderStyle}>Currency Mode</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Post Status</th>
                <th style={tableHeaderStyle}>Remarks</th>
                <th style={tableHeaderStyle}>Void Date</th>
                <th style={tableHeaderStyle}>Void Flag</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ph_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.ph_UK_NO}
                        checked={selectedRows.includes(rowData.ph_UK_NO)}
                        onChange={() => handleRowSelect(rowData.ph_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ph_PM_NO}</td>
                    <td style={tableCellStyle}>{rowData.ph_PD_TY}</td>
                    <td style={tableCellStyle}>{rowData.ph_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.ph_PY_NO}</td>
                    <td style={tableCellStyle}>{rowData.payee_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ph_GL_BA}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ph_PY_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ph_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.ph_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.ph_BA_TY}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ph_BA_DT)}</td>
                    <td style={tableCellStyle}>{rowData.ph_PA_AM}</td>
                    <td style={tableCellStyle}>{rowData.ph_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.ph_CR_MO}</td>
                    <td style={tableCellStyle}>{rowData.ph_CR_RT}</td>
                    <td style={tableCellStyle}>{rowData.ph_GL_PO}</td>
                    {/* <td style={tableCellStyle}>{rowData.phPyId}</td> */}
                    <td style={tableCellStyle}>{rowData.ph_RM_EX}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ph_VO_DT)}</td>
                    <td style={tableCellStyle}>{rowData.ph_VO_FL}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <SecondPopup
        visible={secondPopupVisible}
        onClose={() => setSecondPopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressTypeC}
      />
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        // formData={data}
        bankFilter={bankFilter}
      />
      <PaymentReversalDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        PaymentReversalTableData={PaymentReversalTableData}
        setPaymentReversalTableData={setPaymentReversalTableData}
        ph_DO_CO={ph_DO_CO}
      
        selectedcustmer={selectedcustmer}
        rh_GL_BA={rh_GL_BA}
        setrh_GL_BA={setrh_GL_BA}
        ph_VO_DT={ph_VO_DT}
        setph_VO_DT={setph_VO_DT}
        rh_RC_DT={rh_RC_DT}
        setrh_RC_DT={setrh_RC_DT}
        ph_VO_RE={ph_VO_RE}
        setph_VO_RE={setph_VO_RE}

      />
    </div>
  );
};

export default WorkCentreMain;
