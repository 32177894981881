import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';

const initialFilters = {
  cl_CL_CD: { value: "", operation: "" },
  cl_CL_DS: { value: "", operation: "" },
};

const ClassCodePopup = ({ visible, onClose, onDataSelect }) => {
  const {tableHeaderStyle,tableCellStyle,PopupStyle,} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === "im_IT_NM") {
          constructedColumn = "im_IT_NM";
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
    const updatedFilters = {
      ...filters,
      [column]: {
        value: updatedValue,
        operation: updatedOperation,
      },
    };
    setFilters(updatedFilters);
  };
  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);

  const handleSearch = async () => {
      try {
        const response = await axios.get(`${domain}/api/ClassCode/All/FO/DT`,  {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': 'http://localhost:3000',
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          }
        });
  
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
  
        const listData = response.data;
  
        if (Array.isArray(listData) && listData.length > 0) {
          setTableData(listData);
  
        } else {
          console.error("Invalid API response:", listData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }  
    };
  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  return (
    <Dialog id="dialog-popup-style" visible={open} onHide={handleClose}  style={{ width: "50rem" }} >
      <div className="card">
        <div style={PopupStyle}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={
                      filters["cl_CL_CD"] ? filters["cl_CL_CD"].value : ""
                    }
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CL_CD",
                        e.target.value,
                        filters["cl_CL_CD"]
                          ? filters["cl_CL_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={
                      filters["cl_CL_DS"] ? filters["cl_CL_DS"].value : ""
                    }
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CL_DS",
                        e.target.value,
                        filters["cl_CL_DS"]
                          ? filters["cl_CL_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Description </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.imItNo}
                      onClick={() => onDataSelect(rowData)}
                      style={{
                              backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",
                            }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.cl_CL_CD}</td>
                      <td style={tableCellStyle}>{rowData.cl_CL_DS}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <div className="Pagination" style={{ marginLeft: "92%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default ClassCodePopup;
